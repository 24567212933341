/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useRef, useState } from "react";
import { GDPRCheckbox } from "./GDPRCheckbox";
import "./GDPROverlay.scss";

type Props = {
  title: string;
  description: string;
  acceptAllLabel: string;
  acceptSelectionLabel: string;
  showCheckboxesLabel: string;
  checkboxes: {
    name: string;
    id: string;
    text: string;
    required?: boolean;
    onChecked?: () => void;
  }[];
};
export const GDPROverlay = (props: Props) => {
  const [show, setShow] = useState(false);
  const [showCheckboxes, setShowCheckboxes] = useState(false);
  const [checkedItems, setCheckedItems] = useState<string[]>([
    ...props.checkboxes.filter((cb) => cb.required).map((cb) => cb.id),
  ]);
  const [showText, setShowText] = useState("");
  const topSectionRef = useRef<HTMLDivElement>(null);
  const allRequiredChecked = useCallback(
    (items: string[]) =>
      props.checkboxes
        .filter((e) => e.required)
        .reduce((prev, e) => prev && items.includes(e.id), true),
    [props.checkboxes]
  );

  useEffect(() => {
    document.body.style.overflow = "hidden";
    const itemsJson = localStorage.getItem("GDPR");
    console.log("itemsJson", itemsJson);

    if (!itemsJson) {
      setShow(true);
    } else if (itemsJson) {
      const items = JSON.parse(itemsJson) as string[];
      if (allRequiredChecked(items)) {
        props.checkboxes
          .filter((e) => items.includes(e.id))
          .map((e) => e.onChecked && e.onChecked());
        document.body.style.overflow = "auto";
        setShow(false);
      }
    }
  }, [allRequiredChecked, props.checkboxes]);

  const changeChecked = (id: string, state: boolean) => {
    const newCheckedItems = state
      ? [...checkedItems, id]
      : checkedItems.filter((e) => e !== id);
    setCheckedItems(newCheckedItems);
  };

  const onSubmit = (items: string[]) => {
    if (allRequiredChecked(items)) {
      props.checkboxes
        .filter((e) => items.includes(e.id))
        .map((e) => e.onChecked && e.onChecked());
      setShow(false);
      localStorage.setItem("GDPR", JSON.stringify(items));
      document.body.style.overflow = "auto";
      return true;
    } else {
      console.warn("not all required checked");
    }
    return false;
  };

  const collapseCheckboxesExceptClicked = (id: string) => {
    console.log(id);

    showText === id ? setShowText("") : setShowText(id);
  };

  return !show ? (
    <></>
  ) : (
    <div className="GDPROverlay__container">
      <div className="GDPROverlay__wrapper">
        <div
          className="GDPROverlay"
          ref={topSectionRef}
          style={{
            padding: "20px 0px 20px 0px",
          }}
        >
          <div className="GDPROverlay__top">
            <img
              src="/logo_cookie_banner.svg"
              alt="smokeless_logo"
              className="GDPROverlay__img"
            />
            <h1 className="GDPROverlay__title">{props.title}</h1>
            <div className="GDPROverlay__description">{props.description}</div>

            <div className="GDPROverlay__checkboxContainer">
              {showCheckboxes && (
                <div className="GDPROverlay__checkboxWrapper">
                  <hr className="GDPROverlay__hr" />
                  <div className="GDPROverlay__checkboxes">
                    {props.checkboxes.map((checkbox, index) => {
                      console.log(checkbox.id);

                      return (
                        <GDPRCheckbox
                          title={checkbox.name}
                          text={checkbox.text}
                          isRequired={checkbox.required}
                          changeChecked={changeChecked}
                          id={checkbox.id}
                          key={index}
                          collapseCheckboxesExceptClicked={
                            collapseCheckboxesExceptClicked
                          }
                          showText={showText}
                          isShow={showText === checkbox.id}
                        />
                      );
                    })}
                  </div>
                  <hr className="GDPROverlay__hr" />
                </div>
              )}
            </div>
          </div>

          <div className="GDPR__btn__container">
            {!showCheckboxes && (
              <button
                onClick={() => setShowCheckboxes(true)}
                className="GDPROverlay__manageDataBtn"
              >
                {props.showCheckboxesLabel}
              </button>
            )}
            {showCheckboxes ? (
              <button
                disabled={!allRequiredChecked(checkedItems)}
                onClick={() => onSubmit(checkedItems)}
                className="GDPROverlay__acceptSelectionBtn"
              >
                {props.acceptSelectionLabel}
              </button>
            ) : (
              <button
                onClick={() => {
                  onSubmit(props.checkboxes.map((e) => e.id));
                }}
                className="GDPROverlay__acceptAllBtn"
              >
                {props.acceptAllLabel}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
