import { gql } from "graphql-request";

export const GetPrivateProfile = gql`
  query GetProfilePrivate($profile: ProfileInput!) {
    getProfilePrivate(profile: $profile) {
      ... on ProfileNoPermission {
        __typename
        message
      }
      ... on ProfileNotFound {
        __typename
        message
      }
      ... on ProfilePrivate {
        __typename
        email
        relapseStartDate
        followData {
          followingOthers {
            avatar {
              clothes
              face
              gender
              hairColor
              hairStyle
              skin
              special
            }
            followDate
            name
            start_date
            uid
          }
        }
      }
    }
  }
`;
