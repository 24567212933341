import "./CTAButton.scss";

type Props = {
  title?: string;
  className?: string;
  onClick: () => void;
  buttonText?: string;
  transparent?: boolean;
  disabled?: boolean;
  link?: boolean;
  noMargin?: boolean;
  loading?: boolean;
};

export const CTAButton = ({
  title,
  className,
  onClick,
  buttonText,
  transparent,
  link,
  disabled,
  loading,
}: Props): JSX.Element => {
  return (
    <div
      className={`cta-button-container ${
        link ? "cta-button-container--no-margin" : ""
      }`}
    >
      {buttonText && <p className="button-text-title small1">{buttonText}</p>}
      <button
        disabled={disabled || loading}
        onClick={() => onClick()}
        className={`cta-button ${className ? className : ""} ${
          transparent ? "cta-button--transparent" : ""
        } ${link ? "cta-button--links" : ""} `}
      >
        <h2
          className={`title3 cta-title ${
            transparent ? "cta-title--transparent" : ""
          } ${link ? "cta-title--links" : ""}`}
        >
          {loading ? "Loading..." : title}
        </h2>
      </button>
    </div>
  );
};
