import { t } from "i18n-js";
import { Link } from "react-router-dom";
import { loadAsset } from "../../utils/loadAsset";
import "./Footer.scss";

export const Footer = (): JSX.Element => {
  return (
    <footer className="footer-container flex-center">
      <div className="footer-main content-width">
        <div className="nav-container">
          <div className="logo-section">
            <img
              {...loadAsset("smokeless_logo_footer.svg")}
              alt="smokeless-logo"
            />
          </div>
          <div className="link-sections">
            <div className="section">
              <h1 className="section-title">{t("FOOTER_COMPANY")}</h1>
              <Link to={"/labeling"} className="section-text">
                {t("FOOTER_LABELLING")}
              </Link>
              {/* {<a href="https://smokeless.world/" className="section-text">
                {t("FOOTER_PRESS")}
              </a>} */}
              <a href="https://smokeless.world/jobs" className="section-text">
                {t("FOOTER_CAREER")}
              </a>
            </div>
            <div className="section">
              <h1 className="section-title">{t("FOOTER_PRODUCT")}</h1>
              {/* {<a href="https://smokeless.world/" className="section-text">
                {t("FOOTER_FAQ")}
              </a>
              <a href="https://smokeless.world/" className="section-text">
                {t("FOOTER_SUPPORT")}
              </a>} */}
              <a href="https://smokeless.world/ifu" className="section-text">
                {t("FOOTER_MANUAL")}
              </a>
            </div>
            {/* { <div className="section">
              <h1 className="section-title">{t("FOOTER_FOLLOW_US")}</h1>
              <a href="https://smokeless.world/" className="section-text">
                {t("FACEBOOK")}
              </a>
              <a href="https://smokeless.world/" className="section-text">
                {t("INSTAGRAM")}
              </a>
            </div>} */}
          </div>
        </div>
        <div className="details-container">
          <div className="copyright-section small1">
            <p>{t("FOOTER_COPYRIGHT")}</p>
          </div>
          <div className="bottom-section">
            <div className="data-terms-section small1">
              <a href="https://smokeless.world/impressum.html">
                {t("FOOTER_IMPRINT")}
              </a>
              <a href="https://static.smokeless.world/de/privacy.html">
                {t("FOOTER_DATA")}
              </a>
              <a href="https://static.smokeless.world/de/terms.html">
                {t("FOOTER_TERMS")}
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
