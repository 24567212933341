import { t } from "i18n-js";
import { Dispatch, SetStateAction } from "react";
import { useHideBurgerMenu } from "../../hooks/useHideBurgerMenu";
import "./CreateNewPasswordConfirmationScreen.scss";

type Props = {
  setDisplayBurgerMenu: Dispatch<SetStateAction<boolean>>;
};

export const CreateNewPasswordConfirmationScreen = (props: Props) => {
  const { setDisplayBurgerMenu } = props;
  useHideBurgerMenu(setDisplayBurgerMenu);
  return (
    <div className="create-new-password-confirm-container content-container-width component-container">
      <h1 className="title2">{t("NEW_PASSWORD_CONFIRMATION_WEB_TITLE")}</h1>
      <p className="text-main regular">
        {t("NEW_PASSWORD_CONFIRMATION_WEB_TEXT")}
      </p>
    </div>
  );
};
