/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { t } from "i18n-js";
import { useState } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import "./App.scss";
import { EmailVerificationProtectedRoute } from "./components/EmailVerificationProtectedRoute";
import { Footer } from "./components/Footer/Footer";
import { GDPROverlay } from "./components/GDPROverlay/GDPROverlay";
import { NavBar } from "./components/Navbar/Navbar";
import { CreateNewPasswordConfirmationScreen } from "./containers/CreateNewPasswordConfirmationScreen/CreateNewPasswordConfirmationScreen";
import { CreateNewPasswordFailureScreen } from "./containers/CreateNewPasswordFailureScreen/CreateNewPasswordFailureScreen";
import { CreateNewPasswordScreen } from "./containers/CreateNewPasswordScreen/CreateNewPasswordScreen";
import { CreateVoucherContainer } from "./containers/CreateVoucherContainer/CreateVoucherContainer";
import { ChallengeInvite } from "./containers/DeepLinkingScreen/ChallengeInviteScreen";
import { DeleteAccountContainer } from "./containers/DeleteAccountContainer/DeleteAccountContainer";
import { DownloadDataContainer } from "./containers/DownloadDataContainer/DownloadDataContainer";
import { DrawerContainer } from "./containers/DrawerContainer/DrawerContainer";
import { EditEmailConfirmationScreen } from "./containers/EditEmailConfirmationScreen/EditEmailConfirmationScreen";
import { EmailVerificationScreen } from "./containers/EmailVerificationScreen/EmailVerificationScreen";
import { EmailVerifiedScreen } from "./containers/EmailVerifiedScreen/EmailVerifiedScreen";
import { ForgotPasswordContainer } from "./containers/ForgotPassword/ForgotPassword";
import { ForgotPasswordConfirmContainer } from "./containers/ForgotPasswordConfirm/ForgotPasswordConfirm";
import { LabelingContainer } from "./containers/LabelingContainer/LabelingContainer";
import { LoginContainer } from "./containers/LoginContainer/LoginContainer";
import { NoInternetContainer } from "./containers/NoInternetContainer/NoInternetContaines";
import { OverviewContainer } from "./containers/OverviewContainer/OverviewContainer";
import { ProfileContainer } from "./containers/ProfileContainer/ProfileContainer";
import { RegisterContainer } from "./containers/RegisterContainer/RegisterContainer";
import { SearchContainer } from "./containers/SearchContainer/SearchContainer";
import { StartContainer } from "./containers/StartContainer/StartContainer";
import { VoucherSentScreen } from "./containers/VoucherSentScreen/VoucherSentScreen";
import { useAuth } from "./hooks/useAuth";

export const App = (): JSX.Element => {
  const { isAuth } = useAuth();
  const [drawerIsOpen, setDrawerIsOpen] = useState(false);
  const [displayBurgerMenu, setDisplayBurgerMenu] = useState(true);
  const location = useLocation();
  if (location.pathname === "/") {
    return <Navigate to="/start" />;
  }
  return (
    <div className="main-container">
      <NavBar
        onClick={() => setDrawerIsOpen(!drawerIsOpen)}
        displayBurgerMenu={displayBurgerMenu}
      />
      <GDPROverlay
        title={t("COOKIE_BANNER_TITLE")}
        description={t("COOKIE_BANNER_TEXT")}
        showCheckboxesLabel={t("COOKIE_BANNER_DETAIL_BUTTON")}
        acceptAllLabel={t("COOKIE_BANNER_ACCEPT_ALL_BUTTON")}
        acceptSelectionLabel={t("COOKIE_BANNER_DETAIL_ACCEPT_BUTTON")}
        checkboxes={[
          {
            name: t("COOKIE_BANNER_NECESSARY_TITLE"),
            required: true,
            id: "necessary",
            text: t("COOKIE_BANNER_NECESSARY_TEXT"),
          },
          {
            name: t("COOKIE_BANNER_STATISTICS_TITLE"),
            onChecked: () => {
              console.log("load google analytics");
            },
            id: "statistics",
            text: t("COOKIE_BANNER_STATISTICS_TEXT"),
          },
          {
            name: t("COOKIE_BANNER_MARKETING_TITLE"),
            onChecked: () => {
              console.log("load google analytics");
            },
            id: "marketing",
            text: t("COOKIE_BANNER_MARKETING_TEXT"),
          },
        ]}
      />
      <div className="navbar-separator" />
      <Routes>
        <Route path="/delete-account" element={<DeleteAccountContainer />} />
        <Route path="/download-data" element={<DownloadDataContainer />} />
        <Route path="/publicprofile/:id" element={<ProfileContainer />} />

        <Route path="/register/:type/:id" element={<RegisterContainer />} />
        <Route path="/register" element={<RegisterContainer />} />
        <Route path="/login" element={<LoginContainer />} />
        <Route
          path="/verify_email"
          element={
            <EmailVerifiedScreen setDisplayBurgerMenu={setDisplayBurgerMenu} />
          }
        />
        <Route path="challenge/:challengeToken" element={<ChallengeInvite />} />
        <Route element={<EmailVerificationProtectedRoute />}>
          {/* EMAIL VERIFICATION REDIRECT ROUTE STARTS */}

          <Route path="/start" element={<StartContainer />} />
          <Route path="/forgotPassword" element={<ForgotPasswordContainer />} />
          <Route
            path="/forgotPasswordConfirm"
            element={<ForgotPasswordConfirmContainer />}
          />
          <Route path="/login/:type/:id" element={<LoginContainer />} />
          <Route path="/labeling" element={<LabelingContainer />} />
          <Route path="/overview" element={<OverviewContainer />} />
          <Route path="/search" element={<SearchContainer />} />
          <Route path="/no-internet" element={<NoInternetContainer />} />

          <Route
            path="/create-voucher/:username/:id"
            element={<CreateVoucherContainer />}
          />
          <Route path="/voucher-sent/:uid" element={<VoucherSentScreen />} />
          <Route
            path="/reset_password"
            element={
              <CreateNewPasswordScreen
                setDisplayBurgerMenu={setDisplayBurgerMenu}
              />
            }
          />
          <Route
            path="/change_email"
            element={
              <EditEmailConfirmationScreen
                setDisplayBurgerMenu={setDisplayBurgerMenu}
              />
            }
          />
          <Route
            path="/reset_password/reset_password_confirmed"
            element={
              <CreateNewPasswordConfirmationScreen
                setDisplayBurgerMenu={setDisplayBurgerMenu}
              />
            }
          />
          <Route
            path="/reset_password/reset_password_failure"
            element={
              <CreateNewPasswordFailureScreen
                setDisplayBurgerMenu={setDisplayBurgerMenu}
              />
            }
          />

          <Route path="*" element={<Navigate to="/start" />} />
          {/* EMAIL VERIFICATION REDIRECT ROUTE ENDS */}
        </Route>

        <Route
          path="email-verification"
          element={<EmailVerificationScreen />}
        />
        <Route path="*" element={<Navigate to="/start" />} />
      </Routes>
      <Footer />
      <DrawerContainer
        isOpen={drawerIsOpen}
        isAuth={isAuth}
        closeDrawer={() => setDrawerIsOpen(false)}
      />
    </div>
  );
};
