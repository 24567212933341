import { gql } from "graphql-request";

export const Register = gql`
  mutation Register($updatedData: RegisterWithUsernameInput!) {
    register(updatedData: $updatedData) {
      ... on AccessPayload {
        __typename
        uid
        accessToken
      }
      ... on RegistrationError {
        __typename
        error
      }
    }
  }
`;
