import { useParams } from "react-router-dom";
import { ReactComponent as AppStore } from "../../app_store_en.svg";
import { CTAButton } from "../../components/CTAButton/CTAButton";
import { ReactComponent as GooglePlay } from "../../google_play_en.svg";
import "./ChallengeInviteScreen.scss";

export const ChallengeInvite = (): JSX.Element => {
  const { challengeToken } = useParams();
  return (
    <div className="content-container-width margin-component">
      <div className="challenge-invite-wrapper p-20 d-column">
        <h2 className="title2">Join to the Challenge!</h2>

        <div className="challenge-invite-cta-wrapper">
          <div>
            <CTAButton
              title={"Open Smokeless App"}
              link
              onClick={() =>
                (window.location.href = `smokeless.app://challenge/${challengeToken}`)
              }
            />
          </div>
          <div className="store-links-container">
            <AppStore width={120} height={50} />
            <GooglePlay width={130} height={50} />
          </div>
        </div>
      </div>
    </div>
  );
};
