import { gql } from "graphql-request";

export const AddVoucher = gql`
  mutation addVoucher($profile: ProfileInput!, $updatedData: AddVoucherInput!) {
    addVoucher(profile: $profile, updatedData: $updatedData) {
      ... on UpdateOk {
        __typename
        ok
      }
      ... on InvalidInput {
        __typename
        errors {
          constraints {
            name
            message
          }
        }
      }
    }
  }
`;
