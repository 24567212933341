import { t } from "i18n-js";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { CTAButton } from "../../components/CTAButton/CTAButton";
import { Input } from "../../components/Input/Input";
import { ResetPasswordMutation } from "../../gql/graphql-types";
import { useHideBurgerMenu } from "../../hooks/useHideBurgerMenu";
import { useResetPasswordMutation } from "../../redux/SmokelessApiSlice";
import { Validators } from "../../utils/validators";
import "./CreateNewPasswordScreen.scss";

type Props = {
  setDisplayBurgerMenu: Dispatch<SetStateAction<boolean>>;
};

export const CreateNewPasswordScreen = (props: Props): JSX.Element => {
  const { setDisplayBurgerMenu } = props;
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [resetPassword] = useResetPasswordMutation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const setNewPassword = async () => {
    const result = (await resetPassword({
      updatedData: { password: password, token: searchParams.get("token") },
    })) as { data?: ResetPasswordMutation; error?: unknown };
    if (result.data?.resetPassword.__typename === "UpdateOk") {
      navigate("reset_password_confirmed");
    } else {
      navigate("reset_password_failure");
    }
  };

  useHideBurgerMenu(setDisplayBurgerMenu);

  useEffect(() => {
    if (password && password.length > 0 && !Validators.password(password)) {
      setPasswordError(t("PASSWORD_FORM_TOO_SHORT"));
    } else {
      setPasswordError("");
    }
  }, [password]);
  const buttonDisabled = passwordError || !password ? true : false;
  return (
    <div className="create-new-password-confirm-container content-container-width component-container">
      <h1 className="title2">{t("NEW_PASSWORD_WEB_TITLE")}</h1>
      <p className="text-main regular">{t("NEW_PASSWORD_WEB_TEXT")}</p>
      <Input
        placeholder={t("NEW_PASSWORD_WEB_FORM")}
        type="text"
        inputText={password}
        onChange={(input) => setPassword(input)}
        error={passwordError}
      />
      <CTAButton
        onClick={() => void setNewPassword()}
        title={t("CTA_NEW_PASSWORD_WEB")}
        className="cta-button"
        disabled={buttonDisabled}
      />
    </div>
  );
};
