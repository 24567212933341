import { t } from "i18n-js";
import { useNavigate } from "react-router-dom";
import { CTAButton } from "../../components/CTAButton/CTAButton";

export const ForgotPasswordConfirmContainer = (): JSX.Element => {
  const navigate = useNavigate();
  return (
    <div className="login-container content-container-width">
      <h1 className="title2 login-title">{t("CHECK_YOUR_MAIL_TITLE")}</h1>

      <CTAButton
        title={t("CTA_BACK_TO_LOGIN")}
        className="cta-button"
        onClick={() => {
          navigate("/login");
        }}
      />
    </div>
  );
};
