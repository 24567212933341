/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { t } from "i18n-js";
import { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CTAButton } from "../../../components/CTAButton/CTAButton";
import { ErrorBox } from "../../../components/ErrorBox/ErrorBox";
import { LoadingSpinner } from "../../../components/LoadingSpinner/LoadingSpinner";
import { ProfileAchievements } from "../../../components/ProfileAchievements/ProfileArchievements";
import { ProfileAvatarUsername } from "../../../components/ProfileAvatarUsername/ProfileAvatarUsername";
import { UserBar } from "../../../components/ProfileBar/ProfileBar";
import { ProfileQuitDate } from "../../../components/ProfileQuitDate/ProfileQuitDate";
import {
  AddProfileViewMutation,
  FollowMutation,
  UnfollowMutation,
} from "../../../gql/graphql-types";
import {
  useAddProfileViewMutation,
  useFollowMutation,
  useGetPrivateProfileQuery,
  useUnfollowMutation,
} from "../../../redux/SmokelessApiSlice";
import { getSmokelessPhase } from "../../../utils/helpers";
import "./ProfileTopSection.scss";

type Props = {
  username: string;
  id: string;
  stars: number;
  challenges: number;
  highfives: number;
  supporters: number;
  supportOthers: number;
  awardsTotal: number;
  gender: number;
  hairStyle: number;
  hairColor: number;
  cloth: number;
  skin: number;
  face: number;
  special: number;
  daysInProgram: number;
  timezone: string;
  quitDate: string;
  hideProgressBar?: boolean;
  searchedUserRelapseDay?: number;
};

export const ProfileTopSection = (props: Props): JSX.Element => {
  const [isFollowing, setIsFollowing] = useState(false);
  const [addProfileView] = useAddProfileViewMutation();
  const [follow, { isLoading, isError }] = useFollowMutation();
  const [unfollow, { isLoading: isLoadingUnFollow, isError: isErrorUnFollow }] =
    useUnfollowMutation();
  const myId = localStorage.getItem("uid");
  const navigate = useNavigate();
  const resultsPrivate = useGetPrivateProfileQuery(
    {
      profile: { uid: myId || "" },
    },
    { refetchOnMountOrArgChange: true }
  );
  const smokelessPhase = getSmokelessPhase(props.daysInProgram);

  useEffect(() => {
    if (
      resultsPrivate &&
      resultsPrivate.data?.getProfilePrivate &&
      resultsPrivate.data?.getProfilePrivate.__typename === "ProfilePrivate"
    ) {
      const isFollowingUser =
        resultsPrivate.data.getProfilePrivate.followData.followingOthers
          .map((user) => user.uid)
          .includes(props.id);
      if (isFollowingUser && !isFollowing) {
        setIsFollowing(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.id]);

  useEffect(() => {
    const increaseProfileView = async () => {
      try {
        const results = (await addProfileView({
          profile: { uid: props.id },
        })) as { data: AddProfileViewMutation; error: unknown };
        if (results.data.addProfileView.__typename !== "UpdateOk") {
          throw results;
        }
      } catch (error) {
        console.log(error);
      }
    };
    increaseProfileView().catch((e) => console.warn(e));
  }, [props.id, myId, addProfileView]);

  const followUser = async () => {
    if (myId) {
      if (!isFollowing) {
        const results = (await follow({
          profile: { uid: myId },
          updatedData: { followedUid: props.id },
        })) as { data: FollowMutation; error: unknown };
        if (results.data.follow.__typename === "UpdateOk") {
          setIsFollowing(true);
        }
      } else {
        const results = (await unfollow({
          profile: { uid: myId },
          updatedData: { followedUid: props.id },
        })) as { data: UnfollowMutation; error: unknown };

        if (results.data.unfollow.__typename === "UpdateOk") {
          setIsFollowing(false);
        }
      }
    } else {
      navigate(`/register/follow/${props.id}`);
    }
  };

  return (
    <div className="profile-top-section-container flex-center gradient_blue_dark">
      <div className="content-width content-container-width">
        <ProfileAvatarUsername
          username={props.username}
          gender={props.gender}
          hairStyle={props.hairStyle}
          hairColor={props.hairColor}
          face={props.face}
          skin={props.skin}
          cloth={props.cloth}
          special={props.special}
        />
        {props.hideProgressBar ? null : (
          <UserBar
            dayInProgram={props.daysInProgram}
            phase={smokelessPhase}
            relapseDay={props.searchedUserRelapseDay}
            currentDay={props.daysInProgram}
          />
        )}

        {(isError || isErrorUnFollow) && (
          <ErrorBox title={t("FAIL_NO_INTERNET_TITLE")} />
        )}
        {!isLoading && !isLoadingUnFollow ? (
          myId === props.id ? (
            <Fragment />
          ) : (
            <CTAButton
              onClick={() => followUser()}
              title={isFollowing ? t("CTA_UNFOLLOW") : t("CTA_SUPPORT_USER")}
              className="transparent-button drawer-margin"
              transparent={isFollowing}
            />
          )
        ) : (
          <LoadingSpinner />
        )}
        <ProfileQuitDate quitDate={props.quitDate} timezone={props.timezone} />
        <ProfileAchievements
          stars={props.stars}
          challenges={props.challenges}
          highfives={props.highfives}
          supporters={props.supporters}
          supportOthers={props.supportOthers}
          awardsTotal={props.awardsTotal}
        />
      </div>
    </div>
  );
};
