import { Avatar } from "../Avatar/Avatar";
import "./ProfileAvatarUsername.scss";

type Props = {
  username: string;
  gender: number;
  hairStyle: number;
  hairColor: number;
  cloth: number;
  skin: number;
  face: number;
  special: number;
};

export const ProfileAvatarUsername = (props: Props): JSX.Element => {
  const { username, gender, hairStyle, hairColor, face, skin, cloth, special } =
    props;
  return (
    <div className="profile-avatar-username-container flex-center">
      <div className="profile-avatar-container">
        <Avatar
          gender={gender}
          hairStyle={hairStyle}
          hairColor={hairColor}
          face={face}
          skin={skin}
          clothes={cloth}
          special={special}
          height={160}
        />
      </div>
      <div className="username-container">
        <p className="username personal">{username}</p>
      </div>
    </div>
  );
};
