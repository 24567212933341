import { t } from "i18n-js";
import packageJSON from "../../../package.json";
import { ListItemAssetLarge } from "../../components/ListItemAssetLarge/ListItemAssetLarge";

import { loadAsset } from "../../utils/loadAsset";
import "./LabelingContainer.scss";

export const LabelingContainer = (): JSX.Element => {
  return (
    <>
      <div className="labeling-container content-container-width">
        <div className="logo-section">
          <img
            className="start-logo"
            {...loadAsset("support_others_start.svg")}
            alt="smokeless-icon"
          />
        </div>

        <p className={"title2"}>{t("LABELLING_APP_TITLE")}</p>
        <p className={"small1"}>
          {t("LABELLING_APP_VERSION", { version: packageJSON.version })}
        </p>
        <ListItemAssetLarge
          text={t("LABELLING_CE")}
          icon={loadAsset("ce.svg")}
        />
        <ListItemAssetLarge text={""} icon={loadAsset("medical_device.svg")} />
        <ListItemAssetLarge
          text={"(01)4260705080042\n(11)220500(8012)1.2.0\nGS1-128"}
          icon={loadAsset("udidi.svg")}
        />
        <ListItemAssetLarge
          text={t("LABELLING_MANUFACTURER_1")}
          icon={loadAsset("manufacturer_outline.svg")}
        />
        <ListItemAssetLarge
          text={t("LABELLING_MANUFACTURER_2")}
          icon={loadAsset("manufacturer.svg")}
        />
        <p className={"title3  label-title"}>
          {t("LABELLING_INTENDED_PURPOSE_TITLE")}
        </p>
        <p className={"regular"}>{t("LABELLING_INTENDED_PURPOSE")}</p>
        <p className={"title3 label-title"}>{t("LABELLING_SAFETY_TITLE")}</p>
        <li className="regular">{t("LABELLING_SAFETY_1")}</li>
        <li className="regular">{t("LABELLING_SAFETY_2")}</li>
        <li className="regular">{t("LABELLING_SAFETY_3")}</li>
        <p className="title3 label-title">{t("LABELLING_WARNING_TITLE")}</p>
        <li className="regular">{t("CONDITIONS_MEDICAL_1")}</li>
        <li className="regular">{t("CONDITIONS_MEDICAL_2")}</li>
        <li className="regular">{t("CONDITIONS_MEDICAL_3")}</li>
        <li className="regular">{t("CONDITIONS_MEDICAL_4")}</li>
        <p className="title3 label-title">
          {t("LABELLING_SIDE_EFFECTS_TITLE")}
        </p>
        <p className="regular">{t("LABELLING_SIDE_EFFECTS_TEXT")}</p>
        <p className="title3 label-title">
          {t("LABELLING_INTERACTIONS_TITLE")}
        </p>
        <p className="regular">{t("LABELLING_INTERACTIONS_TEXT")}</p>
        <p className="title3 label-title">{t("LABELLING_MANUAL_TITLE")}</p>
        <a href="https://smokeless.world/ifu">
          <ListItemAssetLarge
            text={t("LABELLING_MANUAL_URL")}
            icon={loadAsset("manual.svg")}
          />
        </a>
        <p className="title3 label-title">{t("LABELLING_SUPPORT_TITLE")}</p>
        <ListItemAssetLarge
          text={t("LABELLING_SUPPORT_TEXT")}
          icon={loadAsset("support.svg")}
        />
      </div>
    </>
  );
};
