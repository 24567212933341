import { ProfileHighFives } from "../../../components/ProfileHighFives/ProfileHighFives";
import "./ProfileHighFivesSection.scss";

type Props = {
  highfives: number;
  username: string;
  uid: string;
};

export const ProfileHighFivesSection = (props: Props): JSX.Element => {
  return (
    <div className="profile-high-fives-section-container flex-center ">
      <div className="">
        <ProfileHighFives {...props} />
      </div>
    </div>
  );
};
