import { useRef, useState } from "react";
import arrowLineSVG from "../../arrow_line_down_small_blue.svg";
import checkedBoxSVG from "../../checkbox_checked.svg";
import uncheckedBoxSVG from "../../checkbox_unchecked.svg";
import "./GDPRCheckbox.scss";

type Props = {
  id: string;
  title: string;
  text: string;
  changeChecked: (id: string, state: boolean) => void;
  collapseCheckboxesExceptClicked: (id: string) => void;
  showText: string;
  isShow: boolean;
  isRequired: boolean;
};
export const GDPRCheckbox = (props: Props) => {
  const [isChecked, setIsChecked] = useState(props.isRequired);
  const textRef = useRef<HTMLElement>(null);

  return (
    <div className="GDPRCheckbox">
      <div className="GDPRCheckbox__checkboxWrapper">
        <div className="GDPRCheckbox__checkbox">
          <input
            disabled={props.isRequired}
            onChange={(e) => {
              props.changeChecked(
                props.id,
                e.currentTarget.checked || props.isRequired
              );
              setIsChecked(!isChecked || props.isRequired);
            }}
            type={"checkbox"}
            id="GDPRCheckbox__checkboxInput"
          />
          <img
            src={isChecked ? checkedBoxSVG : uncheckedBoxSVG}
            alt="smokeless_checkbox"
          />
        </div>
        <div
          className="GDPRCheckbox__textWrapper"
          onClick={() => {
            props.collapseCheckboxesExceptClicked(props.id);
          }}
        >
          <span className="GDPRCheckbox__span"> {props.title}</span>
          <img src={arrowLineSVG} alt="smokeless_arrow" />
        </div>
      </div>

      <span
        className={`GDPRCheckbox__text${
          props.isShow ? " active" : " deactive"
        } `}
        onClick={() => {
          props.collapseCheckboxesExceptClicked(props.id);
        }}
        ref={textRef}
        style={{
          maxHeight:
            props.showText === props.id
              ? textRef.current.scrollHeight + 60
              : "0px",
        }}
      >
        {props.text}
      </span>
    </div>
  );
};
