import moment, { Moment } from "moment";
import { getProfileDigits } from "../../utils";
import { loadAsset } from "../../utils/loadAsset";
import "./ProfileQuitDate.scss";
type Props = {
  quitDate: string;
  timezone: string;
};

export const ProfileQuitDate = (props: Props): JSX.Element => {
  const date: Moment = moment(props.quitDate).tz(props.timezone);
  const day = date.format("D");

  const firstDigit =
    day.length < 2
      ? getProfileDigits(0)
      : getProfileDigits(parseInt(day.charAt(0), 10));

  const secondDigit =
    day.length < 2
      ? getProfileDigits(parseInt(day.charAt(0), 10))
      : getProfileDigits(parseInt(day.charAt(1), 10));
  const year = date.format("YYYY");
  const yearFormatted = year.substr(0, 2);
  return (
    <div className="profile-quit-date-container flex-center">
      <img
        className="quit-background"
        {...loadAsset("quitdate_stage.png")}
        alt="quitdate-background"
      />
      <div className="profile-quit-date-digits">
        <img alt={day} {...firstDigit} />
        <img alt="" {...secondDigit} />
      </div>
      <div className="month-year-container">
        <div className="month title2">{date.format("MMMM").toUpperCase()}</div>
        <div className="year title3">
          <div>{yearFormatted}</div>
          <img alt="smokeless-logo" {...loadAsset("home.svg")} />
          <div>{date.format("YY")}</div>
        </div>
      </div>
    </div>
  );
};
