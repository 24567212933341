import "./ListItemAssetLarge.scss";

export const ListItemAssetLarge = (props: {
  text?: string;
  icon?: { src: string; srcSet?: string } | undefined;
}): JSX.Element => {
  return (
    <div className="ListItemAssetLarge">
      <img alt="icon" {...props.icon} />
      <div>{props.text}</div>
    </div>
  );
};
