import { t } from "i18n-js";
import { useNavigate } from "react-router-dom";
import { CTAButton } from "../../components/CTAButton/CTAButton";
import { useAuth } from "../../hooks/useAuth";
import { useExportUserDataQuery } from "../../redux/SmokelessApiSlice";
import "./DownloadDataContainer.scss";

export const DownloadDataContainer = (): JSX.Element => {
  const { uid, isAuth } = useAuth();

  const navigate = useNavigate();
  const { data, isLoading } = useExportUserDataQuery(
    {
      profile: { uid: uid ? uid : "1" },
    },
    { refetchOnMountOrArgChange: true }
  );

  const download = () => {
    if (data?.exportUserData?.__typename === "UserDataExport") {
      const csvContent =
        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
        "data:text/csv;charset=utf-8," + data.exportUserData.dataCsv;
      const encodedUri = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "SmokelessUserData.csv");
      document.body.appendChild(link); // Required for FF

      link.click();
    }
  };
  return (
    <div className="download-data-container content-container-width">
      <h1 className="title2">{t("DOWNLOAD_USERDATA_TITLE")}</h1>
      <p className="text-main regular">{t("DOWNLOAD_USERDATA_TEXT")}</p>
      <div className="buttons-container">
        {isLoading ? (
          <div>loading</div>
        ) : isAuth ? (
          <CTAButton title={t("CTA_DOWNLOAD_USERDATA")} onClick={download} />
        ) : (
          <CTAButton
            title={t("CTA_LOGIN")}
            onClick={() => navigate("/login")}
          />
        )}
      </div>
    </div>
  );
};
