import { useEffect, useState } from "react";
import { loadAsset } from "../../utils/loadAsset";
import "./CheckItem.scss";

type Props = {
  text: string;
  infoIcon?: boolean;
  infoIconLink?: string;
  selected?: boolean;
  isChecked?: boolean;
  checked?: (arg0: boolean) => void;
};

export const CheckItem = (props: Props): JSX.Element => {
  const { text, infoIcon, checked, infoIconLink } = props;
  const [checkedItem, setCheckedItem] = useState(false);
  useEffect(() => {
    if (props.selected !== undefined) {
      if (props.selected === true && !checkedItem) {
        setCheckedItem(true);
      } else if (props.selected === false && checkedItem) {
        setCheckedItem(false);
      }
    }
  }, [props.selected, checkedItem]);
  const showCheck = () => {
    if (checkedItem) {
      return (
        <img
          alt="checkbox-checked"
          className="checkbox-icon-img checkbox-icon-img--checked"
          {...loadAsset("checkbox_checked.svg")}
        />
      );
    } else {
      return (
        <img
          alt="uncheckbox-checked"
          className="checkbox-icon-img"
          {...loadAsset("checkbox_unchecked.svg")}
        />
      );
    }
  };
  const checkItem = (check: boolean) => {
    setCheckedItem(check);
    if (checked) {
      checked(check);
    }
  };
  return (
    <div className="check-item-container">
      <div className="check-item-half" onClick={() => checkItem(!checkedItem)}>
        <button
          className={`checkbox-button ${
            checkedItem ? "checkbox-button--checked" : ""
          }`}
        >
          {showCheck()}
        </button>
        <p className="title5">{text}</p>
      </div>
      {/* <FailureMessage text={this.props.warning} /> */}
      {infoIcon && (
        <a
          target="_blank"
          href={infoIconLink ? infoIconLink : "https://smokeless.world"}
          className="hitbox-info"
          rel="noreferrer"
        >
          <img
            alt="info icon"
            className="info-icon"
            {...loadAsset("info_filled.svg")}
          />
        </a>
      )}
    </div>
  );
};
