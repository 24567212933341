/* eslint-disable */
import { createApi } from "@reduxjs/toolkit/query/react";
import { ClientError } from "graphql-request";
import { graphqlClient } from "../gql/graphql-client";
import {
  AddHighfivesMutation,
  AddHighfivesMutationVariables,
  AddProfileViewMutation,
  AddProfileViewMutationVariables,
  AddVoucherMutation,
  AddVoucherMutationVariables,
  DeleteUserMutation,
  DeleteUserMutationVariables,
  ExportUserDataQuery,
  ExportUserDataQueryVariables,
  FollowMutation,
  FollowMutationVariables,
  GetProfilePrivateQuery,
  GetProfilePrivateQueryVariables,
  GetProfilePublicQuery,
  GetProfilePublicQueryVariables,
  RegisterMutation,
  RegisterMutationVariables,
  RegistrationFlagsQuery,
  RegistrationFlagsQueryVariables,
  RequestPasswordResetMutation,
  RequestPasswordResetMutationVariables,
  ResendVerificationEmailMutation,
  ResendVerificationEmailMutationVariables,
  ResetPasswordMutation,
  ResetPasswordMutationVariables,
  SearchUserStartingWithQuery,
  SearchUserStartingWithQueryVariables,
  UnfollowMutation,
  UnfollowMutationVariables,
} from "../gql/graphql-types";
import { AddHighfives } from "../gql/mutations/AddHighfives";
import { AddProfileView } from "../gql/mutations/AddProfileView";
import { AddVoucher } from "../gql/mutations/AddVoucher";
import { DeleteUser } from "../gql/mutations/DeleteUser";
import { Follow } from "../gql/mutations/Follow";
import { Register } from "../gql/mutations/Register";
import { RequestPasswordReset } from "../gql/mutations/RequestPasswordReset";
import { ResendVerificationEmail } from "../gql/mutations/ResendVerificationEmail";
import { ResetPassword } from "../gql/mutations/ResetPassword";
import { Unfollow } from "../gql/mutations/Unfollow";
import { ExportUserData } from "../gql/queries/ExportUserData";
import { GetPrivateProfile } from "../gql/queries/GetPrivateProfile";
import { GetPublicProfile } from "../gql/queries/GetPublicProfile";
import { RegistrationFlags } from "../gql/queries/RegistrationFlags";
import { SearchUserStartingWith } from "../gql/queries/SearchUserStartingWith";

// TODO: type base query fn and enable eslint rules
export const SmokelessApiSlice = createApi({
  reducerPath: "smokelessApi",
  // eslint-disable-next-line
  baseQuery: async (body) => {
    //needs the queryfn type
    try {
      const response = await graphqlClient.request(
        body.document,
        body.variables
      );

      return { data: response };
    } catch (error) {
      if (error instanceof ClientError) {
        const { name, message, stack, request, response } = error;
        return { error: { name, message, stack }, meta: { request, response } };
      }
      throw error;
    }
  },
  endpoints: (builder) => ({
    register: builder.mutation<RegisterMutation, RegisterMutationVariables>({
      query: ({ updatedData }) => ({
        document: Register,
        variables: {
          updatedData,
        },
      }),
    }),
    requestPasswordReset: builder.mutation<
      RequestPasswordResetMutation,
      RequestPasswordResetMutationVariables
    >({
      query: ({ updatedData }) => ({
        document: RequestPasswordReset,
        variables: {
          updatedData,
        },
      }),
    }),
    addVoucher: builder.mutation<
      AddVoucherMutation,
      AddVoucherMutationVariables
    >({
      query: ({ profile, updatedData }) => ({
        document: AddVoucher,
        variables: {
          profile,
          updatedData,
        },
      }),
    }),
    addProfileView: builder.mutation<
      AddProfileViewMutation,
      AddProfileViewMutationVariables
    >({
      query: ({ profile }) => ({
        document: AddProfileView,
        variables: {
          profile,
        },
      }),
    }),
    addHighfives: builder.mutation<
      AddHighfivesMutation,
      AddHighfivesMutationVariables
    >({
      query: ({ profile, updatedData }) => ({
        document: AddHighfives,
        variables: {
          profile,
          updatedData,
        },
      }),
    }),
    follow: builder.mutation<FollowMutation, FollowMutationVariables>({
      query: ({ profile, updatedData }) => ({
        document: Follow,
        variables: {
          profile,
          updatedData,
        },
      }),
    }),
    unfollow: builder.mutation<UnfollowMutation, UnfollowMutationVariables>({
      query: ({ profile, updatedData }) => ({
        document: Unfollow,
        variables: {
          profile,
          updatedData,
        },
      }),
    }),
    deleteUser: builder.mutation<
      DeleteUserMutation,
      DeleteUserMutationVariables
    >({
      query: ({ profile }) => ({
        document: DeleteUser,
        variables: {
          profile,
        },
      }),
    }),
    getPublicProfile: builder.query<
      GetProfilePublicQuery,
      GetProfilePublicQueryVariables
    >({
      query: ({ profile }) => ({
        document: GetPublicProfile,
        variables: {
          profile,
        },
      }),
    }),
    exportUserData: builder.query<
      ExportUserDataQuery,
      ExportUserDataQueryVariables
    >({
      query: ({ profile }) => ({
        document: ExportUserData,
        variables: {
          profile,
        },
      }),
    }),
    searchUserStartingWith: builder.query<
      SearchUserStartingWithQuery,
      SearchUserStartingWithQueryVariables
    >({
      query: ({ search }) => ({
        document: SearchUserStartingWith,
        variables: {
          search,
        },
      }),
    }),
    getPrivateProfile: builder.query<
      GetProfilePrivateQuery,
      GetProfilePrivateQueryVariables
    >({
      query: ({ profile }) => ({
        document: GetPrivateProfile,
        variables: {
          profile,
        },
      }),
    }),
    resetPassword: builder.mutation<
      ResetPasswordMutation,
      ResetPasswordMutationVariables
    >({
      query: ({ updatedData }) => ({
        document: ResetPassword,
        variables: {
          updatedData,
        },
      }),
    }),
    resendVerificationEmail: builder.mutation<
      ResendVerificationEmailMutation,
      ResendVerificationEmailMutationVariables
    >({
      query: ({ updatedData }) => ({
        document: ResendVerificationEmail,
        variables: {
          updatedData,
        },
      }),
    }),
    registrationFlags: builder.query<
      RegistrationFlagsQuery,
      RegistrationFlagsQueryVariables
    >({
      query: ({ profile }) => ({
        document: RegistrationFlags,
        variables: {
          profile,
        },
      }),
    }),
  }),
});

export const {
  useAddHighfivesMutation,
  useAddVoucherMutation,
  useDeleteUserMutation,
  useFollowMutation,
  useGetPrivateProfileQuery,
  useGetPublicProfileQuery,
  useSearchUserStartingWithQuery,
  useRegisterMutation,
  useUnfollowMutation,
  useRequestPasswordResetMutation,
  useExportUserDataQuery,
  useAddProfileViewMutation,
  useResetPasswordMutation,
  useResendVerificationEmailMutation,
  useRegistrationFlagsQuery,
  useLazyRegistrationFlagsQuery,
} = SmokelessApiSlice;
