import "./CTASecondary.scss";

type Props = {
  title?: string;
  buttonText: string;
  onClick?: () => void;
};

export const CTASecondary = (props: Props): JSX.Element => {
  const { title, buttonText, onClick } = props;
  return (
    <button
      onClick={() => (onClick ? onClick() : null)}
      className="secondary-cta-container"
    >
      {title && <h2 className="title-secondary">{title}</h2>}
      <span className="button-text-secondary">{buttonText}</span>
    </button>
  );
};
