import React from "react";
import "./LoadingSpinner.scss";
type Props = {
  marginTop?: number;
};
export const LoadingSpinner = (props: Props) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        height: 48 + 8,
      }}
    >
      <div style={{ marginTop: props.marginTop }} className="dot-pulse"></div>
    </div>
  );
};
