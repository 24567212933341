/* eslint-disable @typescript-eslint/no-empty-function */
import { t } from "i18n-js";
import { useNavigate } from "react-router-dom";
import { CTAButton } from "../../components/CTAButton/CTAButton";
import "./EmailVerificationScreen.scss";

export const EmailVerificationScreen = (): JSX.Element => {
  const navigate = useNavigate();

  const email = localStorage.getItem("email");

  return (
    <div className="content-container-width margin-component">
      <div className="email-verification-wrapper p-20 d-column">
        <div className="email-verification-text-wrapper">
          <h2 className="title2">{t("WEBAPP_EMAIL_VERIFICATION_TITLE")}</h2>
          <div className="regular email-verification-text">
            {t("WEBAPP_EMAIL_VERIFICATION_TEXT_1", {
              entered_email: email,
            })}
          </div>
          <div className="regular">{t("WEBAPP_EMAIL_VERIFICATION_TEXT_2")}</div>
        </div>

        <div className="email-verification-cta-wrapper">
          <CTAButton
            title={t("CTA_LOGIN")}
            onClick={() => navigate("/login")}
          />

          <CTAButton
            onClick={() => navigate("/register")}
            title={t("CTA_REGISTER")}
            link
          />
        </div>
      </div>
    </div>
  );
};
