/* eslint-disable @typescript-eslint/no-unsafe-return */
import { t } from "i18n-js";
import { SmokelessPhase } from "../../utils/helpers";
import "./UserBar.scss";
type Props = {
  dayInProgram: number;
  currentDay?: number;
  phase: SmokelessPhase;
  testID?: string;
  relapseDay: number;
};

export const UserBar = (props: Props): JSX.Element => {
  const barArray = [];
  const currentDay = props.currentDay || 0;

  const endDay = props.phase === SmokelessPhase.Analysis ? 7 : 90;
  const relapseDay = props.relapseDay;

  const inRelapse =
    relapseDay < 8 && relapseDay >= 0 && props.dayInProgram >= 90;
  let progress: number;
  if (props.phase === SmokelessPhase.Finished) {
    if (inRelapse) {
      progress = relapseDay / 7;
    } else {
      progress = Math.max((currentDay - 90) / 365, 0.05);
    }
  } else {
    progress = currentDay / endDay;
  }
  let title = t("DAYS_LEFT_XOFX", {
    user_day: currentDay.toString(),
    days_of_program: endDay.toString(),
  }).toUpperCase();
  const bar_width = {
    width: `${progress * 100}%`,
  };
  if (inRelapse) {
    title = t("DAYS_LEFT_SHORT_PROGRAM", {
      relapse_day: relapseDay.toString(),
    }).toUpperCase();
    barArray.push(<div className="bar-3" style={bar_width} />);
  } else {
    switch (props.phase) {
      case SmokelessPhase.Analysis:
        barArray.push(<div className="bar-3" style={bar_width} />);
        break;
      case SmokelessPhase.Finished: {
        let year = 0;
        while (progress > 0) {
          const width = {
            width: `${progress * 100 >= 100 ? "100" : progress * 100}%`,
          };
          barArray.push(<div className="bar-3" style={width} />);
          progress--;
          year++;
        }

        title = year < 4 ? t(`SMOKELESS_YEAR_${year}`).toUpperCase() : "";
        break;
      }
      case SmokelessPhase.Reduction:
        barArray.push(<div className="bar-3" style={bar_width} />);
        break;
      default:
        break;
    }
  }

  return (
    <div className="profile-bar-container flex-center">
      {barArray.map((b) => {
        return (
          <div className="bar-container" key={Math.floor(Math.random() * 11)}>
            <div className="bar-1"></div>
            <div className="bar-2"></div>
            {b}
          </div>
        );
      })}

      <div className="text title3">{title}</div>
    </div>
  );
};
