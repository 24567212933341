import { t } from "i18n-js";
import { useLocation, useNavigate } from "react-router-dom";
import { CTAButton } from "../../components/CTAButton/CTAButton";
import { loadAsset } from "../../utils/loadAsset";
import { logOut } from "../../utils/logOut";
import "./DrawerContainer.scss";

type Props = {
  isOpen: boolean;
  isAuth: boolean;
  closeDrawer: () => void;
};

export const DrawerContainer = (props: Props): JSX.Element => {
  const { isOpen, isAuth, closeDrawer } = props;
  const navigate = useNavigate();
  const path = useLocation();
  const publicProfileScreen = path.pathname.split("/")[1] === "publicprofile";
  const drawerClicked = (path: string) => {
    if (path === "logout") {
      logOut();
      navigate("/");
    } else {
      navigate(path);
    }
    closeDrawer();
  };
  return (
    <div
      className={`drawer-container ${isOpen ? "drawer-container--open" : ""}`}
    >
      {isOpen ? (
        <div className="drawer-content-container">
          <button onClick={() => closeDrawer()} className="close-icon">
            <img
              className="close-icon__img"
              src={loadAsset("close.svg").src}
              alt="close-icon"
            />
          </button>
          <img
            className="drawer-logo"
            src={loadAsset("support_others_drawer_logo.svg").src}
            alt="logo"
          />
          {!isAuth ? (
            <div className="link-container">
              <CTAButton
                onClick={() => drawerClicked("/login")}
                title={t("CTA_LOGIN")}
                link
              />
              <CTAButton
                onClick={() => drawerClicked("/register")}
                title={t("CTA_REGISTER")}
                link
              />
              <CTAButton
                transparent
                title={t("CTA_MORE_ABOUT_SMOKELESS")}
                link
                onClick={() =>
                  (window.location.href = "https://www.smokeless.world")
                }
              />
            </div>
          ) : (
            <div className="link-container">
              <CTAButton
                onClick={() => drawerClicked("/search")}
                title={t("CTA_ADD_SUPPORT_USER_TITLE")}
                link
              />
              <CTAButton
                onClick={() => drawerClicked("logout")}
                title={t("CTA_LOGOUT")}
                link
              />
              <CTAButton
                onClick={() => drawerClicked("/download-data")}
                title={t("CTA_DOWNLOAD_USERDATA_MENU")}
                link
              />
              <CTAButton
                onClick={() => drawerClicked("/delete-account")}
                title={t("CTA_DELETE_ACCOUNT")}
                link
              />
              {publicProfileScreen && (
                <>
                  <CTAButton
                    transparent
                    title={t("CTA_SUPPORT_MANAGEMENT")}
                    link
                    onClick={() => drawerClicked("/overview")}
                  />
                </>
              )}
            </div>
          )}
          <CTAButton
            className="transparent-button drawer-margin"
            transparent
            buttonText={t("CTA_QUIT_SMOKING_TITLE")}
            title={t("CTA_QUIT_SMOKING")}
            onClick={() =>
              (window.location.href = "https://www.smokeless.world")
            }
          />
        </div>
      ) : null}
    </div>
  );
};
