import { t } from "i18n-js";
import { useNavigate, useParams } from "react-router-dom";
import { CTAButton } from "../../components/CTAButton/CTAButton";
import { loadAsset } from "../../utils/loadAsset";
import "./VoucherSentScreen.scss";

export const VoucherSentScreen = (): JSX.Element => {
  const navigate = useNavigate();
  const params = useParams();
  const user = params.uid;
  return (
    <div className="voucher-sent-screen content-container-width">
      <img
        className="voucher-sent-screen__image"
        alt="voucher"
        {...loadAsset("voucher.png")}
      />
      <h1 className="title2">{t("VOUCHER_CREATED_TITLE")}</h1>
      <p className="regular">{t("VOUCHER_CREATED_TEXT")}</p>
      <CTAButton
        title={t("VOUCHER_CREATED_BUTTON")}
        onClick={() => navigate(`/publicprofile/${user || "/"}`)}
      />
    </div>
  );
};
