import { t } from "i18n-js";
import { useNavigate } from "react-router-dom";
import { CTAButton } from "../../components/CTAButton/CTAButton";
import { DeleteUserMutation } from "../../gql/graphql-types";
import { useAuth } from "../../hooks/useAuth";
import { useDeleteUserMutation } from "../../redux/SmokelessApiSlice";
import { logOut } from "../../utils/logOut";
import "./DeleteAccountContainer.scss";

export const DeleteAccountContainer = (): JSX.Element => {
  const navigate = useNavigate();
  const [deleteUser] = useDeleteUserMutation();
  const { uid } = useAuth();
  const deleteUserFunction = async () => {
    if (uid) {
      const results = (await deleteUser({
        profile: { uid: uid },
      })) as { data: DeleteUserMutation; error: unknown };
      console.log(results.data);
      if (
        results &&
        results.data &&
        results.data.deleteUser &&
        results.data.deleteUser.__typename === "UpdateOk"
      ) {
        logOut();
        navigate("/start");
      }
    } else {
      console.log("No uid provided, user not signed in");
    }
  };

  return (
    <div className="delete-account-container content-container-width component-container">
      <h1 className="title2">{t("CONFIRM_DELETE_TITLE")}</h1>
      <p className="text-main regular">{t("CONFIRM_DELETE_TEXT")}</p>
      <div className="buttons-container">
        <CTAButton
          transparent
          className="buttons-container__button"
          title={t("CTA_DELETE")}
          onClick={() => void deleteUserFunction()}
        />
        <div className="buttons-container__separator" />
        <CTAButton
          className="buttons-container__button"
          title={t("CTA_BACK")}
          onClick={() => navigate(-1)}
        />
      </div>
    </div>
  );
};
