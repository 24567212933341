import * as React from "react";
import "./bubbles.js";
import "./getBig.js";
import "./highfive.css";

export const BubbleAnimation = () => {
  return (
    <div itemID="high">
      <script type="text/javascript" src="bubbles.js"></script>
      <script type="text/javascript" src="getBig.js"></script>
      <canvas id="highfives-canvas" />
    </div>
  );
};
