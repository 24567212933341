import { Navigate, Outlet } from "react-router-dom";
import { useRegistrationFlagsQuery } from "../redux/SmokelessApiSlice";

export const EmailVerificationProtectedRoute = () => {
  const { data, isLoading } = useRegistrationFlagsQuery(
    {
      profile: { uid: localStorage.getItem("uid") },
    },
    { skip: Boolean(localStorage.getItem("uid")) ? false : true }
  );

  if (!localStorage.getItem("uid")) {
    return <Outlet />;
  } else if (!data && !isLoading) {
    return <Navigate to="/start" replace />;
  }

  const isEmailVerified =
    data &&
    data.getProfilePrivate.__typename === "ProfilePrivate" &&
    data.getProfilePrivate.registrationFlags.emailVerified;

  return isEmailVerified ? (
    <Outlet />
  ) : (
    <Navigate to="/email-verification" replace={true} />
  );
};
