export enum SmokelessPhase {
  Analysis = "ANALYZATION",
  Reduction = "REDUCTION",
  Finished = "FINISHED",
}

export const getSmokelessPhase = (daysSinceStart: number): SmokelessPhase => {
  const analysisDays = 8;
  const reductionDays = 90;

  if (daysSinceStart < analysisDays) {
    return SmokelessPhase.Analysis;
  } else if (
    daysSinceStart >= analysisDays &&
    daysSinceStart <= reductionDays
  ) {
    return SmokelessPhase.Reduction;
  } else {
    return SmokelessPhase.Finished;
  }
};
