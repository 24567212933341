import { t } from "i18n-js";
import { useNavigate } from "react-router-dom";
import { CTAButton } from "../../components/CTAButton/CTAButton";
import "./NoInternetContainer.scss";

export const NoInternetContainer = (): JSX.Element => {
  const navigate = useNavigate();

  return (
    <div className="no-internet-container content-container-width component-container">
      <h1 className="title2">{t("FAIL_NO_INTERNET_TITLE")}</h1>
      <p className="text-main">{t("FAIL_NO_INTERNET_TEXT")}</p>
      <CTAButton title={t("CTA_TRY_AGAIN")} onClick={() => navigate("/")} />
    </div>
  );
};
