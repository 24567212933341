import { useRef, useState } from "react";
import { Avatar as AvatarType } from "../../gql/graphql-types";
import { loadAsset } from "../../utils/loadAsset";
import "./Avatar.scss";

type Props = {
  height: number;
} & AvatarType;

export const Avatar = ({
  gender,
  hairStyle,
  hairColor,
  face,
  skin,
  clothes,
  special,
  height,
}: Props): JSX.Element => {
  const hairColorArray = ["bright", "dark", "grey", "mid", "red"];
  const genderArray = ["female", "male"];
  const [loading, setLoading] = useState(true);
  const counter = useRef(0);
  const imageLoaded = () => {
    counter.current += 1;

    if (counter.current >= 7) {
      setLoading(false);
    }
  };
  const circleString = `avatar/avatar_layer_1_background.png`;
  const skinString = `avatar/avatar_layer_3_skin_${skin}.png`;
  const hairBackString = `avatar/avatar_layer_2_hair_back_${genderArray[gender]}_${hairColorArray[hairColor]}_${hairStyle}.png`;
  const faceString = `avatar/avatar_layer_5_face_${genderArray[gender]}_${hairColorArray[hairColor]}_${face}.png`;
  const hairFrontString = `avatar/avatar_layer_6_hair_front_${genderArray[gender]}_${hairColorArray[hairColor]}_${hairStyle}.png`;
  const clothString = `avatar/avatar_layer_4_cloth_${clothes}.png`;
  const specialString = `avatar/avatar_layer_7_special_${special}.png`;

  const assets = {
    circles: loadAsset(circleString),
    skin: loadAsset(skinString),
    hairBack: loadAsset(hairBackString),
    face: loadAsset(faceString),
    hairFront: loadAsset(hairFrontString),
    cloth: loadAsset(clothString),
    special: loadAsset(specialString),
    height,
  };
  return (
    <div
      className="avatar-container"
      style={{
        width: height,
        height: height,
        opacity: loading ? 0 : 1,
        transition: "opacity 1s ease-out",
      }}
    >
      <img
        style={{ width: height }}
        {...loadAsset("webapp_background_stage.png")}
        alt="profile-avatar-background"
        className="avatar-image"
        onLoad={imageLoaded}
      />
      <img
        style={{ width: height }}
        className="avatar-image"
        {...assets.circles}
        alt="circles"
        onLoad={imageLoaded}
      />
      <img
        style={{ width: height }}
        className="avatar-image"
        {...assets.hairBack}
        alt="hairBack"
        onLoad={imageLoaded}
      />
      <img
        style={{ width: height }}
        className="avatar-image"
        {...assets.skin}
        alt="skin"
        onLoad={imageLoaded}
      />
      <img
        style={{ width: height }}
        className="avatar-image"
        {...assets.cloth}
        alt="cloth"
        onLoad={imageLoaded}
      />
      <img
        style={{ width: height }}
        className="avatar-image"
        {...assets.face}
        alt="face"
        onLoad={imageLoaded}
      />
      <img
        style={{ width: height }}
        className="avatar-image"
        {...assets.hairFront}
        alt="hairFront"
        onLoad={imageLoaded}
      />
      <img
        style={{ width: height }}
        className="avatar-image"
        {...assets.special}
        alt="special"
        onLoad={imageLoaded}
      />
    </div>
  );
};
