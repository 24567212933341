import { gql } from "graphql-request";

export const AddHighfives = gql`
  mutation AddHighfives(
    $profile: ProfileInput!
    $updatedData: AddHighfivesInput!
  ) {
    addHighfives(profile: $profile, updatedData: $updatedData) {
      ... on InvalidInput {
        __typename
        errors {
          constraints {
            message
            name
          }
          property
          value
        }
      }
      ... on ProfileNotFound {
        __typename
        message
      }
      ... on UpdateOk {
        __typename
        ok
      }
    }
  }
`;
