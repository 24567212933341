import { t } from "i18n-js";
import { loadAsset } from "../../utils/loadAsset";
import "./BadgeCategoryCard.scss";

type Props = {
  category: string;
  state: number;
};

const MAX_STATE = 3;
const STATE_1_STAR = "500";
const STATE_2_STAR = "1.000";
const STATE_3_STAR = "2.000";

export const BadgeCategoryCard = (props: Props): JSX.Element => {
  const myString = "Hello";
  const arrayOfCharacters = myString.split("");
  const newArray = arrayOfCharacters.map((char) => char + char);
  const newString = newArray.toString();

  const renderStage = (stageToRender: number) => {
    let badge;
    if (props.state < stageToRender) {
      return (
        <img
          className="badge-icon"
          alt="lock"
          {...loadAsset("lock_1.png")}
          width="160px"
          height="142px"
        />
      );
    } else {
      try {
        badge = loadAsset(
          `badge/badge_${props.category.toLowerCase()}_${stageToRender}.png`
        );
      } catch (e) {
        badge = loadAsset("lock_1.png");
      }
      return (
        <img
          className="badge-icon"
          alt="badge"
          {...badge}
          width="160px"
          height="142px"
        />
      );
    }
  };
  const renderStars = (level: number) => {
    const stars = loadAsset("star_filled_small_turquese_centered.svg");

    return (
      <div className="stars-container title3 ">
        <div
          className={`stars-number ${
            props.state < level ? "stars-number__transparent" : ""
          }`}
        >
          {level === 1
            ? STATE_1_STAR
            : level === 2
            ? STATE_2_STAR
            : STATE_3_STAR}
        </div>
        <div
          className={`stars-icon ${
            props.state < level ? "stars-icon__transparent" : ""
          }`}
        >
          <img {...stars} alt="stars" className="stars-icon-img" />
        </div>
      </div>
    );
  };

  return (
    <div className="badge-category-card">
      <div className="badge-category-card-title">
        <h1 className="badge-category-card-title__text title3">
          {t(`BADGE_${props.category}_TITLE`).toUpperCase()}
        </h1>
        <span className="badge-category-card-title__counter small1">
          {t("BADGES_CATEGORY_ACHIEVED", { number: props.state })}
        </span>
      </div>
      <div className="badge-card-content">
        <div className="badge-card-stage">
          {renderStage(1)}
          {renderStars(1)}
        </div>
        <div className="badge-card-stage">
          {renderStage(2)}
          {renderStars(2)}
        </div>
        <div className="badge-card-stage">
          {renderStage(3)}
          {renderStars(3)}
        </div>
      </div>
    </div>
  );
};
