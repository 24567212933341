import { numberWithDot } from "../../utils/format";
import "./ProfileAchievementCounter.scss";

type Props = {
  number: number | string;
  title: string;
  icon: { src: string };
};

export const ProfileAchievementCounter = ({
  number,
  title,
  icon,
}: Props): JSX.Element => {
  return (
    <div className="profile-achievement-counter">
      <img
        alt="achievementicon"
        className="profile-achievement-icon"
        {...icon}
      />
      <div className="counter-number title1_condensed">
        {numberWithDot(`${number}`)}
      </div>
      <p className="counter-number-title small2">{title}</p>
    </div>
  );
};
