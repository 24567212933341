import { gql } from "graphql-request";

export const Unfollow = gql`
  mutation Unfollow($profile: ProfileInput!, $updatedData: FollowInput!) {
    unfollow(profile: $profile, updatedData: $updatedData) {
      ... on ProfileNotFound {
        __typename
        message
      }
      ... on UpdateOk {
        __typename
        ok
      }
    }
  }
`;
