import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import {
  FailureMessage,
  FailureType,
} from "../../components/FailureMessage/FailureMessage";
import { EmailVerifiedAppScreen } from "./EmailVerifiedAppScreen";
import "./EmailVerifiedScreen.scss";
import { EmailVerifiedWebScreen } from "./EmailVerifiedWebScreen";
type Props = {
  setDisplayBurgerMenu: Dispatch<SetStateAction<boolean>>;
};

export const EmailVerifiedScreen = (props: Props) => {
  const { setDisplayBurgerMenu } = props;
  const [apiResponse, setApiResponse] = useState<boolean>();
  const [searchParams] = useSearchParams();
  const platform = searchParams.get("os");

  useEffect(() => {
    const fetchApiResponse = async () => {
      console.log(window.app.config.API);
      const res = await fetch(
        `${window.app.config.API}verify_email?token=${searchParams.get(
          "token"
        )}`
      );
      console.log(res);
      setApiResponse(res.ok);
    };
    setDisplayBurgerMenu(false);
    fetchApiResponse().catch(console.error);
    return () => {
      setDisplayBurgerMenu(true);
    };
  }, [searchParams, setDisplayBurgerMenu]);
  return (
    <div className="email-verified-container content-container-width component-container">
      {apiResponse ? (
        platform === "web" ? (
          <EmailVerifiedWebScreen />
        ) : (
          <EmailVerifiedAppScreen />
        )
      ) : (
        <FailureMessage type={FailureType.InvalidToken} />
      )}
    </div>
  );
};
