import { t } from "i18n-js";
import { FailureMessage, FailureType } from "../FailureMessage/FailureMessage";
import "./Input.scss";

type Props = {
  type: string;
  placeholder: string;
  inputText: string;
  onChange: (text: string) => void;
  error?: string;
  limit?: number;
};

export const Input = (props: Props): JSX.Element => {
  const { placeholder, type, onChange, inputText, error, limit } = props;
  return (
    <div className="input-container">
      <input
        className="regular"
        autoCapitalize="none"
        type={type}
        placeholder={placeholder}
        maxLength={limit ? limit : undefined}
        value={inputText}
        onChange={(e) => onChange(e.target.value)}
      />
      {error && error.length > 0 && (
        <FailureMessage type={FailureType.InvalidInput} message={error} />
      )}
      {limit && limit - inputText.length <= 20 && (
        <div className="input-container__input-max-length small2">
          {t("MISSION_GOALS_CREATE_FORM_ALERT", {
            amount: `${limit - inputText.length}`,
          })}
        </div>
      )}
    </div>
  );
};
