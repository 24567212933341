import { useEffect, useState } from "react";
import { graphqlClient } from "../gql/graphql-client";

export const useAuth = (): { isAuth: boolean; uid: string | null } => {
  const [isAuth, setIsAuth] = useState(false);
  const [uid, setUid] = useState<string | null>("");
  const localStorageToken = localStorage.getItem("accessToken");
  const uidStorage = localStorage.getItem("uid");
  if (localStorageToken) {
    const authorizationHeader = `Bearer ${localStorageToken}`;
    graphqlClient.setHeader("authorization", authorizationHeader);
  }
  useEffect(() => {
    if (localStorage.getItem("accessToken") && !isAuth) {
      setIsAuth(true);
    } else if (!localStorage.getItem("accessToken") && isAuth) {
      setIsAuth(false);
    }
    if (localStorage.getItem("uid") && !uid) {
      setUid(uidStorage);
    }
  }, [localStorageToken, isAuth, uid, uidStorage]);
  return { isAuth, uid };
};
