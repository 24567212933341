import { t } from "i18n-js";
import * as React from "react";
import { loadAsset } from "../../utils/loadAsset";
import "./highfive.css";

type Props = {
  highfiveCurrent: number;
  uid: string;
  username?: string;
  addHighFives: (highfives: number) => Promise<void>;
};

type State = {
  highfive: number;
  firestoreHighfives: number;
  timer: NodeJS.Timeout | undefined;
};

export class HighFive extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      highfive: 0,
      firestoreHighfives: this.props.highfiveCurrent,
      timer: undefined,
    };
    this.highfive = this.highfive.bind(this);
  }

  highfive = () => {
    this.setState({ highfive: this.state.highfive + 1 });
    if (this.state.timer) {
      clearTimeout(this.state.timer);
    }
    const newTimer = setTimeout(async () => {
      const newHighfives = this.state.highfive;
      this.setState({
        highfive: 0,
        firestoreHighfives: this.state.firestoreHighfives + newHighfives,
      });

      console.log("send highfives");

      await this.props.addHighFives(newHighfives);
    }, 1500);
    this.setState({ timer: newTimer });
  };

  componentDidUpdate() {
    if (this.state.firestoreHighfives < this.props.highfiveCurrent) {
      this.setState({ firestoreHighfives: this.props.highfiveCurrent });
    }
  }

  render() {
    const isMe = this.props.uid === localStorage.getItem("uid");
    const isMeStyle = { cursor: "not-allowed", opacity: 0.3 };

    return (
      <div className="Highfive_box">
        <div className="Highfive_box_inner">
          <div
            className="HIGHFIVE_GENERAL gradient_blue"
            style={isMe ? isMeStyle : {}}
            onClick={isMe ? undefined : this.highfive}
          >
            <div className="HIGHFIVE_TEXT_CONTAINER">
              <div className="HIGHFIVE_TEXT_1">
                <div className="title4" style={{ letterSpacing: 1.5 }}>
                  {t("HIGHFIVE_CARD_TITLE_1").toUpperCase()}
                </div>
              </div>
              <div className="HIGHFIVE_TEXT_2">
                <div className="HIGHFIVE_CUSTOM_TEXT_STYLE">
                  {t("HIGHFIVE_CARD_TITLE_2")}
                </div>
              </div>
              <div className="HIGHFIVE_TEXT_3">
                <div className="title4">
                  {t("HIGHFIVE_CARD_TITLE_3", {
                    username: this.props.username || "",
                  }).toUpperCase()}
                </div>
              </div>
              <div className="HANDS">
                <div className="HAND_BACK transform">
                  <img alt="hand" {...loadAsset("hand_back.svg")} />
                </div>
                <div className="HAND_FRONT transform2">
                  <img alt="hand" {...loadAsset("hand_front.svg")} />
                </div>
              </div>
              <div className="HIGHFIVE_CLICK_HERE">
                <img
                  alt="click here"
                  {...loadAsset("high_five_click_here.svg")}
                />
              </div>
              <div className="HIGHFIVE_NUM">
                <div className="TITLE_1_HIGHFIVE">
                  {this.state.firestoreHighfives > 9999
                    ? "9999+"
                    : this.state.highfive + this.state.firestoreHighfives}
                </div>
              </div>
              <div className="HIGHFIVE_TEXT_4">
                <div className="title3">{t("HIGHFIVES")}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
