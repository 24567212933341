import { BadgeCategoryList } from "../../../components/BadgeCategoryList/BadgeCategoryList";
import "./ProfileBadgesSection.scss";
interface Cards {
  [key: string]: { state: number; category: string };
}

type Props = {
  badges?: Array<{ __typename?: "Badge"; category: string; state: number }>;
};

const badgeCategories = [
  "LEVEL_1",
  "LEVEL_2",
  "LEVEL_3",
  "LEVEL_4",
  "LEVEL_5",
  "LEVEL_6",
  "MONEY",
  "HEALTH",
  "CHALLENGE",
  "HIGHFIVE",
  "SUPPORT",
  "SUPPORTING",
  "SHARE",
];

export const ProfileBadgesSection = (props: Props): JSX.Element => {
  const { badges } = props;

  const renderBadgeCategories = () => {
    if (badges) {
      const badgeCards: Cards = {};
      badgeCategories.forEach((category) => {
        let state = 0;
        badges.forEach((badge) => {
          if (category === badge.category && badge.state > state) {
            state = badge.state;
          }
        });
        badgeCards[category] = { state: state, category: category };
      });
      return <BadgeCategoryList cards={badgeCards} />;
    } else {
    }
  };

  return (
    <div className="profile-badges-container">{renderBadgeCategories()}</div>
  );
};
