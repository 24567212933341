import { t } from "i18n-js";
import { debounce } from "lodash";
import { useMemo, useState } from "react";
import { UserCard } from "../../components/UserCard/UserCard";
import { useAuth } from "../../hooks/useAuth";
import { useSearchUserStartingWithQuery } from "../../redux/SmokelessApiSlice";
import "./SearchContainer.scss";

const MAX = 20;

export const SearchContainer = (): JSX.Element => {
  const [searchInput, setSearchInput] = useState("");
  const { uid, isAuth } = useAuth();

  const { data, error } = useSearchUserStartingWithQuery(
    {
      search: { username: searchInput, isPublic: true },
    },
    { refetchOnMountOrArgChange: true }
  );
  if (error) {
    console.error(error);
  }

  const debouncedSearch = useMemo(() => {
    return debounce((e: React.ChangeEvent<HTMLInputElement>) => {
      setSearchInput(e.target.value);
    }, 300);
  }, []);

  let userCards = [];
  if (data?.searchUserStartingWith?.results && searchInput !== "") {
    userCards = data.searchUserStartingWith.results
      .slice(0, 20)
      .filter((user) => user.uid !== uid)
      .map((user, idx) => {
        return (
          <UserCard
            key={idx}
            username={user.username}
            uid={user.uid}
            gender={user.avatar?.gender}
            startDate={user.start_date}
          />
        );
      });
  }

  return (
    <div className="search-container content-container-width">
      <div className="input-container">
        <input
          autoFocus
          className="input"
          placeholder={t("USER_SEARCH_FORM")}
          onChange={debouncedSearch}
        />
      </div>
      <div className="results-container">
        {searchInput.length > 2 && userCards}
        {
          <div className="user-search-info">
            <p className="user-search-info__text small1">
              {searchInput.length > 2 &&
                userCards?.length > 19 &&
                t("USER_SEARCH_ENTRIES")}
              {searchInput.length > 2 &&
                userCards?.length === 0 &&
                t("SEARCH_USERNAME_EMPTY")}
              {searchInput.length <= 2 &&
                t("SEARCH_USERNAME_SUPPORT_OTHERS_TEXT")}
            </p>
          </div>
        }
      </div>
    </div>
  );
};
