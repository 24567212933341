import { gql } from "graphql-request";

export const AddProfileView = gql`
  mutation AddProfileView($profile: ProfileInput!) {
    addProfileView(profile: $profile) {
      ... on InvalidInput {
        __typename
        errors {
          property
          constraints {
            name
            message
          }
          value
        }
      }
      ... on ProfileNotFound {
        __typename
        message
      }
      ... on UpdateOk {
        __typename
        ok
      }
    }
  }
`;
