import { GraphQLClient } from "graphql-request";
import * as Dom from "graphql-request/dist/types.dom";
import gql from "graphql-tag";
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: any;
};

export type AccessPayload = {
  __typename?: "AccessPayload";
  accessToken: Scalars["String"];
  uid: Scalars["String"];
};

export type AddAfterProgramInput = {
  afterProgramDays: Array<AfterProgramDay>;
};

export type AddEmergencyKitActionInput = {
  category: Scalars["String"];
  title: Scalars["String"];
};

export type AddEmergencyKitActionUnion =
  | AlreadyDoneToday
  | ProfileNotFound
  | UpdateOk;

export type AddFinancialGoalInput = {
  financialGoalId: Scalars["String"];
  money: Scalars["Float"];
  reward: Scalars["String"];
  /** Date in ISO format (YYYY-MM-DDTHH:mm:ss.sssZ) */
  startDate: Scalars["String"];
};

export type AddFinancialGoalUnion =
  | FinancialGoal
  | InvalidInput
  | NotUpdated
  | ProfileNotFound
  | UpdateOk;

export type AddHighfivesInput = {
  addHighfives: Scalars["Int"];
};

export type AddMissionInput = {
  messageId: Scalars["String"];
  stars: Scalars["Int"];
  type: Scalars["String"];
};

export type AddNicotineUsageInput = {
  /** Date in ISO format (YYYY-MM-DDTHH:mm:ss.sssZ) */
  date: Scalars["String"];
  puffsWithNicotine: Scalars["Int"];
  puffsWithoutNicotine: Scalars["Int"];
};

export type AddNotificationTokenInput = {
  token: Scalars["String"];
};

export type AddParticipantUnion =
  | ParticipantNotAdded
  | ProfileNotFound
  | UpdateOk;

export type AddQuizResultInput = {
  /** Date in ISO format (YYYY-MM-DDTHH:mm:ss.sssZ) */
  date: Scalars["String"];
  name: Scalars["String"];
  questionCount: Scalars["Int"];
};

export type AddSharingInput = {
  platform: Scalars["String"];
};

export type AddSharingUnion = AlreadySharedToday | ProfileNotFound | UpdateOk;

export type AddSmokedCigaretteInput = {
  count: Scalars["Int"];
};

export type AddVoucherInput = {
  /** Accepts: days90, years1, years5 */
  redeemType: RedeemType;
  text: Scalars["String"];
  title: Scalars["String"];
  uidRecipient: Scalars["String"];
};

export type AfterProgram = {
  __typename?: "AfterProgram";
  cravings: Scalars["Boolean"];
  /** Date in ISO format (YYYY-MM-DDTHH:mm:ss.sssZ) */
  date: Scalars["String"];
  day: Scalars["Int"];
  smoked: Scalars["Boolean"];
};

export type AfterProgramData = {
  __typename?: "AfterProgramData";
  afterProgramSmoking: Array<AfterProgram>;
  /** Date in ISO format (YYYY-MM-DDTHH:mm:ss.sssZ) */
  smokingHintMessageLastSend?: Maybe<Scalars["String"]>;
};

export type AfterProgramDay = {
  cravings: Scalars["Boolean"];
  /** Date in ISO format (YYYY-MM-DDTHH:mm:ss.sssZ) */
  date: Scalars["String"];
  day: Scalars["Float"];
  relapse?: Maybe<Scalars["Boolean"]>;
  smoked: Scalars["Boolean"];
};

export type AllUsers = {
  __typename?: "AllUsers";
  users: Array<User>;
};

export type AlreadyDoneToday = {
  __typename?: "AlreadyDoneToday";
  message: Scalars["String"];
};

export type AlreadyExists = {
  __typename?: "AlreadyExists";
  alreadyExists: Scalars["Boolean"];
};

export type AlreadySharedToday = {
  __typename?: "AlreadySharedToday";
  alreadyShared: Scalars["Boolean"];
  message: Scalars["String"];
};

export type Avatar = {
  __typename?: "Avatar";
  clothes: Scalars["Int"];
  face: Scalars["Int"];
  gender: Scalars["Int"];
  hairColor: Scalars["Int"];
  hairStyle: Scalars["Int"];
  skin: Scalars["Int"];
  special: Scalars["Int"];
};

export type AvatarInput = {
  clothes: Scalars["Int"];
  face: Scalars["Int"];
  gender: Scalars["Int"];
  hairColor: Scalars["Int"];
  hairStyle: Scalars["Int"];
  skin: Scalars["Int"];
  special: Scalars["Int"];
};

export type Badge = {
  __typename?: "Badge";
  category: Scalars["String"];
  /** Date in ISO format (YYYY-MM-DDTHH:mm:ss.sssZ) */
  date: Scalars["String"];
  dip?: Maybe<Scalars["Int"]>;
  previousDay?: Maybe<Scalars["Int"]>;
  slag: Scalars["String"];
  state: Scalars["Int"];
};

export type Cta = {
  __typename?: "CTA";
  floating?: Maybe<CtaAction>;
  header?: Maybe<CtaAction>;
  primary?: Maybe<CtaAction>;
  secondary?: Maybe<CtaAction>;
  third?: Maybe<CtaAction>;
};

export type CtaAction = {
  __typename?: "CTAAction";
  action?: Maybe<Scalars["String"]>;
  backgroundColor?: Maybe<Scalars["String"]>;
  disabled?: Maybe<Scalars["Boolean"]>;
  label?: Maybe<Scalars["String"]>;
  subtitle?: Maybe<Scalars["String"]>;
};

export type CalculateDailyGoalUnion =
  | CalculatedDailyGoals
  | InvalidInput
  | ProfileNotFound;

export type CalculateDailyGoalsInput = {
  puffs: Array<DailyGoalPuffs>;
};

export type CalculateUserStarsUnion = CalculatedUserStars | ProfileNotFound;

export type CalculatedDailyGoal = {
  __typename?: "CalculatedDailyGoal";
  day: Scalars["Int"];
  goal: Scalars["Int"];
};

export type CalculatedDailyGoals = {
  __typename?: "CalculatedDailyGoals";
  days: Array<CalculatedDailyGoal>;
};

export type CalculatedUserStars = {
  __typename?: "CalculatedUserStars";
  /** @deprecated use starsData instead */
  stars: Array<Stars>;
  starsData: Array<StarsData>;
  starsTotal: Scalars["Int"];
};

export type Challenge = {
  __typename?: "Challenge";
  cancel_date?: Maybe<Scalars["String"]>;
  challengeId: Scalars["String"];
  created: Scalars["String"];
  created_by: Scalars["String"];
  duration: Scalars["Int"];
  end_date?: Maybe<Scalars["String"]>;
  messages: Array<ChallengeChatMessage>;
  name: Scalars["String"];
  participants: Array<Participant>;
  started?: Maybe<Scalars["String"]>;
  /** Accepts: vs, team */
  type: ChallengeType;
};

export type ChallengeChatMessage = {
  __typename?: "ChallengeChatMessage";
  message: Scalars["String"];
  messageId: Scalars["String"];
  sendDate: Scalars["String"];
  sender: Sender;
};

export enum ChallengeGoalAchieved {
  Achieved = "ACHIEVED",
  NoData = "NO_DATA",
  Unachieved = "UNACHIEVED",
}

export type ChallengeInput = {
  challengeId: Scalars["String"];
};

export type ChallengeInsert = {
  duration: Scalars["Int"];
  name: Scalars["String"];
  type: Scalars["String"];
};

export type ChallengeMessageInsert = {
  challengeId: Scalars["String"];
  message: Scalars["String"];
};

export type ChallengeNotFound = {
  __typename?: "ChallengeNotFound";
  message: Scalars["String"];
};

export type ChallengeParticipantInput = {
  challengeId: Scalars["String"];
  participantUid: Scalars["String"];
};

export enum ChallengeParticipantStatus {
  Accepted = "ACCEPTED",
  Invited = "INVITED",
  Rejected = "REJECTED",
}

export enum ChallengeType {
  Team = "TEAM",
  Vs = "VS",
}

export type Challenges = {
  __typename?: "Challenges";
  challenges: Array<Challenge>;
};

export type ChecklistItem = {
  __typename?: "ChecklistItem";
  /** Date in ISO format (YYYY-MM-DDTHH:mm:ss.sssZ) */
  achieved?: Maybe<Scalars["String"]>;
  title: Scalars["String"];
};

export type ChecklistItemInput = {
  isAchieved: Scalars["Boolean"];
  title: Scalars["String"];
};

export type ChecklistItemStub = {
  __typename?: "ChecklistItemStub";
  title: Scalars["String"];
};

export type CollectStarsInput = {
  starsIds: Array<Scalars["String"]>;
};

export type CreateChallengeUnion = Challenge | ProfileNotFound;

export type CreateTestUserInput = {
  email: Scalars["String"];
  profilePrivateOverrideJson?: Maybe<Scalars["String"]>;
  profilePublicOverrideJson?: Maybe<Scalars["String"]>;
  uid: Scalars["String"];
  username: Scalars["String"];
};

export type DailyGoalPuffs = {
  day: Scalars["Int"];
  /** Array with 24 numbers of puffs for each hour */
  hours: Array<Scalars["Int"]>;
};

export type DailyReviewLastShownInput = {
  /** Date in ISO format (YYYY-MM-DDTHH:mm:ss.sssZ) */
  date?: Maybe<Scalars["String"]>;
};

export type DataConsentInput = {
  ads?: Maybe<Scalars["Boolean"]>;
  agb?: Maybe<Scalars["Boolean"]>;
  improvements?: Maybe<Scalars["Boolean"]>;
  isPublic?: Maybe<Scalars["Boolean"]>;
};

export type EmailAlreadyUsed = {
  __typename?: "EmailAlreadyUsed";
  message: Scalars["String"];
};

export type EmailChangeStatus = {
  __typename?: "EmailChangeStatus";
  isPending: Scalars["Boolean"];
  newEmail?: Maybe<Scalars["String"]>;
};

export type EmergencyKitAction = {
  __typename?: "EmergencyKitAction";
  /** Date in ISO format (YYYY-MM-DDTHH:mm:ss.sssZ) */
  achieved: Scalars["String"];
  category: Scalars["String"];
  title: Scalars["String"];
};

export type EmergencyKitActionInput = {
  title: Scalars["String"];
};

export type FinalizeRegistrationUnion =
  | InvalidFlagState
  | ProfileFull
  | ProfileNotFound;

export type FinancialGoal = {
  __typename?: "FinancialGoal";
  /** Date in ISO format (YYYY-MM-DDTHH:mm:ss.sssZ) */
  creationDate: Scalars["String"];
  financialGoalId: Scalars["String"];
  /** Date in ISO format (YYYY-MM-DDTHH:mm:ss.sssZ) */
  finishDate: Scalars["String"];
  money: Scalars["Int"];
  reward: Scalars["String"];
  /** Date in ISO format (YYYY-MM-DDTHH:mm:ss.sssZ) */
  startDate: Scalars["String"];
};

export type FollowData = {
  __typename?: "FollowData";
  followingMe: Array<Follower>;
  followingOthers: Array<Follower>;
};

export type FollowInput = {
  followedUid: Scalars["String"];
};

export type Follower = {
  __typename?: "Follower";
  avatar?: Maybe<Avatar>;
  /** Date in ISO format (YYYY-MM-DDTHH:mm:ss.sssZ) */
  followDate: Scalars["String"];
  isSupporter?: Maybe<Scalars["Boolean"]>;
  name: Scalars["String"];
  start_date: Scalars["String"];
  uid: Scalars["String"];
};

export type GetChallengesUnion = Challenges | ProfileNotFound;

export type GetMessagesUnion = MessageData | ProfileNotFound;

export type GetMoneySavedInput = {
  money: Scalars["Float"];
  /** Date in ISO format (YYYY-MM-DDTHH:mm:ss.sssZ) */
  startDate: Scalars["String"];
};

export type GetMoneySavedOutput = {
  __typename?: "GetMoneySavedOutput";
  /** Date in ISO format (YYYY-MM-DDTHH:mm:ss.sssZ) */
  finishDate: Scalars["String"];
};

export type GetOneChallengeUnion = Challenge | ChallengeNotFound;

export type GetProfilePrivateUnion =
  | ProfileNoPermission
  | ProfileNotFound
  | ProfilePrivate;

export type GetProfilePublicUnion =
  | ProfileNotFound
  | ProfileNotPublic
  | ProfilePublic;

export type GetUserDataExportUnion = ProfileNotFound | UserDataExport;

export type Goal = {
  __typename?: "Goal";
  /** Date in ISO format (YYYY-MM-DDTHH:mm:ss.sssZ) */
  achieved?: Maybe<Scalars["String"]>;
  category: Scalars["String"];
  goalId: Scalars["String"];
  title: Scalars["String"];
};

export type GoalInput = {
  /** Date in ISO format (YYYY-MM-DDTHH:mm:ss.sssZ) */
  achieved?: Maybe<Scalars["String"]>;
  category: Scalars["String"];
  goalId?: Maybe<Scalars["String"]>;
  title: Scalars["String"];
};

export type GrantedPermissions = {
  __typename?: "GrantedPermissions";
  ads?: Maybe<Scalars["Boolean"]>;
  agb?: Maybe<Scalars["Boolean"]>;
  improvements?: Maybe<Scalars["Boolean"]>;
};

export type Hello = {
  __typename?: "Hello";
  text: Scalars["String"];
};

export type HighfiveDay = {
  __typename?: "HighfiveDay";
  count: Scalars["Int"];
  day: Scalars["Int"];
};

export type Highfives = {
  __typename?: "Highfives";
  highfivesPerDay: Array<HighfiveDay>;
  number: Scalars["Int"];
  uid?: Maybe<Scalars["String"]>;
};

export type ImportantWarningFlagInput = {
  hasRead: Scalars["Boolean"];
};

export type InputError = {
  __typename?: "InputError";
  constraints: Array<ValidationConstraint>;
  property: Scalars["String"];
  value: Scalars["String"];
};

export type InvalidCredentials = {
  __typename?: "InvalidCredentials";
  message: Scalars["String"];
};

export type InvalidFlagState = {
  __typename?: "InvalidFlagState";
  invalidFlag: Scalars["String"];
};

export type InvalidInput = {
  __typename?: "InvalidInput";
  errors: Array<InputError>;
};

export type IsPaidInput = {
  isPaid: Scalars["Boolean"];
};

export type JsonStringInput = {
  json: Scalars["String"];
};

export enum Language {
  De = "de",
  En = "en",
}

export type LanguageInput = {
  countryCode: Scalars["String"];
  /** Two-letter language code capitalized (accepts: de, en) */
  language: Language;
};

export type LastQuitDateResetInput = {
  /** Date in ISO format (YYYY-MM-DDTHH:mm:ss.sssZ) */
  date?: Maybe<Scalars["String"]>;
};

export type Level = {
  __typename?: "Level";
  /** Date in ISO format (YYYY-MM-DDTHH:mm:ss.sssZ) */
  levelStarted: Scalars["String"];
  name: Scalars["String"];
  state: Scalars["Int"];
};

/** Level flags are used to store if the level introduction in the daily review was already seen */
export type LevelFlags = {
  __typename?: "LevelFlags";
  Level_2: Scalars["Boolean"];
  Level_3: Scalars["Boolean"];
  Level_4: Scalars["Boolean"];
  Level_5: Scalars["Boolean"];
  Level_6: Scalars["Boolean"];
  Level_7: Scalars["Boolean"];
};

export type LevelFlagsInput = {
  Level_2?: Maybe<Scalars["Boolean"]>;
  Level_3?: Maybe<Scalars["Boolean"]>;
  Level_4?: Maybe<Scalars["Boolean"]>;
  Level_5?: Maybe<Scalars["Boolean"]>;
  Level_6?: Maybe<Scalars["Boolean"]>;
  Level_7?: Maybe<Scalars["Boolean"]>;
};

export type LicenseInput = {
  licenseKey: Scalars["String"];
};

export type LicenseNoUses = {
  __typename?: "LicenseNoUses";
  message: Scalars["String"];
};

export type LicenseNotFound = {
  __typename?: "LicenseNotFound";
  message: Scalars["String"];
};

export type Message = {
  __typename?: "Message";
  animation?: Maybe<Scalars["String"]>;
  audio?: Maybe<Scalars["String"]>;
  cardstyle?: Maybe<MessageType>;
  cta?: Maybe<Cta>;
  data?: Maybe<Scalars["String"]>;
  date?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  done: Scalars["Boolean"];
  grant_stars?: Maybe<Scalars["String"]>;
  header?: Maybe<Scalars["String"]>;
  id: Scalars["String"];
  image?: Maybe<Scalars["String"]>;
  level: Scalars["Int"];
  longtext?: Maybe<Scalars["String"]>;
  progress?: Maybe<Scalars["String"]>;
  read?: Maybe<Scalars["String"]>;
  read_action?: Maybe<Scalars["String"]>;
  sections: Array<MessageSection>;
  speechbubbles: Array<Scalars["String"]>;
  stars?: Maybe<Scalars["String"]>;
  style?: Maybe<Scalars["String"]>;
  subtitle?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
  transcript?: Maybe<Scalars["String"]>;
  type?: Maybe<MessageType>;
};

export type MessageData = {
  __typename?: "MessageData";
  all: Scalars["Int"];
  count: Scalars["Int"];
  messages: Array<Message>;
  uid: Scalars["String"];
};

export type MessageSection = {
  __typename?: "MessageSection";
  content?: Maybe<Scalars["String"]>;
  type: MessageSectionType;
};

export enum MessageSectionType {
  CardActivity = "CARD_ACTIVITY",
  CardLearning = "CARD_LEARNING",
  CardSummary = "CARD_SUMMARY",
  CardTip = "CARD_TIP",
  Outline = "OUTLINE",
  Text = "TEXT",
  Title = "TITLE",
}

export enum MessageType {
  Badge = "BADGE",
  Challenge_1 = "CHALLENGE_1",
  Challenge_2 = "CHALLENGE_2",
  Challenge_3 = "CHALLENGE_3",
  Info = "INFO",
  Level = "LEVEL",
  Mission = "MISSION",
  Shop = "SHOP",
  Support = "SUPPORT",
  SupporterMode = "SUPPORTER_MODE",
}

export type Mutation = {
  __typename?: "Mutation";
  acceptChallengeInvite: UpdateDataUnion;
  addAfterProgramDays: UpdateDataUnion;
  addChallengeMessage: UpdateUnion;
  addChallengeParticipant: AddParticipantUnion;
  addEmergencyKitAction: AddEmergencyKitActionUnion;
  addFinancialGoal: AddFinancialGoalUnion;
  addHighfives: UpdateDataUnion;
  addMission: UpdateUniqueDataUnion;
  addNicotineUsage: UpdateDataUnion;
  addNotificationToken: UpdateDataUnion;
  addProfileView: UpdateDataUnion;
  addQuizResult: UpdateUniqueDataUnion;
  addSharing: AddSharingUnion;
  addSmokedCigarette: UpdateDataUnion;
  addVoucher: UpdateDataUnion;
  calculateDailyGoals: CalculateDailyGoalUnion;
  /** Calculate user stars after puffs synced */
  calculateUserStars: CalculateUserStarsUnion;
  cancelChallenge: UpdateUnion;
  collectStars: UpdateUnion;
  createChallenge: CreateChallengeUnion;
  createTestUser: UpdateUnion;
  deleteUser: UpdateDataUnion;
  finalizeRegistration: FinalizeRegistrationUnion;
  follow: UpdateDataUnion;
  forceUpdateChallenge: UpdateUnion;
  forceUpdateProfilePrivate: UpdateUnion;
  forceUpdateProfilePublic: UpdateUnion;
  queueUserUpdate: UpdateDataUnion;
  register: RegistrationUnion;
  rejectChallengeInvite: UpdateDataUnion;
  removeChallenge: UpdateUnion;
  removeChallengeParticipant: UpdateDataUnion;
  removeChecklistItem: UpdateDataUnion;
  removeEmergencyKitAction: UpdateDataUnion;
  removeFollower: UpdateDataUnion;
  removeVoucher: UpdateDataUnion;
  reportChallenge: UpdateDataUnion;
  reportChallengeMessage: UpdateDataUnion;
  reportUser: UpdateDataUnion;
  reportVoucher: UpdateDataUnion;
  requestEmailChange: RequestEmailChangeUnion;
  requestPasswordReset: UpdateUnion;
  resendVerificationEmail: UpdateUnion;
  resetPassword: UpdateUnion;
  resetUser: UpdateDataUnion;
  sendMessage: UpdateUnion;
  setChallengeMessagesRead: UpdateUnion;
  setMessageRead: UpdateDataUnion;
  setNewPassword: SetNewPasswordUnion;
  setPersonalGoals: UpdateDataUnion;
  startChallenge: UpdateUnion;
  startRelapseMode: UpdateDataUnion;
  testMutation: UpdateUnion;
  unfollow: UpdateDataUnion;
  updateAgentId: UpdateDataUnion;
  updateAvatar: UpdateDataUnion;
  updateDailyReviewLastShown: UpdateDataUnion;
  updateDataConsent: UpdateDataUnion;
  updateImportantWarningFlag: UpdateDataUnion;
  updateIsPaid: UpdateDataUnion;
  /** @deprecated Language will be passed during registration */
  updateLanguage: UpdateDataUnion;
  updateLastQuitDateReset: UpdateDataUnion;
  updateLevelFlags: UpdateUserFlagsUnion;
  updateOnboardingFlag: UpdateDataUnion;
  updatePersonalGoal: UpdateUnion;
  updatePersonalInformation: UpdateDataUnion;
  updatePuffs: UpdateDataUnion;
  updateSmokingBehavior: UpdateDataUnion;
  updateSmokingStopped: UpdateDataUnion;
  updateStartDate: UpdateDataUnion;
  updateSupporterMode: UpdateDataUnion;
  updateUsername: UpdateUsernameUnion;
  upsertChecklistItem: UpdateDataUnion;
  useLicense: UseLicenseUnion;
};

export type MutationAcceptChallengeInviteArgs = {
  profile: ProfileInput;
  updatedData: ChallengeInput;
};

export type MutationAddAfterProgramDaysArgs = {
  profile: ProfileInput;
  updatedData: AddAfterProgramInput;
};

export type MutationAddChallengeMessageArgs = {
  profile: ProfileInput;
  updatedData: ChallengeMessageInsert;
};

export type MutationAddChallengeParticipantArgs = {
  profile: ProfileInput;
  updatedData: ChallengeParticipantInput;
};

export type MutationAddEmergencyKitActionArgs = {
  profile: ProfileInput;
  updatedData: AddEmergencyKitActionInput;
};

export type MutationAddFinancialGoalArgs = {
  profile: ProfileInput;
  updatedData: AddFinancialGoalInput;
};

export type MutationAddHighfivesArgs = {
  profile: ProfileInput;
  updatedData: AddHighfivesInput;
};

export type MutationAddMissionArgs = {
  profile: ProfileInput;
  updatedData: AddMissionInput;
};

export type MutationAddNicotineUsageArgs = {
  profile: ProfileInput;
  updatedData: AddNicotineUsageInput;
};

export type MutationAddNotificationTokenArgs = {
  profile: ProfileInput;
  updatedData: AddNotificationTokenInput;
};

export type MutationAddProfileViewArgs = {
  profile: ProfileInput;
};

export type MutationAddQuizResultArgs = {
  profile: ProfileInput;
  updatedData: AddQuizResultInput;
};

export type MutationAddSharingArgs = {
  profile: ProfileInput;
  updatedData: AddSharingInput;
};

export type MutationAddSmokedCigaretteArgs = {
  profile: ProfileInput;
  updatedData: AddSmokedCigaretteInput;
};

export type MutationAddVoucherArgs = {
  profile: ProfileInput;
  updatedData: AddVoucherInput;
};

export type MutationCalculateDailyGoalsArgs = {
  profile: ProfileInput;
  updatedData: CalculateDailyGoalsInput;
};

export type MutationCalculateUserStarsArgs = {
  profile: ProfileInput;
};

export type MutationCancelChallengeArgs = {
  profile: ProfileInput;
  updatedData: ChallengeInput;
};

export type MutationCollectStarsArgs = {
  profile: ProfileInput;
  updatedData: CollectStarsInput;
};

export type MutationCreateChallengeArgs = {
  profile: ProfileInput;
  updatedData: ChallengeInsert;
};

export type MutationCreateTestUserArgs = {
  updatedData: CreateTestUserInput;
};

export type MutationDeleteUserArgs = {
  profile: ProfileInput;
};

export type MutationFinalizeRegistrationArgs = {
  profile: ProfileInput;
};

export type MutationFollowArgs = {
  profile: ProfileInput;
  updatedData: FollowInput;
};

export type MutationForceUpdateChallengeArgs = {
  challenge: ChallengeInput;
  updatedData: JsonStringInput;
};

export type MutationForceUpdateProfilePrivateArgs = {
  profile: ProfileInput;
  updatedData: JsonStringInput;
};

export type MutationForceUpdateProfilePublicArgs = {
  profile: ProfileInput;
  updatedData: JsonStringInput;
};

export type MutationQueueUserUpdateArgs = {
  profile: ProfileInput;
};

export type MutationRegisterArgs = {
  updatedData: RegisterWithUsernameInput;
};

export type MutationRejectChallengeInviteArgs = {
  profile: ProfileInput;
  updatedData: ChallengeInput;
};

export type MutationRemoveChallengeArgs = {
  profile: ProfileInput;
  updatedData: ChallengeInput;
};

export type MutationRemoveChallengeParticipantArgs = {
  profile: ProfileInput;
  updatedData: ChallengeParticipantInput;
};

export type MutationRemoveChecklistItemArgs = {
  profile: ProfileInput;
  updatedData: RemoveChecklistItemInput;
};

export type MutationRemoveEmergencyKitActionArgs = {
  profile: ProfileInput;
  updatedData: EmergencyKitActionInput;
};

export type MutationRemoveFollowerArgs = {
  profile: ProfileInput;
  updatedData: FollowInput;
};

export type MutationRemoveVoucherArgs = {
  profile: ProfileInput;
  updatedData: RemoveVoucherInput;
};

export type MutationReportChallengeArgs = {
  profile: ProfileInput;
  updatedData: ReportChallengeInput;
};

export type MutationReportChallengeMessageArgs = {
  profile: ProfileInput;
  updatedData: ReportChallengeMessageInput;
};

export type MutationReportUserArgs = {
  profile: ProfileInput;
  updatedData: ReportUserInput;
};

export type MutationReportVoucherArgs = {
  profile: ProfileInput;
  updatedData: ReportVoucherInput;
};

export type MutationRequestEmailChangeArgs = {
  profile: ProfileInput;
  updatedData: RequestEmailChangeInput;
};

export type MutationRequestPasswordResetArgs = {
  updatedData: RequestPasswordInput;
};

export type MutationResendVerificationEmailArgs = {
  updatedData: ResendVerificationInput;
};

export type MutationResetPasswordArgs = {
  updatedData: ResetPasswordInput;
};

export type MutationResetUserArgs = {
  profile: ProfileInput;
};

export type MutationSendMessageArgs = {
  updatedData: SendMessageInput;
};

export type MutationSetChallengeMessagesReadArgs = {
  profile: ProfileInput;
  updatedData: ChallengeInput;
};

export type MutationSetMessageReadArgs = {
  profile: ProfileInput;
  updatedData: SetMessageReadInput;
};

export type MutationSetNewPasswordArgs = {
  profile: ProfileInput;
  updatedData: NewPasswordInput;
};

export type MutationSetPersonalGoalsArgs = {
  profile: ProfileInput;
  updatedData: SetPersonalGoalsInput;
};

export type MutationStartChallengeArgs = {
  profile: ProfileInput;
  updatedData: ChallengeInput;
};

export type MutationStartRelapseModeArgs = {
  profile: ProfileInput;
};

export type MutationTestMutationArgs = {
  profile: ProfileInput;
  updatedData: TestMutationInput;
};

export type MutationUnfollowArgs = {
  profile: ProfileInput;
  updatedData: FollowInput;
};

export type MutationUpdateAgentIdArgs = {
  profile: ProfileInput;
  updatedData: UpdateAgentIdInput;
};

export type MutationUpdateAvatarArgs = {
  profile: ProfileInput;
  updatedData: AvatarInput;
};

export type MutationUpdateDailyReviewLastShownArgs = {
  profile: ProfileInput;
  updatedData: DailyReviewLastShownInput;
};

export type MutationUpdateDataConsentArgs = {
  profile: ProfileInput;
  updatedData: DataConsentInput;
};

export type MutationUpdateImportantWarningFlagArgs = {
  profile: ProfileInput;
  updatedData: ImportantWarningFlagInput;
};

export type MutationUpdateIsPaidArgs = {
  profile: ProfileInput;
  updatedData: IsPaidInput;
};

export type MutationUpdateLanguageArgs = {
  profile: ProfileInput;
  updatedData: LanguageInput;
};

export type MutationUpdateLastQuitDateResetArgs = {
  profile: ProfileInput;
  updatedData: LastQuitDateResetInput;
};

export type MutationUpdateLevelFlagsArgs = {
  profile: ProfileInput;
  updatedData: LevelFlagsInput;
};

export type MutationUpdateOnboardingFlagArgs = {
  profile: ProfileInput;
  updatedData: OnboardingFlagInput;
};

export type MutationUpdatePersonalGoalArgs = {
  profile: ProfileInput;
  updatedData: UpdatePersonalGoalInput;
};

export type MutationUpdatePersonalInformationArgs = {
  profile: ProfileInput;
  updatedData: ProfilePrivateInput;
};

export type MutationUpdatePuffsArgs = {
  profile: ProfileInput;
  updatedData: PuffsInput;
};

export type MutationUpdateSmokingBehaviorArgs = {
  profile: ProfileInput;
  updatedData: SmokingBehaviorInput;
};

export type MutationUpdateSmokingStoppedArgs = {
  profile: ProfileInput;
  updatedData: UpdateSmokingStoppedInput;
};

export type MutationUpdateStartDateArgs = {
  profile: ProfileInput;
  updatedData: StartDateInput;
};

export type MutationUpdateSupporterModeArgs = {
  profile: ProfileInput;
  updatedData: SupporterModeInput;
};

export type MutationUpdateUsernameArgs = {
  profile: ProfileInput;
  updatedData: UsernameInput;
};

export type MutationUpsertChecklistItemArgs = {
  profile: ProfileInput;
  updatedData: ChecklistItemInput;
};

export type MutationUseLicenseArgs = {
  updatedData: LicenseInput;
};

export type NewPasswordInput = {
  newPassword: Scalars["String"];
  oldPassword: Scalars["String"];
};

export type NicotineUsage = {
  __typename?: "NicotineUsage";
  /** Date in ISO format (YYYY-MM-DDTHH:mm:ss.sssZ) */
  date: Scalars["String"];
  puffsWithNicotine: Scalars["Int"];
  puffsWithoutNicotine: Scalars["Int"];
};

export type NotUpdated = {
  __typename?: "NotUpdated";
  notUpdated: Scalars["Boolean"];
};

export type OfficialVoucher = {
  __typename?: "OfficialVoucher";
  /** URL to image */
  bg: Scalars["String"];
  code: Scalars["String"];
  content: Scalars["String"];
  /** URL to image */
  logo: Scalars["String"];
  stars: Scalars["Int"];
  subtitle: Scalars["String"];
  title: Scalars["String"];
};

export type OnboardingFlagInput = {
  hasCompleted: Scalars["Boolean"];
};

export type PaginationInput = {
  limit: Scalars["Int"];
  offset: Scalars["Int"];
};

export type PartialProfilePrivate = {
  __typename?: "PartialProfilePrivate";
  afterProgramData?: Maybe<AfterProgramData>;
  agentId?: Maybe<Scalars["String"]>;
  checklist?: Maybe<Array<ChecklistItem>>;
  dailyReviewLastShown?: Maybe<Scalars["String"]>;
  /** Date in YYYY-MM-DD format */
  dayOfBirth?: Maybe<Scalars["String"]>;
  deleted?: Maybe<Scalars["Boolean"]>;
  dipOfLastStarsCalculation?: Maybe<Scalars["Float"]>;
  email?: Maybe<Scalars["String"]>;
  emergencyKitActions?: Maybe<Array<EmergencyKitAction>>;
  financialGoals?: Maybe<Array<FinancialGoal>>;
  firstname?: Maybe<Scalars["String"]>;
  followData?: Maybe<FollowData>;
  goals?: Maybe<Array<Goal>>;
  isDailyGoalCalculated?: Maybe<Scalars["Boolean"]>;
  isPaid?: Maybe<Scalars["Boolean"]>;
  /** Date of last updateStartDate() in ISO format (YYYY-MM-DDTHH:mm:ss.sssZ) */
  lastJumpDate?: Maybe<Scalars["String"]>;
  lastReset?: Maybe<Scalars["String"]>;
  lastname?: Maybe<Scalars["String"]>;
  levels?: Maybe<Array<Level>>;
  messageData?: Maybe<MessageData>;
  missions?: Maybe<Array<UserMissionData>>;
  notificationTokens?: Maybe<Array<Scalars["String"]>>;
  permissions?: Maybe<GrantedPermissions>;
  quizzes?: Maybe<Array<Quiz>>;
  registrationFlags?: Maybe<RegistrationFlags>;
  /** Date in ISO format (YYYY-MM-DDTHH:mm:ss.sssZ) */
  relapseStartDate?: Maybe<Scalars["String"]>;
  reportedUsers?: Maybe<Array<ReportedUser>>;
  shares?: Maybe<Array<Share>>;
  smoking?: Maybe<SmokingData>;
  stars?: Maybe<Array<Stars>>;
  starsData?: Maybe<Array<StarsData>>;
  tobacco?: Maybe<Tobacco>;
  uid?: Maybe<Scalars["String"]>;
  userFlags?: Maybe<UserFlags>;
  vouchers?: Maybe<VoucherData>;
};

export type PartialProfilePublic = {
  __typename?: "PartialProfilePublic";
  agentId?: Maybe<Scalars["String"]>;
  amountChallenges?: Maybe<Scalars["Int"]>;
  amountFollower?: Maybe<Scalars["Int"]>;
  amountFollowing?: Maybe<Scalars["Int"]>;
  avatar?: Maybe<Avatar>;
  badges?: Maybe<Array<Badge>>;
  /** Two letter country code, e.g. DE */
  countryCode?: Maybe<Scalars["String"]>;
  highfives?: Maybe<Highfives>;
  /** Accepts: de, en */
  lang?: Maybe<Language>;
  lastQuitDateReset?: Maybe<Scalars["String"]>;
  os?: Maybe<Scalars["String"]>;
  profileViews?: Maybe<Scalars["Int"]>;
  public?: Maybe<Scalars["Boolean"]>;
  /** Date in ISO format (YYYY-MM-DDTHH:mm:ss.sssZ) */
  quitDate?: Maybe<Scalars["String"]>;
  /** Date in ISO format (YYYY-MM-DDTHH:mm:ss.sssZ) */
  relapseStartDate?: Maybe<Scalars["String"]>;
  /** Date in ISO format (YYYY-MM-DDTHH:mm:ss.sssZ) */
  starsLastUpdated?: Maybe<Scalars["String"]>;
  starsTotal?: Maybe<Scalars["Int"]>;
  start_date?: Maybe<Scalars["String"]>;
  supporterMode?: Maybe<Scalars["Boolean"]>;
  /** Timezone in format "Region/City" */
  timezone?: Maybe<Scalars["String"]>;
  uid?: Maybe<Scalars["String"]>;
  username?: Maybe<Scalars["String"]>;
};

export type Participant = {
  __typename?: "Participant";
  achieved: Array<ChallengeGoalAchieved>;
  avatar?: Maybe<Avatar>;
  lang: Scalars["String"];
  msgRead: Scalars["Int"];
  stars: Scalars["Int"];
  start_date: Scalars["String"];
  status: ChallengeParticipantStatus;
  uid: Scalars["String"];
  username: Scalars["String"];
};

export type ParticipantNotAdded = {
  __typename?: "ParticipantNotAdded";
  message: Scalars["String"];
};

export type PasswordWeak = {
  __typename?: "PasswordWeak";
  message: Scalars["String"];
};

export type PasswordWrong = {
  __typename?: "PasswordWrong";
  message: Scalars["String"];
};

export type PlaceholderReplacement = {
  __typename?: "PlaceholderReplacement";
  placeholder: Scalars["String"];
  replacement: Scalars["String"];
};

export type ProfileFull = {
  __typename?: "ProfileFull";
  profilePrivate: ProfilePrivate;
  profilePublic: ProfilePublic;
};

export type ProfileInput = {
  uid: Scalars["String"];
};

export type ProfileNoPermission = {
  __typename?: "ProfileNoPermission";
  message: Scalars["String"];
};

export type ProfileNotFound = {
  __typename?: "ProfileNotFound";
  message: Scalars["String"];
};

export type ProfileNotPublic = {
  __typename?: "ProfileNotPublic";
  message: Scalars["String"];
};

export type ProfilePrivate = {
  __typename?: "ProfilePrivate";
  afterProgramData: AfterProgramData;
  agentId?: Maybe<Scalars["String"]>;
  checklist: Array<ChecklistItem>;
  /** Date in ISO format (YYYY-MM-DDTHH:mm:ss.sssZ) */
  dailyReviewLastShown: Scalars["String"];
  /** Date in YYYY-MM-DD format */
  dayOfBirth: Scalars["String"];
  deleted?: Maybe<Scalars["Boolean"]>;
  /** Day in program when stars calculation of nightly job was done */
  dipOfLastStarsCalculation?: Maybe<Scalars["Float"]>;
  /** Day in program when stars calculation was last done after puff is synched */
  dipOfLastSyncedStarsCalculation?: Maybe<Scalars["Float"]>;
  email: Scalars["String"];
  emergencyKitActions: Array<EmergencyKitAction>;
  financialGoals: Array<FinancialGoal>;
  firstname: Scalars["String"];
  followData: FollowData;
  goals: Array<Goal>;
  isDailyGoalCalculated?: Maybe<Scalars["Boolean"]>;
  isPaid: Scalars["Boolean"];
  /** Date of last updateStartDate() in ISO format (YYYY-MM-DDTHH:mm:ss.sssZ) */
  lastJumpDate?: Maybe<Scalars["String"]>;
  /** Date in ISO format (YYYY-MM-DDTHH:mm:ss.sssZ) */
  lastReset?: Maybe<Scalars["String"]>;
  lastname: Scalars["String"];
  levels: Array<Level>;
  messageData: MessageData;
  missions: Array<UserMissionData>;
  notificationTokens: Array<Scalars["String"]>;
  permissions?: Maybe<GrantedPermissions>;
  quizzes: Array<Quiz>;
  registrationFlags: RegistrationFlags;
  /** Date in ISO format (YYYY-MM-DDTHH:mm:ss.sssZ) */
  relapseStartDate?: Maybe<Scalars["String"]>;
  reportedUsers: Array<ReportedUser>;
  shares: Array<Share>;
  smoking: SmokingData;
  stars: Array<Stars>;
  starsData: Array<StarsData>;
  tobacco: Tobacco;
  uid: Scalars["String"];
  userFlags: UserFlags;
  vouchers: VoucherData;
};

export type ProfilePrivateInput = {
  /** Date in format YYYY-MM-DD */
  dayOfBirth: Scalars["String"];
  firstname: Scalars["String"];
  lastname: Scalars["String"];
  /** Timezone in format "Region/City" */
  timezone: Scalars["String"];
};

export type ProfilePublic = {
  __typename?: "ProfilePublic";
  amountChallenges: Scalars["Int"];
  amountFollower: Scalars["Int"];
  amountFollowing: Scalars["Int"];
  avatar?: Maybe<Avatar>;
  badges: Array<Badge>;
  /** Two letter country code, e.g. DE */
  countryCode: Scalars["String"];
  highfives: Highfives;
  /** Accepts: de, en */
  lang?: Maybe<Language>;
  lastQuitDateReset?: Maybe<Scalars["String"]>;
  os?: Maybe<Scalars["String"]>;
  profileViews: Scalars["Int"];
  public: Scalars["Boolean"];
  /** Date in ISO format (YYYY-MM-DDTHH:mm:ss.sssZ) */
  quitDate: Scalars["String"];
  /** Date in ISO format (YYYY-MM-DDTHH:mm:ss.sssZ) */
  relapseStartDate?: Maybe<Scalars["String"]>;
  starsCollected: Scalars["Int"];
  /** Date in ISO format (YYYY-MM-DDTHH:mm:ss.sssZ) */
  starsLastUpdated: Scalars["String"];
  starsTotal: Scalars["Int"];
  start_date: Scalars["String"];
  supporterMode?: Maybe<Scalars["Boolean"]>;
  /** Timezone in format "Region/City" */
  timezone: Scalars["String"];
  uid: Scalars["String"];
  username: Scalars["String"];
};

export type PuffDay = {
  day: Scalars["Int"];
  /** Array with 24 numbers of puffs for each hour */
  hours: Array<Scalars["Int"]>;
};

export type PuffsInput = {
  days: Array<PuffDay>;
  /** Date in ISO format (YYYY-MM-DDTHH:mm:ss.sssZ) */
  lastSync: Scalars["String"];
};

export type Query = {
  __typename?: "Query";
  exportUserData: GetUserDataExportUnion;
  getAdminRestricted: Hello;
  getAllUsers: AllUsers;
  getChallenges: GetChallengesUnion;
  getChecklistItemStubs: Array<ChecklistItemStub>;
  getEmailChangeStatus: EmailChangeStatus;
  getMessages: GetMessagesUnion;
  getOfficialVouchers: Array<OfficialVoucher>;
  getOneChallenge: GetOneChallengeUnion;
  getOwnProfilePublic: GetProfilePublicUnion;
  getPersonalDateMoneySaved: GetMoneySavedOutput;
  getProfilePrivate: GetProfilePrivateUnion;
  getProfilePublic: GetProfilePublicUnion;
  getReportedChallengeMessages: Array<ReportedChallengeMessage>;
  getReportedChallenges: Array<ReportedChallenge>;
  getReportedUsers: Array<ReportedUserData>;
  getReportedVouchers: Array<ReportedVoucher>;
  getUserRestricted: Hello;
  searchUserExact: SearchUsernameResults;
  searchUserInReduction: SearchUserInReductionResults;
  searchUserStartingWith: SearchUsernameResults;
};

export type QueryExportUserDataArgs = {
  profile: ProfileInput;
};

export type QueryGetAdminRestrictedArgs = {
  profile: ProfileInput;
};

export type QueryGetAllUsersArgs = {
  pagination: PaginationInput;
};

export type QueryGetChallengesArgs = {
  profile: ProfileInput;
};

export type QueryGetChecklistItemStubsArgs = {
  profile: ProfileInput;
};

export type QueryGetEmailChangeStatusArgs = {
  profile: ProfileInput;
};

export type QueryGetMessagesArgs = {
  profile: ProfileInput;
};

export type QueryGetOfficialVouchersArgs = {
  profile: ProfileInput;
};

export type QueryGetOneChallengeArgs = {
  challenge: ChallengeInput;
  profile: ProfileInput;
};

export type QueryGetOwnProfilePublicArgs = {
  profile: ProfileInput;
};

export type QueryGetPersonalDateMoneySavedArgs = {
  data: GetMoneySavedInput;
  profile: ProfileInput;
};

export type QueryGetProfilePrivateArgs = {
  profile: ProfileInput;
};

export type QueryGetProfilePublicArgs = {
  profile: ProfileInput;
};

export type QueryGetReportedChallengeMessagesArgs = {
  pagination: PaginationInput;
};

export type QueryGetReportedChallengesArgs = {
  pagination: PaginationInput;
};

export type QueryGetReportedUsersArgs = {
  pagination: PaginationInput;
};

export type QueryGetReportedVouchersArgs = {
  pagination: PaginationInput;
};

export type QueryGetUserRestrictedArgs = {
  profile: ProfileInput;
};

export type QuerySearchUserExactArgs = {
  search: SearchUsernameWithFiltersInput;
};

export type QuerySearchUserInReductionArgs = {
  search: SearchUsernameInput;
};

export type QuerySearchUserStartingWithArgs = {
  search: SearchUsernameWithFiltersInput;
};

export type Quiz = {
  __typename?: "Quiz";
  /** Date in ISO format (YYYY-MM-DDTHH:mm:ss.sssZ) */
  completed: Scalars["String"];
  name: Scalars["String"];
  questionCount: Scalars["Int"];
};

export enum RedeemType {
  Days90 = "Days90",
  Years1 = "Years1",
  Years5 = "Years5",
}

export type RegisterWithUsernameInput = {
  email: Scalars["String"];
  language: Scalars["String"];
  os: Scalars["String"];
  password: Scalars["String"];
  username: Scalars["String"];
};

export type RegistrationError = {
  __typename?: "RegistrationError";
  error: Scalars["String"];
};

export type RegistrationFlags = {
  __typename?: "RegistrationFlags";
  avatar: Scalars["Boolean"];
  dataConsent: Scalars["Boolean"];
  emailVerified: Scalars["Boolean"];
  importantWarning: Scalars["Boolean"];
  personalized: Scalars["Boolean"];
  registrationOnboardingCompleted: Scalars["Boolean"];
  tobacco: Scalars["Boolean"];
  userProfileCreated: Scalars["Boolean"];
  username: Scalars["Boolean"];
};

export type RegistrationUnion = AccessPayload | RegistrationError;

export type RemoveChecklistItemInput = {
  title: Scalars["String"];
};

export type RemoveVoucherInput = {
  uidRecipient: Scalars["String"];
  voucherId: Scalars["String"];
};

export type ReportChallengeInput = {
  challengeId: Scalars["String"];
};

export type ReportChallengeMessageInput = {
  challengeId: Scalars["String"];
  messageId: Scalars["String"];
};

export type ReportUserInput = {
  reportedUid: Scalars["String"];
};

export type ReportVoucherInput = {
  title?: Maybe<Scalars["String"]>;
  voucherId: Scalars["String"];
};

export type ReportedChallenge = {
  __typename?: "ReportedChallenge";
  challengeId: Scalars["String"];
  createdAt: Scalars["DateTime"];
  issuerUid: Scalars["String"];
  name?: Maybe<Scalars["String"]>;
  reportedUid: Scalars["String"];
  updatedAt: Scalars["DateTime"];
};

export type ReportedChallengeMessage = {
  __typename?: "ReportedChallengeMessage";
  challengeId: Scalars["String"];
  createdAt: Scalars["DateTime"];
  issuerUid: Scalars["String"];
  message: Scalars["String"];
  messageId: Scalars["String"];
  reportedUid: Scalars["String"];
  updatedAt: Scalars["DateTime"];
};

export type ReportedUser = {
  __typename?: "ReportedUser";
  uid: Scalars["String"];
  username: Scalars["String"];
};

export type ReportedUserData = {
  __typename?: "ReportedUserData";
  createdAt: Scalars["DateTime"];
  issuerUid: Scalars["String"];
  reportedUid: Scalars["String"];
  updatedAt: Scalars["DateTime"];
  username?: Maybe<Scalars["String"]>;
};

export type ReportedVoucher = {
  __typename?: "ReportedVoucher";
  createdAt: Scalars["DateTime"];
  issuerUid: Scalars["String"];
  reportedUid: Scalars["String"];
  title?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
  voucherId: Scalars["String"];
};

export type RequestEmailChangeInput = {
  newEmail: Scalars["String"];
  password: Scalars["String"];
};

export type RequestEmailChangeUnion =
  | EmailAlreadyUsed
  | InvalidCredentials
  | ProfileNotFound
  | UpdateOk;

export type RequestPasswordInput = {
  email: Scalars["String"];
};

export type ResendVerificationInput = {
  email: Scalars["String"];
};

export type ResetPasswordInput = {
  password: Scalars["String"];
  token: Scalars["String"];
};

export type SearchUserInReductionResults = {
  __typename?: "SearchUserInReductionResults";
  results: Array<SearchUsernameResult>;
  skippedResults: Scalars["Int"];
};

export type SearchUsernameInput = {
  username: Scalars["String"];
};

export type SearchUsernameResult = {
  __typename?: "SearchUsernameResult";
  avatar?: Maybe<Avatar>;
  public: Scalars["Boolean"];
  start_date: Scalars["String"];
  supporterMode?: Maybe<Scalars["Boolean"]>;
  uid: Scalars["String"];
  username: Scalars["String"];
};

export type SearchUsernameResults = {
  __typename?: "SearchUsernameResults";
  results: Array<SearchUsernameResult>;
};

export type SearchUsernameWithFiltersInput = {
  /** Filter for isPublic state of user. Leave empty for no filter */
  isPublic?: Maybe<Scalars["Boolean"]>;
  /** Filter for isSupporter state of user. Leave empty for no filter */
  isSupporter?: Maybe<Scalars["Boolean"]>;
  username: Scalars["String"];
};

export type SendMessageInput = {
  /** Two-letter language code capitalized (accepts: de, en) */
  language: Language;
  receiverUid: Scalars["String"];
  /** Stringified JSON object of replacement parameters for the message */
  replacementsJson?: Maybe<Scalars["String"]>;
  slag: Scalars["String"];
  /** Timezone in format "Region/City" */
  timezone?: Maybe<Scalars["String"]>;
};

export type Sender = {
  __typename?: "Sender";
  avatar: Avatar;
  uid: Scalars["String"];
  username: Scalars["String"];
};

export type SetMessageReadInput = {
  isRead: Scalars["Boolean"];
  messageId: Scalars["String"];
};

export type SetNewPasswordUnion =
  | PasswordWeak
  | PasswordWrong
  | ProfileNotFound
  | UpdateOk;

export type SetPersonalGoalsInput = {
  goals: Array<GoalInput>;
};

export type Share = {
  __typename?: "Share";
  /** Date in ISO format (YYYY-MM-DDTHH:mm:ss.sssZ) */
  date: Scalars["String"];
  platform: Scalars["String"];
};

export type SmokedCigarette = {
  __typename?: "SmokedCigarette";
  count: Scalars["Int"];
  date: Scalars["String"];
};

export type SmokingBehaviorInput = {
  brand: Scalars["String"];
  flavor: Scalars["String"];
  numberOfCigarettes: Scalars["Int"];
};

export type SmokingData = {
  __typename?: "SmokingData";
  dailyGoals: Array<SmokingGoal>;
  /** Date in ISO format (YYYY-MM-DDTHH:mm:ss.sssZ) */
  lastSync: Scalars["String"];
  nicotineUsages: Array<NicotineUsage>;
  puffs: Array<SmokingDay>;
  smokedCigarette: Array<SmokedCigarette>;
  /** If user decides to stop already completely for the last 7 days, the stopped prop in the entries is set to true */
  stopped: Array<SmokingStopped>;
  uid: Scalars["String"];
};

export type SmokingDay = {
  __typename?: "SmokingDay";
  day: Scalars["Int"];
  /** Array with 24 numbers of puffs for each hour */
  hours: Array<Scalars["Int"]>;
};

export type SmokingGoal = {
  __typename?: "SmokingGoal";
  day: Scalars["Int"];
  goal: Scalars["Int"];
};

export type SmokingStopped = {
  __typename?: "SmokingStopped";
  stopped: Scalars["Boolean"];
  /** Accepts: mon, tue, wed, thu, fri, sat, sun */
  weekday: Weekday;
};

export type Stars = {
  __typename?: "Stars";
  afterProgramSmoking: Scalars["Int"];
  badges: Scalars["Int"];
  challenge: Scalars["Int"];
  dailyGoal: Scalars["Int"];
  emergency: Scalars["Int"];
  highfives: Scalars["Int"];
  missions: Scalars["Int"];
  multiple: Scalars["Int"];
  sharing: Scalars["Int"];
  supporters: Scalars["Int"];
  supporting: Scalars["Int"];
};

export enum StarsCategory {
  AfterProgramSmoking = "AfterProgramSmoking",
  Badges = "Badges",
  Challenges = "Challenges",
  DailyGoal = "DailyGoal",
  Emergency = "Emergency",
  Highfives = "Highfives",
  Missions = "Missions",
  MultipleDays = "MultipleDays",
  Sharing = "Sharing",
  Supporters = "Supporters",
  Supporting = "Supporting",
}

export type StarsData = {
  __typename?: "StarsData";
  amount: Scalars["Int"];
  category: StarsCategory;
  /** Date in ISO format (YYYY-MM-DDTHH:mm:ss.sssZ) */
  collectedDate?: Maybe<Scalars["String"]>;
  /** Date in ISO format (YYYY-MM-DDTHH:mm:ss.sssZ) */
  receivedDate: Scalars["String"];
  receivedOnDayInProgram: Scalars["Int"];
  replacements: Array<PlaceholderReplacement>;
  starsId: Scalars["String"];
  textId: Scalars["String"];
  uniquenessId?: Maybe<Scalars["String"]>;
};

export type StartDateInput = {
  day: Scalars["Int"];
  /** When set to 'true' the daily updates will not be applied, which includes stars calculation, daily messages and badges */
  skipDailyUpdate?: Maybe<Scalars["Boolean"]>;
};

export type Subscription = {
  __typename?: "Subscription";
  challengeMessage: ChallengeChatMessage;
  message: Message;
  profilePrivateUpdated: PartialProfilePrivate;
  profilePublicUpdated: PartialProfilePublic;
};

export type SubscriptionChallengeMessageArgs = {
  challenge: ChallengeInput;
  profile: ProfileInput;
};

export type SubscriptionMessageArgs = {
  profile: ProfileInput;
};

export type SubscriptionProfilePrivateUpdatedArgs = {
  profile: ProfileInput;
};

export type SubscriptionProfilePublicUpdatedArgs = {
  profile: ProfileInput;
};

export type SupporterModeInput = {
  isSupporter: Scalars["Boolean"];
};

export type TestMutationInput = {
  shouldPass: Scalars["Boolean"];
};

export type Tobacco = {
  __typename?: "Tobacco";
  brand: Scalars["String"];
  flavor: Scalars["String"];
  numberOfCigarettes: Scalars["Int"];
};

export type UpdateAgentIdInput = {
  agentId: Scalars["String"];
};

export type UpdateDataUnion = InvalidInput | ProfileNotFound | UpdateOk;

export type UpdateOk = {
  __typename?: "UpdateOk";
  ok: Scalars["Boolean"];
};

export type UpdatePersonalGoalInput = {
  goalId: Scalars["String"];
  isAchieved: Scalars["Boolean"];
};

export type UpdateSmokingStoppedInput = {
  stopped: Scalars["Boolean"];
  weekday: Weekday;
};

export type UpdateUnion = NotUpdated | UpdateOk;

export type UpdateUniqueDataUnion = AlreadyExists | ProfileNotFound | UpdateOk;

export type UpdateUserFlagsUnion = ProfileNotFound | UserFlags;

export type UpdateUsernameUnion = AlreadyExists | ProfileNotFound | UpdateOk;

export type UseLicenseUnion = LicenseNoUses | LicenseNotFound | UpdateOk;

export type User = {
  __typename?: "User";
  createdAt: Scalars["String"];
  email: Scalars["String"];
  uid: Scalars["String"];
};

export type UserDataExport = {
  __typename?: "UserDataExport";
  dataCsv: Scalars["String"];
};

export type UserFlags = {
  __typename?: "UserFlags";
  levelFlags: LevelFlags;
};

export type UserMissionData = {
  __typename?: "UserMissionData";
  /** Date in ISO format (YYYY-MM-DDTHH:mm:ss.sssZ) */
  completed: Scalars["String"];
  messageId: Scalars["String"];
  stars: Scalars["Int"];
  type: Scalars["String"];
};

export type UsernameInput = {
  username: Scalars["String"];
};

export type ValidationConstraint = {
  __typename?: "ValidationConstraint";
  message: Scalars["String"];
  name: Scalars["String"];
};

export type Voucher = {
  __typename?: "Voucher";
  collectedDate?: Maybe<Scalars["String"]>;
  finishDate?: Maybe<Scalars["String"]>;
  nameRecipient: Scalars["String"];
  nameSupporter: Scalars["String"];
  redeemType: RedeemType;
  uidRecipient: Scalars["String"];
  uidSupporter: Scalars["String"];
  voucherId: Scalars["String"];
  voucherText: Scalars["String"];
  voucherTitle: Scalars["String"];
};

export type VoucherData = {
  __typename?: "VoucherData";
  vouchersForMe: Array<Voucher>;
  vouchersForOthers: Array<Voucher>;
};

export enum Weekday {
  Fri = "Fri",
  Mon = "Mon",
  Sat = "Sat",
  Sun = "Sun",
  Thu = "Thu",
  Tue = "Tue",
  Wed = "Wed",
}

export type AddHighfivesMutationVariables = Exact<{
  profile: ProfileInput;
  updatedData: AddHighfivesInput;
}>;

export type AddHighfivesMutation = {
  __typename?: "Mutation";
  addHighfives:
    | {
        __typename: "InvalidInput";
        errors: Array<{
          __typename?: "InputError";
          property: string;
          value: string;
          constraints: Array<{
            __typename?: "ValidationConstraint";
            message: string;
            name: string;
          }>;
        }>;
      }
    | { __typename: "ProfileNotFound"; message: string }
    | { __typename: "UpdateOk"; ok: boolean };
};

export type AddProfileViewMutationVariables = Exact<{
  profile: ProfileInput;
}>;

export type AddProfileViewMutation = {
  __typename?: "Mutation";
  addProfileView:
    | {
        __typename: "InvalidInput";
        errors: Array<{
          __typename?: "InputError";
          property: string;
          value: string;
          constraints: Array<{
            __typename?: "ValidationConstraint";
            name: string;
            message: string;
          }>;
        }>;
      }
    | { __typename: "ProfileNotFound"; message: string }
    | { __typename: "UpdateOk"; ok: boolean };
};

export type AddVoucherMutationVariables = Exact<{
  profile: ProfileInput;
  updatedData: AddVoucherInput;
}>;

export type AddVoucherMutation = {
  __typename?: "Mutation";
  addVoucher:
    | {
        __typename: "InvalidInput";
        errors: Array<{
          __typename?: "InputError";
          constraints: Array<{
            __typename?: "ValidationConstraint";
            name: string;
            message: string;
          }>;
        }>;
      }
    | { __typename?: "ProfileNotFound" }
    | { __typename: "UpdateOk"; ok: boolean };
};

export type DeleteUserMutationVariables = Exact<{
  profile: ProfileInput;
}>;

export type DeleteUserMutation = {
  __typename?: "Mutation";
  deleteUser:
    | {
        __typename: "InvalidInput";
        errors: Array<{
          __typename?: "InputError";
          property: string;
          value: string;
          constraints: Array<{
            __typename?: "ValidationConstraint";
            message: string;
            name: string;
          }>;
        }>;
      }
    | { __typename: "ProfileNotFound"; message: string }
    | { __typename: "UpdateOk"; ok: boolean };
};

export type FollowMutationVariables = Exact<{
  profile: ProfileInput;
  updatedData: FollowInput;
}>;

export type FollowMutation = {
  __typename?: "Mutation";
  follow:
    | {
        __typename: "InvalidInput";
        errors: Array<{
          __typename?: "InputError";
          constraints: Array<{
            __typename?: "ValidationConstraint";
            message: string;
          }>;
        }>;
      }
    | { __typename: "ProfileNotFound"; message: string }
    | { __typename: "UpdateOk"; ok: boolean };
};

export type RegisterMutationVariables = Exact<{
  updatedData: RegisterWithUsernameInput;
}>;

export type RegisterMutation = {
  __typename?: "Mutation";
  register:
    | { __typename: "AccessPayload"; uid: string; accessToken: string }
    | { __typename: "RegistrationError"; error: string };
};

export type RequestPasswordResetMutationVariables = Exact<{
  updatedData: RequestPasswordInput;
}>;

export type RequestPasswordResetMutation = {
  __typename?: "Mutation";
  requestPasswordReset:
    | { __typename: "NotUpdated"; notUpdated: boolean }
    | { __typename: "UpdateOk"; ok: boolean };
};

export type ResendVerificationEmailMutationVariables = Exact<{
  updatedData: ResendVerificationInput;
}>;

export type ResendVerificationEmailMutation = {
  __typename?: "Mutation";
  resendVerificationEmail:
    | { __typename: "NotUpdated"; notUpdated: boolean }
    | { __typename: "UpdateOk"; ok: boolean };
};

export type ResetPasswordMutationVariables = Exact<{
  updatedData: ResetPasswordInput;
}>;

export type ResetPasswordMutation = {
  __typename?: "Mutation";
  resetPassword:
    | { __typename?: "NotUpdated" }
    | { __typename: "UpdateOk"; ok: boolean };
};

export type UnfollowMutationVariables = Exact<{
  profile: ProfileInput;
  updatedData: FollowInput;
}>;

export type UnfollowMutation = {
  __typename?: "Mutation";
  unfollow:
    | { __typename?: "InvalidInput" }
    | { __typename: "ProfileNotFound"; message: string }
    | { __typename: "UpdateOk"; ok: boolean };
};

export type ExportUserDataQueryVariables = Exact<{
  profile: ProfileInput;
}>;

export type ExportUserDataQuery = {
  __typename?: "Query";
  exportUserData:
    | { __typename: "ProfileNotFound"; message: string }
    | { __typename: "UserDataExport"; dataCsv: string };
};

export type GetProfilePrivateQueryVariables = Exact<{
  profile: ProfileInput;
}>;

export type GetProfilePrivateQuery = {
  __typename?: "Query";
  getProfilePrivate:
    | { __typename: "ProfileNoPermission"; message: string }
    | { __typename: "ProfileNotFound"; message: string }
    | {
        __typename: "ProfilePrivate";
        email: string;
        relapseStartDate?: Maybe<string>;
        followData: {
          __typename?: "FollowData";
          followingOthers: Array<{
            __typename?: "Follower";
            followDate: string;
            name: string;
            start_date: string;
            uid: string;
            avatar?: Maybe<{
              __typename?: "Avatar";
              clothes: number;
              face: number;
              gender: number;
              hairColor: number;
              hairStyle: number;
              skin: number;
              special: number;
            }>;
          }>;
        };
      };
};

export type GetProfilePublicQueryVariables = Exact<{
  profile: ProfileInput;
}>;

export type GetProfilePublicQuery = {
  __typename?: "Query";
  getProfilePublic:
    | { __typename: "ProfileNotFound"; message: string }
    | { __typename: "ProfileNotPublic"; message: string }
    | {
        __typename: "ProfilePublic";
        lastQuitDateReset?: Maybe<string>;
        amountChallenges: number;
        amountFollower: number;
        amountFollowing: number;
        countryCode: string;
        lang?: Maybe<Language>;
        os?: Maybe<string>;
        profileViews: number;
        public: boolean;
        starsLastUpdated: string;
        starsTotal: number;
        start_date: string;
        supporterMode?: Maybe<boolean>;
        timezone: string;
        uid: string;
        username: string;
        quitDate: string;
        highfives: { __typename?: "Highfives"; number: number };
        badges: Array<{
          __typename?: "Badge";
          category: string;
          state: number;
        }>;
        avatar?: Maybe<{
          __typename?: "Avatar";
          clothes: number;
          face: number;
          gender: number;
          hairColor: number;
          hairStyle: number;
          skin: number;
          special: number;
        }>;
      };
};

export type RegistrationFlagsQueryVariables = Exact<{
  profile: ProfileInput;
}>;

export type RegistrationFlagsQuery = {
  __typename?: "Query";
  getProfilePrivate:
    | { __typename: "ProfileNoPermission"; message: string }
    | { __typename: "ProfileNotFound"; message: string }
    | {
        __typename: "ProfilePrivate";
        registrationFlags: {
          __typename?: "RegistrationFlags";
          emailVerified: boolean;
        };
      };
};

export type SearchUserStartingWithQueryVariables = Exact<{
  search: SearchUsernameWithFiltersInput;
}>;

export type SearchUserStartingWithQuery = {
  __typename?: "Query";
  searchUserStartingWith: {
    __typename: "SearchUsernameResults";
    results: Array<{
      __typename: "SearchUsernameResult";
      public: boolean;
      start_date: string;
      supporterMode?: Maybe<boolean>;
      uid: string;
      username: string;
      avatar?: Maybe<{
        __typename?: "Avatar";
        clothes: number;
        face: number;
        hairColor: number;
        gender: number;
        hairStyle: number;
        skin: number;
        special: number;
      }>;
    }>;
  };
};

export const AddHighfivesDocument = gql`
  mutation AddHighfives(
    $profile: ProfileInput!
    $updatedData: AddHighfivesInput!
  ) {
    addHighfives(profile: $profile, updatedData: $updatedData) {
      ... on InvalidInput {
        __typename
        errors {
          constraints {
            message
            name
          }
          property
          value
        }
      }
      ... on ProfileNotFound {
        __typename
        message
      }
      ... on UpdateOk {
        __typename
        ok
      }
    }
  }
`;
export const AddProfileViewDocument = gql`
  mutation AddProfileView($profile: ProfileInput!) {
    addProfileView(profile: $profile) {
      ... on InvalidInput {
        __typename
        errors {
          property
          constraints {
            name
            message
          }
          value
        }
      }
      ... on ProfileNotFound {
        __typename
        message
      }
      ... on UpdateOk {
        __typename
        ok
      }
    }
  }
`;
export const AddVoucherDocument = gql`
  mutation addVoucher($profile: ProfileInput!, $updatedData: AddVoucherInput!) {
    addVoucher(profile: $profile, updatedData: $updatedData) {
      ... on UpdateOk {
        __typename
        ok
      }
      ... on InvalidInput {
        __typename
        errors {
          constraints {
            name
            message
          }
        }
      }
    }
  }
`;
export const DeleteUserDocument = gql`
  mutation DeleteUser($profile: ProfileInput!) {
    deleteUser(profile: $profile) {
      ... on InvalidInput {
        __typename
        errors {
          constraints {
            message
            name
          }
          property
          value
        }
      }
      ... on ProfileNotFound {
        __typename
        message
      }
      ... on UpdateOk {
        __typename
        ok
      }
    }
  }
`;
export const FollowDocument = gql`
  mutation Follow($profile: ProfileInput!, $updatedData: FollowInput!) {
    follow(profile: $profile, updatedData: $updatedData) {
      ... on InvalidInput {
        __typename
        errors {
          constraints {
            message
          }
        }
      }
      ... on ProfileNotFound {
        __typename
        message
      }
      ... on UpdateOk {
        __typename
        ok
      }
    }
  }
`;
export const RegisterDocument = gql`
  mutation Register($updatedData: RegisterWithUsernameInput!) {
    register(updatedData: $updatedData) {
      ... on AccessPayload {
        __typename
        uid
        accessToken
      }
      ... on RegistrationError {
        __typename
        error
      }
    }
  }
`;
export const RequestPasswordResetDocument = gql`
  mutation RequestPasswordReset($updatedData: RequestPasswordInput!) {
    requestPasswordReset(updatedData: $updatedData) {
      ... on NotUpdated {
        __typename
        notUpdated
      }
      ... on UpdateOk {
        __typename
        ok
      }
    }
  }
`;
export const ResendVerificationEmailDocument = gql`
  mutation ResendVerificationEmail($updatedData: ResendVerificationInput!) {
    resendVerificationEmail(updatedData: $updatedData) {
      ... on NotUpdated {
        __typename
        notUpdated
      }
      ... on UpdateOk {
        __typename
        ok
      }
    }
  }
`;
export const ResetPasswordDocument = gql`
  mutation ResetPassword($updatedData: ResetPasswordInput!) {
    resetPassword(updatedData: $updatedData) {
      ... on UpdateOk {
        __typename
        ok
      }
    }
  }
`;
export const UnfollowDocument = gql`
  mutation Unfollow($profile: ProfileInput!, $updatedData: FollowInput!) {
    unfollow(profile: $profile, updatedData: $updatedData) {
      ... on ProfileNotFound {
        __typename
        message
      }
      ... on UpdateOk {
        __typename
        ok
      }
    }
  }
`;
export const ExportUserDataDocument = gql`
  query ExportUserData($profile: ProfileInput!) {
    exportUserData(profile: $profile) {
      ... on ProfileNotFound {
        __typename
        message
      }
      ... on UserDataExport {
        __typename
        dataCsv
      }
    }
  }
`;
export const GetProfilePrivateDocument = gql`
  query GetProfilePrivate($profile: ProfileInput!) {
    getProfilePrivate(profile: $profile) {
      ... on ProfileNoPermission {
        __typename
        message
      }
      ... on ProfileNotFound {
        __typename
        message
      }
      ... on ProfilePrivate {
        __typename
        email
        relapseStartDate
        followData {
          followingOthers {
            avatar {
              clothes
              face
              gender
              hairColor
              hairStyle
              skin
              special
            }
            followDate
            name
            start_date
            uid
          }
        }
      }
    }
  }
`;
export const GetProfilePublicDocument = gql`
  query GetProfilePublic($profile: ProfileInput!) {
    getProfilePublic(profile: $profile) {
      ... on ProfileNotFound {
        __typename
        message
      }
      ... on ProfileNotPublic {
        __typename
        message
      }
      ... on ProfilePublic {
        __typename
        lastQuitDateReset
        amountChallenges
        amountFollower
        amountFollowing
        countryCode
        lang
        os
        profileViews
        public
        starsLastUpdated
        highfives {
          number
        }
        badges {
          category
          state
        }
        avatar {
          clothes
          face
          gender
          hairColor
          hairStyle
          skin
          special
        }
        starsTotal
        start_date
        supporterMode
        timezone
        uid
        username
        quitDate
      }
    }
  }
`;
export const RegistrationFlagsDocument = gql`
  query RegistrationFlags($profile: ProfileInput!) {
    getProfilePrivate(profile: $profile) {
      ... on ProfilePrivate {
        __typename
        registrationFlags {
          emailVerified
        }
      }
      ... on ProfileNoPermission {
        __typename
        message
      }
      ... on ProfileNotFound {
        __typename
        message
      }
    }
  }
`;
export const SearchUserStartingWithDocument = gql`
  query SearchUserStartingWith($search: SearchUsernameWithFiltersInput!) {
    searchUserStartingWith(search: $search) {
      __typename
      results {
        __typename
        avatar {
          clothes
          face
          hairColor
          gender
          hairStyle
          skin
          special
        }
        public
        start_date
        supporterMode
        uid
        username
      }
    }
  }
`;

export type SdkFunctionWrapper = <T>(
  action: (requestHeaders?: Record<string, string>) => Promise<T>,
  operationName: string,
  operationType?: string
) => Promise<T>;

const defaultWrapper: SdkFunctionWrapper = (
  action,
  _operationName,
  _operationType
) => action();

export function getSdk(
  client: GraphQLClient,
  withWrapper: SdkFunctionWrapper = defaultWrapper
) {
  return {
    AddHighfives(
      variables: AddHighfivesMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<AddHighfivesMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<AddHighfivesMutation>(
            AddHighfivesDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "AddHighfives",
        "mutation"
      );
    },
    AddProfileView(
      variables: AddProfileViewMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<AddProfileViewMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<AddProfileViewMutation>(
            AddProfileViewDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "AddProfileView",
        "mutation"
      );
    },
    addVoucher(
      variables: AddVoucherMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<AddVoucherMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<AddVoucherMutation>(AddVoucherDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "addVoucher",
        "mutation"
      );
    },
    DeleteUser(
      variables: DeleteUserMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<DeleteUserMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<DeleteUserMutation>(DeleteUserDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "DeleteUser",
        "mutation"
      );
    },
    Follow(
      variables: FollowMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<FollowMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<FollowMutation>(FollowDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "Follow",
        "mutation"
      );
    },
    Register(
      variables: RegisterMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<RegisterMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<RegisterMutation>(RegisterDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "Register",
        "mutation"
      );
    },
    RequestPasswordReset(
      variables: RequestPasswordResetMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<RequestPasswordResetMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<RequestPasswordResetMutation>(
            RequestPasswordResetDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "RequestPasswordReset",
        "mutation"
      );
    },
    ResendVerificationEmail(
      variables: ResendVerificationEmailMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<ResendVerificationEmailMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<ResendVerificationEmailMutation>(
            ResendVerificationEmailDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "ResendVerificationEmail",
        "mutation"
      );
    },
    ResetPassword(
      variables: ResetPasswordMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<ResetPasswordMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<ResetPasswordMutation>(
            ResetPasswordDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "ResetPassword",
        "mutation"
      );
    },
    Unfollow(
      variables: UnfollowMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<UnfollowMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UnfollowMutation>(UnfollowDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "Unfollow",
        "mutation"
      );
    },
    ExportUserData(
      variables: ExportUserDataQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<ExportUserDataQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<ExportUserDataQuery>(
            ExportUserDataDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "ExportUserData",
        "query"
      );
    },
    GetProfilePrivate(
      variables: GetProfilePrivateQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<GetProfilePrivateQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetProfilePrivateQuery>(
            GetProfilePrivateDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "GetProfilePrivate",
        "query"
      );
    },
    GetProfilePublic(
      variables: GetProfilePublicQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<GetProfilePublicQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetProfilePublicQuery>(
            GetProfilePublicDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "GetProfilePublic",
        "query"
      );
    },
    RegistrationFlags(
      variables: RegistrationFlagsQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<RegistrationFlagsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<RegistrationFlagsQuery>(
            RegistrationFlagsDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "RegistrationFlags",
        "query"
      );
    },
    SearchUserStartingWith(
      variables: SearchUserStartingWithQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<SearchUserStartingWithQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<SearchUserStartingWithQuery>(
            SearchUserStartingWithDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "SearchUserStartingWith",
        "query"
      );
    },
  };
}
export type Sdk = ReturnType<typeof getSdk>;
