import { gql } from "graphql-request";

export const ResetPassword = gql`
  mutation ResetPassword($updatedData: ResetPasswordInput!) {
    resetPassword(updatedData: $updatedData) {
      ... on UpdateOk {
        __typename
        ok
      }
    }
  }
`;
