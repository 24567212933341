import { t } from "i18n-js";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AlreadyLoggedIn from "../../components/AlreadyLoggedIn/AlreadyLoggedIn";
import { CTAButton } from "../../components/CTAButton/CTAButton";
import { CTASecondary } from "../../components/CTASecondary/CTASecondary";
import { CheckItem } from "../../components/CheckItem/CheckItem";
import { ErrorBox } from "../../components/ErrorBox/ErrorBox";
import { Input } from "../../components/Input/Input";
import { LoadingSpinner } from "../../components/LoadingSpinner/LoadingSpinner";
import { graphqlClient } from "../../gql/graphql-client";
import {
  AddVoucherMutation,
  FollowMutation,
  RegisterMutation,
} from "../../gql/graphql-types";
import { useAuth } from "../../hooks/useAuth";
import {
  useAddVoucherMutation,
  useDeleteUserMutation,
  useFollowMutation,
  useRegisterMutation,
} from "../../redux/SmokelessApiSlice";
import { Validators } from "../../utils/validators";
import "./RegisterContainer.scss";

export const RegisterContainer = (): JSX.Element => {
  const [usernameInput, setUsernameInput] = useState("");
  const [emailInput, setEmailInput] = useState("");
  const [password, setPasswordInput] = useState("");
  const [usernameError, setUsernameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [checkedItems, setCheckedItems] = useState(false);
  const [checkedSecurity, setCheckedSecurity] = useState(false);
  const [checkedTerms, setCheckedTerms] = useState(false);
  const [checkedHealth, setCheckedHealth] = useState(false);

  const { type, id } = useParams();

  const [loading, setLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    console.log(checkedSecurity && checkedTerms && checkedHealth);
    if (checkedSecurity && checkedTerms && checkedHealth) {
      setCheckedItems(true);
    } else if (!checkedSecurity || !checkedTerms || !checkedHealth) {
      setCheckedItems(false);
    }
  }, [checkedSecurity, checkedTerms, checkedHealth, checkedItems]);
  const [addVoucher] = useAddVoucherMutation();
  const [register, { isLoading }] = useRegisterMutation();
  const [deleteUser] = useDeleteUserMutation();
  const [follow] = useFollowMutation();
  const auth = useAuth();
  const registerUser = async () => {
    const requestBody = {
      username: usernameInput,
      email: emailInput,
      password,
      os: "web",
      language: navigator.language.split("-")[0],
    };
    try {
      setIsError(false);
      setLoading(true);
      const results = (await register({
        updatedData: requestBody,
      })) as { data: RegisterMutation; error: unknown };

      if (results && results.data) {
        if (results.data.register.__typename === "AccessPayload") {
          const token = results.data.register.accessToken;
          graphqlClient.setHeader("authorization", token);
          localStorage.setItem("uid", results.data.register.uid);
          localStorage.setItem("email", emailInput);
          navigate("/email-verification");
          const voucherData = localStorage.getItem("voucherData");
          if (type && type === "voucher" && id && voucherData) {
            const result = (await addVoucher({
              profile: { uid: results.data.register.uid || "" },
              updatedData: JSON.parse(voucherData) || "",
            })) as { data: AddVoucherMutation; error: unknown };
            if (
              result.data &&
              result.data?.addVoucher?.__typename === "UpdateOk"
            ) {
              navigate(`/voucher-sent/${id}`);
            }
          }
          if (type && type === "follow" && id) {
            const result = (await follow({
              profile: { uid: results.data.register.uid },
              updatedData: { followedUid: id },
            })) as { data: FollowMutation; error: unknown };
            if (result.data && result.data.follow.__typename === "UpdateOk") {
              //navigate(`/publicProfile/${id}`);
              navigate(`/overview`);
            }
          }
        } else if (results.data.register.__typename === "RegistrationError") {
          console.log("here", results.data.register.error);
          if (results.data.register.error === "EMAIL_ALREADY_USED") {
            setEmailError(t("EMAIL_FROM_IN_USE"));
          } else if (results.data.register.error === "USERNAME_ALREADY_USED") {
            setUsernameError(t("USERNAME_FROM_ALREADY_TAKEN"));
          }
        }
      }
      if (results.error) {
        console.log(results.error);
        setIsError(true);
      }
    } catch (error) {
      setIsError(true);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (emailInput.length > 0 && !Validators.email(emailInput)) {
      setEmailError(t("EMAIL_FORM_NOT_VALID"));
    } else {
      setEmailError("");
    }
    if (password.length > 0 && !Validators.password(password)) {
      setPasswordError(t("PASSWORD_FORM_TOO_SHORT"));
    } else {
      setPasswordError("");
    }
    if (usernameInput.length > 0 && !Validators.userName(usernameInput)) {
      if (usernameInput.length < 3) {
        setUsernameError(t("USERNAME_FORM_TOO_SHORT"));
      }
      if (usernameInput.length > 18) {
        setUsernameError(t("USERNAME_FORM_TOO_LONG"));
      }
    } else {
      setUsernameError("");
    }
  }, [emailInput, password, usernameInput]);
  const buttonDisabled =
    !emailInput ||
    emailError ||
    passwordError ||
    usernameError ||
    !usernameInput ||
    !checkedItems ||
    !password
      ? true
      : false;

  if (auth.isAuth) {
    return <AlreadyLoggedIn />;
  }
  return (
    <div className="register-container content-container-width component-container">
      <h1 className="title2">{t("SUPPORT_REGISTER_AND_LOGIN_TITLE")}</h1>
      <Input
        error={usernameError}
        placeholder={t("USERNAME_FORM")}
        type="text"
        inputText={usernameInput}
        onChange={(input: string) => {
          setUsernameError("");
          setUsernameInput(input);
        }}
      />
      <Input
        error={emailError}
        placeholder={t("EMAIL_FORM")}
        type="email"
        inputText={emailInput}
        onChange={(input: string) => setEmailInput(input)}
      />
      <Input
        error={passwordError}
        placeholder={t("PASSWORD_FORM")}
        type="password"
        inputText={password}
        onChange={(input: string) => setPasswordInput(input)}
      />
      <p className="regular first-text">{t("CONSENT_TEXT_1_1")}</p>
      <p className="regular first-text">{t("CONSENT_TEXT_1_2")}</p>
      <CheckItem
        checked={(is) => setCheckedSecurity(is)}
        text={t("CONSENT_DATA_SECURITY")}
        infoIcon
        infoIconLink="https://static.smokeless.world/de/privacy.html"
      />
      <CheckItem
        checked={(is) => setCheckedTerms(is)}
        text={t("CONSENT_TERMS")}
        infoIcon
        infoIconLink="https://static.smokeless.world/de/terms.html"
      />
      <CheckItem
        checked={(is) => setCheckedHealth(is)}
        text={t("CONSENT_HEALTH_DATA")}
        infoIcon
        infoIconLink="https://static.smokeless.world/de/health_data.html"
      />
      <p className="title5 text-2">{t("CONSENT_TEXT_2")}</p>
      {isError && <ErrorBox title={t("FAIL_NO_INTERNET_TITLE")} />}
      {loading ? (
        <LoadingSpinner />
      ) : (
        <CTAButton
          onClick={() => void registerUser()}
          title={t("CTA_CREATE_NEW_ACCOUNT")}
          className="cta-button"
          disabled={buttonDisabled}
          loading={isLoading}
        />
      )}
      <CTASecondary
        onClick={() => navigate("/login/" + type + "/" + id)}
        title={t("CTA_LOGIN_TITLE")}
        buttonText={t("CTA_LOGIN")}
      />
    </div>
  );
};
