import { Dispatch, SetStateAction, useEffect } from "react";

export const useHideBurgerMenu = (
  setDisplayBurgerMenu: Dispatch<SetStateAction<boolean>>
) => {
  useEffect(() => {
    setDisplayBurgerMenu(false);
    return () => {
      setDisplayBurgerMenu(true);
    };
  }, [setDisplayBurgerMenu]);
};
