import * as $ from "jquery";
import "./highfive.css";

$(document).ready(function () {
  $(document).on("click", ".HIGHFIVE_GENERAL", function () {
    window.setTimeout(function () {
      $(".transform").toggleClass("transform-active");
      $(".transform2").toggleClass("transform2-active");
    }, 200);
    $(".transform").toggleClass("transform-active");
    $(".transform2").toggleClass("transform2-active");
  });
  // console.log("GET BIG");
  // console.log($(".HIGHFIVE_GENERAL"));
  // $(".HIGHFIVE_GENERAL").click(function () {
  //   window.setTimeout(function () {
  //     $(".transform").toggleClass("transform-active");
  //     $(".transform2").toggleClass("transform2-active");
  //   }, 200);
  //   $(".transform").toggleClass("transform-active");
  //   $(".transform2").toggleClass("transform2-active");
  // });
});
