import { t } from "i18n-js";
import { loadAsset } from "../../utils/loadAsset";
import { ProfileAchievementCounter } from "../ProfileAchivementCounter/ProfileArchievementCounter";
import "./ProfileAchievements.scss";

type Props = {
  stars: number;
  challenges: number;
  highfives: number | string;
  supporters: number;
  supportOthers: number;
  awardsTotal: number;
};

export const ProfileAchievements = (props: Props): JSX.Element => {
  const {
    stars,
    challenges,
    highfives,
    supporters,
    supportOthers,
    awardsTotal,
  } = props;
  const achievementsList = [
    {
      title: t("STARS"),
      number: stars,
      icon: loadAsset("overview_stars.svg"),
    },
    {
      title: t("AWARDS"),
      number: awardsTotal,
      icon: loadAsset("overview_awards.svg"),
    },
    {
      title: t("CHALLENGES"),
      number: challenges,
      icon: loadAsset("overview_challenges.svg"),
    },
    {
      title: t("HIGHFIVES"),
      number: highfives > 9999 ? "9.999+" : highfives,
      icon: loadAsset("overview_high_fives.svg"),
    },
    {
      title: t("SUPPORTERS"),
      number: supporters,
      icon: loadAsset("overview_supporter.svg"),
    },
    {
      title: t("SUPPORT_OTHERS_COUNTER_TITLE"),
      number: supportOthers,
      icon: loadAsset("overview_supporting.svg"),
    },
  ];
  return (
    <div className="profile-achievements-container flex-center">
      <div className="profile-achievements-main">
        {achievementsList.map((achievement, idx) => (
          <ProfileAchievementCounter
            key={idx}
            title={achievement.title}
            number={achievement.number}
            icon={achievement.icon}
          />
        ))}
      </div>
    </div>
  );
};
