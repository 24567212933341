import html2canvas from "html2canvas";
import * as $ from "jquery";

const mountCanvas = () => {
  const btnGeneral = document.querySelector(".HIGHFIVE_GENERAL");
  const btn = document.querySelector(".HAND_FRONT");
  let ctx;

  if (
    btn &&
    !document.getElementById("highfives-canvas").classList.contains("mounted")
  ) {
    html2canvas(btn).then((canvas) => {
      ctx = canvas.getContext("2d");
      ctx.fillStyle = "rgba(255,255,255,1)";
      ctx.fillRect(50, 50, 75, 50);
      createParticleCanvas();

      const reductionFactor = 99;
      btnGeneral.addEventListener("click", (e) => {
        // Get the color data for our button
        const width = btn.offsetWidth;
        const height = btn.offsetHeight;

        // Keep track of how many times we've iterated (in order to reduce
        // the total number of particles create)
        let count = 0;
        window.setTimeout(function () {
          // Go through every location of our button and create a particle
          for (let localX = 20; localX < width + 20; localX++) {
            for (let localY = 20; localY < height + 20; localY++) {
              if (count % reductionFactor === 0) {
                const rgbaColorArr = [255, 255, 255]; //colorData.slice(index, index + 4);

                const bcr = btn.getBoundingClientRect();

                const globalX = bcr.left + localX;
                // let globalY = window.scrollY + bcr.top + localY;
                const globalY = window.innerHeight;
                const offset = 60;
                // console.log(globalX, globalY + offset, rgbaColorArr);
                createParticleAtPoint(globalX, globalY + offset, rgbaColorArr);
              }
              count++;
            }
          }
          //console.log("anmateion creatend");
        }, 100);
      });
    });
  } else {
  }

  function easeOutQuart(t) {
    // eslint-disable-next-line
    return 1 - --t * t * t * t;
  }

  /* An "exploding" particle effect that uses circles */
  const ExplodingParticle = function () {
    // Set how long we want our particle to animate for
    this.animationDuration = 5000; // in ms
    const max = 100;
    // Set the speed for our particle
    this.speed = {
      x: -0.5 * max + Math.random() * max,
      y: -0.5 * max + Math.random() * max,
      start: [],
    };
    this.speed.start.x = this.speed.x;
    this.speed.start.y = this.speed.y;

    // Size our particle
    this.radius = 5 + Math.random() * 15;

    // Set a max time to live for our particle
    this.life = this.animationDuration;
    this.remainingLife = this.life;

    // This function will be called by our animation logic later on
    this.draw = (ctx) => {
      const p = this;

      if (this.remainingLife > 0 && this.radius > 0) {
        // Draw a circle at the current location
        ctx.beginPath();
        ctx.arc(p.startX, p.startY, p.radius, 0, Math.PI * 2);

        const percent =
          1 -
          easeOutQuart((Date.now() - this.startTime) / this.animationDuration);

        const particleOpacity = percent;

        ctx.fillStyle =
          "rgba(" +
          this.rgbArray[0] +
          "," +
          this.rgbArray[1] +
          "," +
          this.rgbArray[2] +
          ", " +
          particleOpacity +
          ")";
        ctx.fill();

        // Update the particle's location and life
        p.remainingLife--;
        //p.radius -= 0.25;
        p.startX += p.speed.x;
        p.startY += p.speed.y;
        p.speed.x = this.speed.start.x * percent;
        p.speed.y = this.speed.start.y * percent;
      }
    };
  };

  let particles = [];

  function createParticleAtPoint(x, y, colorData) {
    const particle = new ExplodingParticle();

    particle.rgbArray = colorData;
    //particle.rgbArray = new Uint8ClampedArray(100,100,100,100);
    particle.startX = x;
    particle.startY = y;
    particle.startTime = Date.now();

    particles.push(particle);
  }

  let particleCanvas, particleCtx;

  function createParticleCanvas() {
    // Create our canvas

    particleCanvas = document.getElementById("highfives-canvas");
    particleCanvas.classList.add("mounted");
    particleCtx = particleCanvas.getContext("2d");
    //particleCanvas.style = "background:red"

    particleCtx.globalAlpha = 1;
    // Size our canvas
    particleCanvas.width = window.innerWidth;
    particleCanvas.height = window.innerHeight * 2;

    const handPosition = $(".HAND_FRONT").offset().top;

    // Position out canvas
    particleCanvas.style.position = "absolute";
    particleCanvas.style.top = handPosition + "px" - window.innerHeight;
    particleCanvas.style.left = "0px";

    // Make sure it's on top of other elements
    particleCanvas.style.zIndex = "1001";

    // Make sure other elements under it are clickable
    particleCanvas.style.pointerEvents = "none";

    // Add our canvas to the page
    //document.getElementById("high").appendChild(particleCanvas);
    if (document && btn) {
      // document.getElementsByClassName("Highfive_box")[0].appendChild(particleCanvas);
    }
    setInterval(updateCanvasPosition, 1000);
  }

  function updateCanvasPosition() {
    particleCanvas = $("canvas")[0];
    const handFront = $(".HAND_FRONT");
    if (particleCanvas && handFront && handFront.offset()) {
      const canvasHeight = window.innerHeight * 2;
      particleCanvas.width = window.innerWidth;
      particleCanvas.height = canvasHeight;

      const handPosition = handFront.offset().top;

      particleCanvas.style.top = handPosition - canvasHeight / 2 + "px";
    }
  }

  function update() {
    // Clear out the old particles
    if (typeof particleCtx !== "undefined") {
      particleCtx.clearRect(0, 0, window.innerWidth, window.innerHeight + 1300);
    }

    // Draw all of our particles in their new location
    for (let i = 0; i < particles.length; i++) {
      particles[i].draw(particleCtx);
      // Simple way to clean up if he last particle is done animating
      if (i === particles.length - 1) {
        const percent =
          (Date.now() - particles[i].startTime) /
          particles[i].animationDuration;

        if (percent >= 1) {
          particles = [];
        }
      }
    }

    // Animate performantly
    window.requestAnimationFrame(update);
  }
  window.requestAnimationFrame(update);
};

$(document).ready(function () {
  setInterval(() => {
    mountCanvas();
  }, 500);
});
