import { gql } from "graphql-request";

export const GetPublicProfile = gql`
  query GetProfilePublic($profile: ProfileInput!) {
    getProfilePublic(profile: $profile) {
      ... on ProfileNotFound {
        __typename
        message
      }
      ... on ProfileNotPublic {
        __typename
        message
      }
      ... on ProfilePublic {
        __typename
        lastQuitDateReset
        amountChallenges
        amountFollower
        amountFollowing
        countryCode
        lang
        os
        profileViews
        public
        starsLastUpdated
        highfives {
          number
        }
        badges {
          category
          state
        }
        avatar {
          clothes
          face
          gender
          hairColor
          hairStyle
          skin
          special
        }
        starsTotal
        start_date
        supporterMode
        timezone
        uid
        username
        quitDate
      }
    }
  }
`;
