import { t } from "i18n-js";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import {
  FailureMessage,
  FailureType,
} from "../../components/FailureMessage/FailureMessage";
import "./EditEmailConfirmationScreen.scss";

type Props = {
  setDisplayBurgerMenu: Dispatch<SetStateAction<boolean>>;
};

export const EditEmailConfirmationScreen = (props: Props) => {
  const { setDisplayBurgerMenu } = props;
  const [apiResponse, setApiResponse] = useState<boolean>();
  const [searchParams] = useSearchParams();
  useEffect(() => {
    const fetchApiResponse = async () => {
      const res = await fetch(
        `${window.app.config.API}change_email?token=${searchParams.get(
          "token"
        )}`
      );
      setApiResponse(res.ok);
    };
    setDisplayBurgerMenu(false);
    fetchApiResponse().catch(console.error);
    return () => {
      setDisplayBurgerMenu(true);
    };
  }, [searchParams, setDisplayBurgerMenu]);
  return (
    <div className="edit-email-confirm-container content-container-width component-container">
      {apiResponse ? (
        <div>
          <h1 className="title2">{t("EDIT_EMAIL_CONFIRMATION_WEB_TITLE")}</h1>
          <p className="text-main regular">
            {t("EDIT_EMAIL_CONFIRMATION_WEB_TEXT")}
          </p>
        </div>
      ) : (
        <FailureMessage type={FailureType.InvalidToken} />
      )}
    </div>
  );
};
