import { t } from "i18n-js";
import { useNavigate } from "react-router-dom";
import { Voucher } from "../../../components/Voucher/Voucher";
import "./VoucherSection.scss";

type Props = {
  username: string;
  userId: string;
};

export const VoucherSection = (props: Props): JSX.Element => {
  const navigate = useNavigate();
  const navigateHandler = () => {
    navigate(`/create-voucher/${props.username}/${props.userId}`);
  };
  return (
    <div className="gradient_blue_dark">
      <div className="voucher-section-container content-container-width-no-height">
        <Voucher
          onClick={() => navigateHandler()}
          text={t("PUBLIC_PROFILE_VOUCHER_BUTTON_TEXT", {
            username: props.username,
          })}
          subtitle={t("PUBLIC_PROFILE_VOUCHER_BUTTON_SUBTITLE")}
          title={t("PUBLIC_PROFILE_VOUCHER_BUTTON_TITLE")}
          uid={props.userId}
        />
      </div>
    </div>
  );
};
