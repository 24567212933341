import I18n from "i18n-js";
import moment from "moment";
import "moment/locale/de";
import de from "./translations/de.json";
import en from "./translations/en.json";

I18n.locale = getBrowserLocales()[0].languageCode;
I18n.fallbacks = true;
I18n.translations = { en, de };

export const currentLocale: string = I18n.locale;
export const { languageTag, languageCode, countryCode } =
  getBrowserLocales()[0];
console.log(languageTag, languageCode, countryCode);

if (
  moment.locales().includes(languageTag) ||
  moment.locales().includes(languageCode)
) {
  moment.locale(languageTag);
} else {
  moment.locale("en-US");
}

function getBrowserLocales() {
  const locales =
    navigator.languages === undefined
      ? [navigator.language]
      : navigator.languages;

  if (!locales) {
    return [{ languageTag: "en", languageCode: "en", countryCode: "en" }];
  }
  return locales.map((languageTag) => {
    const [languageCode, countryCode] = languageTag?.split("-") ?? [];
    return {
      languageTag,
      languageCode,
      countryCode,
    };
  });
}
