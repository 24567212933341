import { gql } from "graphql-request";

export const DeleteUser = gql`
  mutation DeleteUser($profile: ProfileInput!) {
    deleteUser(profile: $profile) {
      ... on InvalidInput {
        __typename
        errors {
          constraints {
            message
            name
          }
          property
          value
        }
      }
      ... on ProfileNotFound {
        __typename
        message
      }
      ... on UpdateOk {
        __typename
        ok
      }
    }
  }
`;
