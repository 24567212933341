import { loadAsset } from "../../utils/loadAsset";
import "./Voucher.scss";

type Props = {
  subtitle?: string;
  title?: string;
  text?: string;
  onClick?: () => void;
  uid?: string;
};

export const Voucher = (props: Props): JSX.Element => {
  const { subtitle, title, text, onClick } = props;

  const isMe = props.uid === localStorage.getItem("uid");
  const isMeStyle = { cursor: "not-allowed", opacity: 0.3 };
  return (
    <button
      onClick={() => (onClick && !isMe ? onClick() : console.log("voucher"))}
      className="voucher-container gradient_blue"
      style={isMe ? isMeStyle : {}}
    >
      <div className="voucher-inner ">
        <div className="voucher-header">
          <div className="header-text">
            <h2 className="header-text__subtitle small2">{subtitle}</h2>
            <h1 className="header-text__title title3">{title}</h1>
          </div>
          <div className="header-logo">
            <img
              {...loadAsset("voucher_logo_smokeless.svg")}
              alt="voucher-logo"
            />
          </div>
        </div>
        <div className="voucher-main">
          <p className="voucher-text small1">{text}</p>
          <img
            className="voucher-click-here"
            {...loadAsset("high_five_click_here.svg")}
            alt="click-here"
          />
        </div>
        <div className="voucher-footer">
          <div className="voucher-separator-container">
            <img
              alt="voucher-separator"
              className="voucher-separator-container__left"
              {...loadAsset("voucher_sidecut_left_v2.svg")}
            />
            <img
              alt="voucher-separator"
              className="voucher-separator-container__separator"
              {...loadAsset("voucher_stripe.svg")}
            />
            <img
              alt="voucher-separator"
              {...loadAsset("voucher_sidecut_right_v2.svg")}
            />
          </div>
          <div className="voucher-corner-fold">
            <img
              alt="voucher-corner"
              className="voucher-corner-fold__img"
              {...loadAsset("voucher_paper_corner_v3.png")}
            />
            <div className="voucher-fold-color" />
          </div>
        </div>
      </div>
    </button>
  );
};
