import { BadgeCategoryCard } from "../BadgeCategoryCard/BadgeCategoryCard";
import "./BadgeCategoryList.scss";

interface Cards {
  [key: string]: { state: number };
}

type Props = {
  cards: Cards;
};

export const BadgeCategoryList = (props: Props): JSX.Element => {
  return (
    <div className="badge-category-list">
      {Object.keys(props.cards).map((cat, index) => {
        return (
          <BadgeCategoryCard
            key={index}
            category={cat}
            state={props.cards[cat].state}
          />
        );
      })}
    </div>
  );
};
