import { gql } from "graphql-request";

export const ExportUserData = gql`
  query ExportUserData($profile: ProfileInput!) {
    exportUserData(profile: $profile) {
      ... on ProfileNotFound {
        __typename
        message
      }
      ... on UserDataExport {
        __typename
        dataCsv
      }
    }
  }
`;
