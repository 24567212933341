export const loadAsset = (name: string) => {
  if (name.includes(".png")) {
    return {
      src: "/assets/" + name,
      srcSet: `/assets/${name} 1x, /assets/${name.replace(
        ".png",
        "@2x.png"
      )} 2x, /assets/${name.replace(".png", "@3x.png")} 3x`,
    };
  }
  return { src: "/assets/" + name };
};
