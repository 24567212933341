import { gql } from "graphql-request";

export const Follow = gql`
  mutation Follow($profile: ProfileInput!, $updatedData: FollowInput!) {
    follow(profile: $profile, updatedData: $updatedData) {
      ... on InvalidInput {
        __typename
        errors {
          constraints {
            message
          }
        }
      }
      ... on ProfileNotFound {
        __typename
        message
      }
      ... on UpdateOk {
        __typename
        ok
      }
    }
  }
`;
