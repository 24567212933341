import { t } from "i18n-js";
import "./EmailVerifiedWebScreen.scss";

export const EmailVerifiedAppScreen = (): JSX.Element => {
  return (
    <div>
      <h1 className="title2">
        {t("EMAIL_VERIFICATION_CONFIRMATION_APP_TITLE")}
      </h1>
      <p className="text-main regular">
        {t("EMAIL_VERIFICATION_CONFIRMATION_APP_TEXT")}
      </p>
    </div>
  );
};
