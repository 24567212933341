import { t } from "i18n-js";
import { useNavigate } from "react-router-dom";
import { CTAButton } from "../CTAButton/CTAButton";

export default function AlreadyLoggedIn() {
  const navigation = useNavigate();
  return (
    <div className="p-20 d-column margin-component content-container-width">
      <h1 className="title2">{t("ALREADY_LOGGED_IN_TITLE")}</h1>

      <CTAButton
        title={t("CTA_SUPPORT_MANAGEMENT")}
        onClick={() => {
          navigation("/overview");
        }}
      />
    </div>
  );
}
