import { t } from "i18n-js";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CTAButton } from "../../components/CTAButton/CTAButton";
import { Input } from "../../components/Input/Input";
import { LoadingSpinner } from "../../components/LoadingSpinner/LoadingSpinner";
import { RequestPasswordResetMutation } from "../../gql/graphql-types";
import { useRequestPasswordResetMutation } from "../../redux/SmokelessApiSlice";
import { Validators } from "../../utils/validators";
import "./ForgotPassword.scss";

export const ForgotPasswordContainer = (): JSX.Element => {
  const [emailInput, setEmailInput] = useState("");
  const navigate = useNavigate();
  const [emailError, setEmailError] = useState("");
  const [requestPasswordReset, { isLoading }] =
    useRequestPasswordResetMutation();
  const resetPassword = async () => {
    const result = (await requestPasswordReset({
      updatedData: { email: emailInput },
    })) as { data?: RequestPasswordResetMutation; error?: unknown };
    if (result.data?.requestPasswordReset.__typename === "UpdateOk") {
      navigate(`/forgotPasswordConfirm`);
    } else {
      if (result?.data?.requestPasswordReset?.__typename === "NotUpdated") {
        setEmailError(t("EMAIL_FORM_NOT_VALID"));
      } else {
        setEmailError(t("FAIL_NO_CONNECTION"));
      }
    }
  };

  useEffect(() => {
    if (emailInput.length > 0 && !Validators.email(emailInput)) {
      setEmailError(t("EMAIL_FORM_NOT_VALID"));
    } else {
      setEmailError("");
    }
  }, [emailInput]);
  const buttonDisabled = emailInput === "" || emailError !== "";
  return (
    <div className="login-container content-container-width">
      <h1 className="title2 login-title">{t("RETRIVE_PASSWORD_TITLE")}</h1>
      <Input
        placeholder={t("EMAIL_FORM")}
        type="text"
        inputText={emailInput}
        onChange={(input: string) => setEmailInput(input)}
        error={emailError}
      />

      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <CTAButton
          onClick={() => void resetPassword()}
          title={t("CTA_SEND_NEW_PASSWORD")}
          className="cta-button"
          disabled={buttonDisabled}
        />
      )}
    </div>
  );
};
