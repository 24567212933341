import { t } from "i18n-js";
import { useNavigate } from "react-router-dom";
import { CTAButton } from "../../components/CTAButton/CTAButton";
import { loadAsset } from "../../utils/loadAsset";
import { QuitSmokingSection } from "../ProfileContainer/QuitSmokingSection/QuitSmokingSection";
import "./StartContainer.scss";

export const StartContainer = (): JSX.Element => {
  const navigate = useNavigate();
  return (
    <>
      <div className="start-container content-container-width-no-height">
        <div className="logo-section">
          <img
            className="start-logo"
            src={loadAsset("support_others_start.svg").src}
            alt="smokeless-icon"
          />
        </div>
        <p className="start-text-main regular">
          {t("SUPPORTER_APP_START_TEXT")}
        </p>
        <CTAButton
          title={t("CTA_START")}
          onClick={() => navigate("/overview")}
        />
      </div>
      <div className="quit-smoking-section">
        <QuitSmokingSection />
      </div>
    </>
  );
};
