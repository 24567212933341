import { t } from "i18n-js";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { Avatar as AvatarType } from "../../gql/graphql-types";
import { loadAsset } from "../../utils/loadAsset";
import { Avatar } from "../Avatar/Avatar";
import "./UserCard.scss";

type Props = {
  username: string;
  uid: string;
  gender?: number;
  avatar?: AvatarType;
  startDate: string;
};

export const UserCard = (props: Props): JSX.Element => {
  const navigate = useNavigate();
  const daysLeft = 90 - moment().diff(moment(props.startDate), "days");

  console.log("gender", props.gender);
  const avatarString =
    props.gender === undefined
      ? "avatar_placeholder"
      : props.gender === 1
      ? "avatar_male"
      : "avatar_female";
  const avatarAsset = loadAsset(avatarString + ".svg");
  return (
    <div
      className="user-card-container gradient_blue"
      onClick={() => navigate(`/publicprofile/${props.uid}`)}
    >
      <div
        className="photo-section"
        style={{
          paddingBottom: props.avatar ? 15 : 0,
          paddingTop: !props.avatar ? 10 : 0,
        }}
      >
        {props.avatar ? (
          <Avatar height={48} {...props.avatar} />
        ) : (
          <img {...avatarAsset} alt="avatar" />
        )}
      </div>
      <div className="text-section">
        <span className="text-section__days small1">
          {daysLeft < 0
            ? t("DAYS_SINCE_LONG", { days: -daysLeft })
            : t("DAYS_LEFT_LONG", { days: daysLeft })}
        </span>
        <h2 className="text-section__username title4">{props.username}</h2>
      </div>
    </div>
  );
};
