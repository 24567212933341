import { t } from "i18n-js";
import { useNavigate } from "react-router-dom";
import { CTAButton } from "../../components/CTAButton/CTAButton";
import { LoadingSpinner } from "../../components/LoadingSpinner/LoadingSpinner";
import { SectionTitle } from "../../components/SectionTitle/SectionTitle";
import { UserCard } from "../../components/UserCard/UserCard";
import { useAuth } from "../../hooks/useAuth";
import { useGetPrivateProfileQuery } from "../../redux/SmokelessApiSlice";
import "./OverviewContainer.scss";

export const OverviewContainer = (): JSX.Element => {
  const navigate = useNavigate();
  const { isAuth } = useAuth();
  const { data, error, isLoading } = useGetPrivateProfileQuery(
    {
      profile: { uid: localStorage.getItem("uid") || "" },
    },
    {
      skip: Boolean(!localStorage.getItem("uid")),
      refetchOnMountOrArgChange: true,
    }
  );

  if (error) {
    console.error(error);
  }
  if (isLoading) {
    return (
      <div className="profile-loading">
        <LoadingSpinner />
      </div>
    );
  }

  let userCards;
  if (
    data &&
    data.getProfilePrivate &&
    data.getProfilePrivate.__typename === "ProfilePrivate" &&
    data.getProfilePrivate?.followData?.followingOthers
  ) {
    const users = data.getProfilePrivate.followData.followingOthers;
    userCards = users.map((user) => (
      <UserCard
        key={user.uid}
        startDate={user.start_date}
        gender={user.avatar.gender}
        uid={user.uid}
        username={user.name}
        avatar={user.avatar}
      />
    ));
  }

  return (
    <div className="overview-container content-container-width component-container">
      <SectionTitle
        title={t("SUPPORT_OTHERS_SECTION_TITLE")}
        subtitle={t("SUPPORT_OTHERS_SECTION_SUBTITLE")}
      />
      <div className="overview-separator" />
      <p className="regular text-overview">
        {t("SUPPORT_OTHERS_SECTION_TEXT")}
      </p>
      {/* {isAuth && isLoading && <LoadingSpinner />} */}
      {isAuth === true &&
      data?.getProfilePrivate?.__typename === "ProfilePrivate" ? (
        <div className="user-card-overview-container">{userCards}</div>
      ) : (
        <div className="not-auth-empty" />
      )}
      <CTAButton
        buttonText={t("CTA_ADD_SUPPORT_USER_TITLE")}
        title={t("CTA_ADD_SUPPORT_USER")}
        onClick={() => navigate("/search")}
      />
    </div>
  );
};
