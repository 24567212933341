import { t } from "i18n-js";
import { Dispatch, SetStateAction } from "react";
import { useHideBurgerMenu } from "../../hooks/useHideBurgerMenu";
import "./CreateNewPasswordFailureScreen.scss";

type Props = {
  setDisplayBurgerMenu: Dispatch<SetStateAction<boolean>>;
};
export const CreateNewPasswordFailureScreen = (props: Props) => {
  const { setDisplayBurgerMenu } = props;
  useHideBurgerMenu(setDisplayBurgerMenu);
  return (
    <div className="create-new-password-fail-container content-container-width component-container">
      <h1 className="title2">{t("NEW_PASSWORD_WEB_FAILURE_TITLE")}</h1>
      <p className="text-main regular">{t("NEW_PASSWORD_WEB_FAILURE_TEXT")}</p>
    </div>
  );
};
