const createValidators = () => {
  const email = (input: string): boolean => {
    const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(input);
  };
  const password = (input: string): boolean => {
    return input.length >= 8;
  };

  const userName = (username: string) => {
    const regex = /^[a-zA-Z0-9_-]+$/g;
    const valid =
      regex.test(username) && username.length >= 3 && username.length <= 18;
    return valid;
  };

  return { email, password, userName };
};

const Validators = createValidators();
export { Validators };
