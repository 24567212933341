import { t } from "i18n-js";
import "./FailureMessage.scss";

export enum FailureType {
  InvalidInput = "INVALID_INPUT",
  InvalidToken = "INVALID_TOKEN",
}

type Props = {
  message?: string;
  type: FailureType;
};

export const FailureMessage = (props: Props): JSX.Element => {
  const { message, type } = props;
  switch (type) {
    case FailureType.InvalidInput:
      return (
        <div className="failure-message">
          <p className="failure-message__text small2">{message}</p>
        </div>
      );
    case FailureType.InvalidToken:
      return (
        <div>
          <h1 className="title2">{t("LINK_EXPIRED_TITLE")}</h1>
          <p className="text-main regular">{t("LINK_EXPIRED_TEXT")}</p>
        </div>
      );
  }
};
