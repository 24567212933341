import { gql } from "graphql-request";

export const ResendVerificationEmail = gql`
  mutation ResendVerificationEmail($updatedData: ResendVerificationInput!) {
    resendVerificationEmail(updatedData: $updatedData) {
      ... on NotUpdated {
        __typename
        notUpdated
      }
      ... on UpdateOk {
        __typename
        ok
      }
    }
  }
`;
