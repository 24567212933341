import { t } from "i18n-js";
import { useNavigate } from "react-router-dom";
import { CTAButton } from "../../components/CTAButton/CTAButton";
import "./EmailVerifiedWebScreen.scss";

export const EmailVerifiedWebScreen = (): JSX.Element => {
  const navigate = useNavigate();
  return (
    <div>
      <div>
        <h1 className="title2">
          {t("EMAIL_VERIFICATION_CONFIRMATION_WEB_TITLE")}
        </h1>
        <p className="text-main regular">
          {t("EMAIL_VERIFICATION_CONFIRMATION_WEB_TEXT")}
        </p>
      </div>
      <div className="email-verified-cta-wrapper">
        <CTAButton title={t("CTA_LOGIN")} onClick={() => navigate("/login")} />
      </div>
    </div>
  );
};
