import { gql } from "graphql-request";

export const RegistrationFlags = gql`
  query RegistrationFlags($profile: ProfileInput!) {
    getProfilePrivate(profile: $profile) {
      ... on ProfilePrivate {
        __typename
        registrationFlags {
          emailVerified
        }
      }
      ... on ProfileNoPermission {
        __typename
        message
      }
      ... on ProfileNotFound {
        __typename
        message
      }
    }
  }
`;
