import { gql } from "graphql-request";

export const SearchUserStartingWith = gql`
  query SearchUserStartingWith($search: SearchUsernameWithFiltersInput!) {
    searchUserStartingWith(search: $search) {
      __typename
      results {
        __typename
        avatar {
          clothes
          face
          hairColor
          gender
          hairStyle
          skin
          special
        }
        public
        start_date
        supporterMode
        uid
        username
      }
    }
  }
`;
