import { t } from "i18n-js";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CTAButton } from "../../components/CTAButton/CTAButton";
import { CheckItem } from "../../components/CheckItem/CheckItem";
import { ErrorBox } from "../../components/ErrorBox/ErrorBox";
import { Input } from "../../components/Input/Input";
import { LoadingSpinner } from "../../components/LoadingSpinner/LoadingSpinner";
import { SectionTitle } from "../../components/SectionTitle/SectionTitle";
import { Voucher } from "../../components/Voucher/Voucher";
import { AddVoucherMutation, RedeemType } from "../../gql/graphql-types";
import { useAuth } from "../../hooks/useAuth";
import { useAddVoucherMutation } from "../../redux/SmokelessApiSlice";
import "./CreateVoucherContainer.scss";

export const CreateVoucherContainer = (): JSX.Element => {
  const [titleInput, setTitleInput] = useState("");
  const [textInput, setTextInput] = useState("");
  const [selectedItem, setSelectedItem] = useState(1);
  const [isError, setIsError] = useState(false);
  const params = useParams();
  const { username, id } = params;
  const [addVoucher, { isLoading }] = useAddVoucherMutation();
  const navigate = useNavigate();
  const isAuth = useAuth().isAuth;
  const myId = localStorage.getItem("uid");
  const sendVoucher = async () => {
    setIsError(false);
    if (id) {
      const redeemType =
        selectedItem === 1
          ? RedeemType.Days90
          : selectedItem === 2
          ? RedeemType.Years1
          : RedeemType.Years5;
      const voucherBody = {
        redeemType: redeemType,
        text: textInput,
        title: titleInput,
        uidRecipient: id,
      };
      if (isAuth) {
        const result = (await addVoucher({
          profile: { uid: myId || "" },
          updatedData: voucherBody,
        })) as { data?: AddVoucherMutation; error?: unknown };
        if (result.data?.addVoucher.__typename === "UpdateOk") {
          navigate(`/voucher-sent/${id}`);
        }
        if (result.error) {
          console.log(result.error);
          setIsError(true);
        }
      } else {
        navigate(`/register/voucher/${id}`);
        localStorage.setItem("voucherData", JSON.stringify(voucherBody));
      }
    }
  };
  return (
    <div className="create-voucher-container content-container-width">
      <h1 className="title2">
        {t("CREATE_VOUCHER_TITLE", { username: username })}
      </h1>
      <Input
        placeholder={t("CREATE_VOUCHER_FORM_PLACEHOLDER_TITLE")}
        inputText={titleInput}
        type={"text"}
        limit={50}
        onChange={(i) => setTitleInput(i)}
      />
      <div className="text-area-container">
        <textarea
          className="text-area-container__text-area regular"
          placeholder={t("CREATE_VOUCHER_FORM_PLACEHOLDER_MESSAGE", {
            username: username,
          })}
          value={textInput}
          maxLength={1000}
          onChange={(e) => setTextInput(e.target.value)}
        />
        {1000 - textInput.length <= 20 && (
          <div className="text-area-container__input-max-length small2">
            {t("MISSION_GOALS_CREATE_FORM_ALERT", {
              amount: `${1000 - textInput.length}`,
            })}
          </div>
        )}
      </div>
      <div className="check-items">
        <CheckItem
          checked={() => setSelectedItem(1)}
          selected={selectedItem === 1}
          text={t("CREATE_VOUCHER_VALID_OPTION_1")}
        />
        <CheckItem
          checked={() => setSelectedItem(2)}
          selected={selectedItem === 2}
          text={t("CREATE_VOUCHER_VALID_OPTION_2")}
        />
        <CheckItem
          checked={() => setSelectedItem(3)}
          selected={selectedItem === 3}
          text={t("CREATE_VOUCHER_VALID_OPTION_3")}
        />
      </div>
      {isError && <ErrorBox title={t("FAIL_NO_INTERNET_TITLE")} />}
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <CTAButton
          onClick={() => void sendVoucher()}
          className="voucher-button"
          disabled={titleInput.length === 0 || textInput.length === 0}
          title={t("CREATE_VOUCHER_SEND_BUTTON")}
        />
      )}
      <SectionTitle subtitle={t("VOUCHER_EXAMPLES_LIST_TITLE")} />
      <div className="separator" />
      <Voucher
        subtitle={t("VOUCHER_EXAMPLE_1_TITLE")}
        title={t("VOUCHER_EXAMPLE_1_TITLE")}
        text={t("VOUCHER_EXAMPLE_1_TEXT")}
      />
      <Voucher
        subtitle={t("VOUCHER_EXAMPLE_2_SUBTITLE")}
        title={t("VOUCHER_EXAMPLE_2_TITLE")}
        text={t("VOUCHER_EXAMPLE_2_TEXT")}
      />
      <Voucher
        subtitle={t("VOUCHER_EXAMPLE_3_SUBTITLE")}
        title={t("VOUCHER_EXAMPLE_3_TITLE")}
        text={t("VOUCHER_EXAMPLE_3_TEXT")}
      />
    </div>
  );
};
