import "./SectionTitle.scss";

type Props = {
  title?: string;
  subtitle: string;
};

export const SectionTitle = (props: Props): JSX.Element => {
  const { title, subtitle } = props;
  return (
    <section className="section-title">
      {title && <h1 className="title1 title-overview">{title}</h1>}
      <p className="title3 subtitle-overview">{subtitle}</p>
    </section>
  );
};
