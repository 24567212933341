import moment from "moment-timezone";

export const daysSinceStart = (start_date: string, timezone: string) => {
  return moment()
    .tz(timezone)
    .startOf("day")
    .diff(moment(start_date).tz(timezone).startOf("day"), "days");
};

export const getRelapseDay = (relapseStartDate: string | null | undefined) => {
  return relapseStartDate
    ? moment().diff(moment(relapseStartDate), "days")
    : 10;
};
