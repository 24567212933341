import { t } from "i18n-js";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import { CTAButton } from "../../components/CTAButton/CTAButton";
import { LoadingSpinner } from "../../components/LoadingSpinner/LoadingSpinner";
import { useGetPublicProfileQuery } from "../../redux/SmokelessApiSlice";
import { daysSinceStart } from "../../utils/date";
import { ProfileBadgesSection } from "./ProfileBadgesSection/ProfileBadgesSection";
import "./ProfileContainer.scss";
import { ProfileHighFivesSection } from "./ProfileHighFivesSection/ProfileHighFivesSection";
import { ProfileTopSection } from "./ProfileTopSection/ProfileTopSection";
import { QuitSmokingSection } from "./QuitSmokingSection/QuitSmokingSection";
import { VoucherSection } from "./VoucherSection/VoucherSection";
export const ProfileContainer = (): JSX.Element | null => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { data, error, isLoading } = useGetPublicProfileQuery(
    {
      profile: { uid: id ? id : "1" },
    },
    { refetchOnMountOrArgChange: true }
  );
  if (error) {
    console.error(error);
  }
  if (isLoading) {
    return (
      <div className="profile-loading">
        <LoadingSpinner />
      </div>
    );
  }

  if (
    data?.getProfilePublic.__typename === "ProfileNotPublic" ||
    data?.getProfilePublic.__typename === "ProfileNotFound" ||
    (data?.getProfilePublic.__typename === "ProfilePublic" &&
      data?.getProfilePublic.supporterMode)
  ) {
    return (
      <div className="profile-not-found-container content-width content-container-width">
        <h1 className="title2">{t("WEBAPP_INACTIVE_TITLE")}</h1>
        <p className="regular">{t("WEBAPP_INACTIVE_TEXT")}</p>
        <CTAButton onClick={() => navigate(-1)} title={t("CTA_BACK")} />
      </div>
    );
  }

  const now = moment();
  const quitDate = data.getProfilePublic.quitDate;
  const isQuitDateInFuture = moment(quitDate).isSameOrAfter(now);
  const hideProgressBar =
    isQuitDateInFuture &&
    daysSinceStart(
      data.getProfilePublic.start_date,
      data.getProfilePublic.timezone
    ) >= 90;
  const searchedUserRelapseDay = isQuitDateInFuture
    ? moment(quitDate).diff(now, "days")
    : 10;

  if (data?.getProfilePublic.__typename !== "ProfilePublic") return null;
  return (
    <div className="public-profile-container ">
      <ProfileTopSection
        daysInProgram={daysSinceStart(
          data.getProfilePublic.start_date,
          data.getProfilePublic.timezone
        )}
        timezone={data?.getProfilePublic.timezone}
        username={data?.getProfilePublic.username}
        id={data?.getProfilePublic.uid}
        stars={data?.getProfilePublic.starsTotal}
        challenges={data?.getProfilePublic.amountChallenges}
        quitDate={data?.getProfilePublic.quitDate}
        highfives={data?.getProfilePublic?.highfives?.number || 0}
        supporters={data?.getProfilePublic.amountFollower}
        supportOthers={data?.getProfilePublic.amountFollowing}
        awardsTotal={data?.getProfilePublic.badges.length}
        gender={data?.getProfilePublic.avatar?.gender || 0}
        hairStyle={data?.getProfilePublic.avatar?.hairStyle || 0}
        hairColor={data?.getProfilePublic.avatar?.hairColor || 0}
        face={data?.getProfilePublic.avatar?.face || 0}
        skin={data?.getProfilePublic.avatar?.skin || 0}
        cloth={data?.getProfilePublic.avatar?.clothes || 0}
        special={data?.getProfilePublic.avatar?.special || 0}
        hideProgressBar={hideProgressBar}
        searchedUserRelapseDay={searchedUserRelapseDay}
      />
      <ProfileHighFivesSection
        username={data?.getProfilePublic?.username || ""}
        uid={id || ""}
        highfives={data?.getProfilePublic?.highfives?.number || 0}
      />
      <VoucherSection
        username={data?.getProfilePublic?.username}
        userId={id || "1"}
      />
      <QuitSmokingSection />
      <ProfileBadgesSection
        badges={
          data?.getProfilePublic.badges
            ? data?.getProfilePublic.badges
            : undefined
        }
      />
    </div>
  );
};
