import { Link } from "react-router-dom";
import { loadAsset } from "../../utils/loadAsset";
import "./NavBar.scss";

type Props = {
  displayBurgerMenu?: boolean;
  onClick: () => void;
};

export const NavBar = (props: Props): JSX.Element => {
  const { onClick, displayBurgerMenu } = props;

  return (
    <header className="navbar-container flex-center">
      <div className="content-width navbar-main">
        <Link to={"/"}>
          <img
            {...loadAsset("support_others_header_logo.svg")}
            className="smokeless-logo"
            alt="smokeless-logo"
          />
        </Link>
        {displayBurgerMenu && (
          <button className="burger-button" onClick={() => onClick()}>
            <img alt="burger-menu-icon" src={loadAsset("side_menu.svg").src} />
          </button>
        )}
      </div>
    </header>
  );
};
