import { t } from "i18n-js";
import { CTAButton } from "../../../components/CTAButton/CTAButton";
import { loadAsset } from "../../../utils/loadAsset";
import "./QuitSmokingSection.scss";

export const QuitSmokingSection = (): JSX.Element => {
  return (
    <div className="profile-quit-smoking-container flex-center">
      <div className="profile-quit-smoking-main content-width">
        <div className="text-half content-container-width-no-height  with-padding">
          <h1 className="quit-title">{t("WEBAPP_AD_TITLE")}</h1>
          <p className="quit-subtitle">{t("WEBAPP_AD_SUBTITLE")}</p>
          <div className="quit-main-text title3">
            <p className="title3 bulletpoint">{t("WEBAPP_AD_BULLET_1")}</p>
            <p className="title3 bulletpoint">{t("WEBAPP_AD_BULLET_2")}</p>
            <p className="title3 bulletpoint">{t("WEBAPP_AD_BULLET_3")}</p>
          </div>
          <a href="https://www.smokeless.world">
            <CTAButton
              onClick={() => console.log("more about smokeless")}
              title={t("CTA_MORE_ABOUT_SMOKELESS")}
            />
          </a>
        </div>
        <div className="image-half   ">
          <img
            {...loadAsset("device_and_app.png")}
            alt="profile-avatar-background"
            className="device-image"
          />
        </div>
      </div>
    </div>
  );
};
