/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import axios from "axios";
import { t } from "i18n-js";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { CTAButton } from "../../components/CTAButton/CTAButton";
import { Input } from "../../components/Input/Input";
import { LoadingSpinner } from "../../components/LoadingSpinner/LoadingSpinner";
import { graphqlClient } from "../../gql/graphql-client";
import { AddVoucherMutation, FollowMutation } from "../../gql/graphql-types";
import { useAuth } from "../../hooks/useAuth";
import {
  SmokelessApiSlice,
  useAddVoucherMutation,
  useFollowMutation,
  useResendVerificationEmailMutation,
} from "../../redux/SmokelessApiSlice";
import { Validators } from "../../utils/validators";
import "./LoginContainer.scss";

// == THUNKS
type LoginResponse = { accessToken: string; uid: string };

export const LoginContainer = (): JSX.Element => {
  const [emailInput, setEmailInput] = useState("");
  const [password, setPasswordInput] = useState("");
  const [emailError, setEmailError] = useState("");
  const [noInternetError, setNoInternetError] = useState("");
  const [verificationError, setVerificationError] = useState("");
  const [loading, setLoading] = useState(false);
  const [addVoucher] = useAddVoucherMutation();
  const auth = useAuth();
  const [follow] = useFollowMutation();
  const { type, id } = useParams();
  const [passwordError, setPasswordError] = useState("");
  const navigate = useNavigate();
  const [trigger, result, lastPromiseInfo] =
    SmokelessApiSlice.useLazyRegistrationFlagsQuery();
  const [resendEmailVerification] = useResendVerificationEmailMutation();

  const loginUser = async () => {
    const requestBody = {
      username: emailInput,
      password,
    };
    setLoading(true);
    await axios
      .post<LoginResponse>(`${window.app.config.API}login`, requestBody, {
        headers: { "Access-Control-Allow-Origin": "*" },
      })
      .then(async (res) => {
        setEmailError("");
        setPasswordError("");
        localStorage.setItem("uid", res.data.uid);
        const authorizationHeader = `Bearer ${res.data.accessToken}`;
        graphqlClient.setHeader("authorization", authorizationHeader);
        const dataRegFlags = await trigger({
          profile: { uid: res.data.uid },
        });
        const isEmailVerified =
          dataRegFlags &&
          dataRegFlags.data.getProfilePrivate.__typename === "ProfilePrivate" &&
          dataRegFlags.data.getProfilePrivate.registrationFlags.emailVerified;
        console.log(isEmailVerified);

        if (isEmailVerified) {
          console.log("email is verified");
          setVerificationError("");
          localStorage.setItem("accessToken", res.data.accessToken);

          const voucherData = localStorage.getItem("voucherData");
          if (type && type === "voucher" && id && voucherData) {
            const result = (await addVoucher({
              profile: { uid: res.data.uid || "" },
              updatedData: JSON.parse(voucherData) || "",
            })) as { data: AddVoucherMutation; error: unknown };
            if (
              result.data &&
              result.data?.addVoucher?.__typename === "UpdateOk"
            ) {
              navigate(`/voucher-sent/${id}`);
            }
          } else if (type && type === "follow" && id) {
            const result = (await follow({
              profile: { uid: res.data.uid },
              updatedData: { followedUid: id },
            })) as { data: FollowMutation; error: unknown };
            if (result.data && result.data.follow.__typename === "UpdateOk") {
              navigate(`/overview`);
            }
          }
          navigate("/overview");
        } else if (!isEmailVerified) {
          console.log("email is not verified");
          setLoading(false);
          setVerificationError(t("WEBAPP_EMAIL_NOT_ACTIVATED_YET"));
          await resendEmailVerification({
            updatedData: { email: emailInput },
          }).catch((err) => console.warn(err));
        }
      })
      .catch((error) => {
        console.log(JSON.stringify(error));
        console.log(error.data);

        if (error.message === "Request failed with status code 401") {
          setEmailError(t("EMAIL_OR_PASSWORD_WRONG"));
          setPasswordError(t("EMAIL_OR_PASSWORD_WRONG"));
        } else {
          setNoInternetError(t("FAIL_NO_INTERNET_TITLE"));
        }
        setLoading(false);
      });
  };

  useEffect(() => {
    if (emailInput.length > 0 && !Validators.email(emailInput)) {
      setEmailError(t("EMAIL_FORM_NOT_VALID"));
    } else {
      setEmailError("");
    }
    if (password.length > 0 && !Validators.password(password)) {
      setPasswordError(t("PASSWORD_FORM_TOO_SHORT"));
    } else {
      setPasswordError("");
    }
  }, [emailInput, password]);
  const buttonDisabled =
    !emailInput || emailError || passwordError || !password ? true : false;
  // if (auth.isAuth) {
  //   return <AlreadyLoggedIn />;
  // }
  return (
    <div className="login-container content-container-width component-container">
      <h1 className="title2 login-title">{t("LOGIN_TITLE")}</h1>
      <Input
        placeholder={t("EMAIL_FORM")}
        type="email"
        inputText={emailInput}
        onChange={(input: string) => setEmailInput(input)}
        error={emailError || verificationError || noInternetError}
      />
      <Input
        placeholder={t("PASSWORD_FORM")}
        type="password"
        inputText={password}
        error={passwordError}
        onChange={(input: string) => setPasswordInput(input)}
      />

      <div className="button-container">
        {loading ? (
          <LoadingSpinner />
        ) : (
          <CTAButton
            onClick={() => void loginUser()}
            title={t("LOGIN_TITLE")}
            className="cta-button"
            disabled={buttonDisabled}
          />
        )}
        <Link to="/forgotPassword">
          <CTAButton
            onClick={() => {
              console.log(1);
            }}
            link
            title={t("CTA_FORGOT_PASSWORD")}
          />
        </Link>
      </div>
    </div>
  );
};
