import { useEffect } from "react";
import { useAddHighfivesMutation } from "../../redux/SmokelessApiSlice";
import { BubbleAnimation } from "../HighFive/bubblejs";
import { HighFive } from "../HighFive/highfive";
import "./ProfileHighFives.scss";

type Props = {
  highfives: number;
  username: string;
  uid: string;
};

export const ProfileHighFives = (props: Props): JSX.Element => {
  const [addHighfives] = useAddHighfivesMutation();

  useEffect(() => {
    const href = window.location.href;
    if (href.includes("#")) {
      const id = `${href.substring(href.indexOf("#") + 1)}`;
      const anchor = document.getElementById(id);
      if (anchor) {
        anchor.scrollIntoView({ block: "center" });
      }
    }
  }, []);
  return (
    <div id="highfives">
      <HighFive
        highfiveCurrent={props.highfives}
        uid={props.uid}
        username={props.username}
        addHighFives={async (highfives: number) => {
          await addHighfives({
            profile: { uid: props.uid },
            updatedData: { addHighfives: highfives },
          });
        }}
      />
      <BubbleAnimation />
    </div>
  ); /*
  return (
    <div className="high-fives-container">
      <div className="high-fives-inner">
        <button
          className="high-fives-general gradient_blue"
          onClick={() => console.log("highfive")}
        >
          <div className="high-fives-text-container">
            <div className="title4 first-text" style={{ letterSpacing: 1.5 }}>
              {t("HIGHFIVE_CARD_TITLE_1").toUpperCase()}
            </div>
            <div className="second-text">{t("HIGHFIVE_CARD_TITLE_2")}</div>
            <div className="title4 third-text">
              {t("HIGHFIVE_CARD_TITLE_3", {
                username: "username",
              }).toUpperCase()}
            </div>
          </div>
          <div className="hands-container">
            <div className="hand-back transform">
              <img
                {...getAsset("hand_back")}
                alt="profile-avatar-background"
                className="hand-back-icon"
              />
            </div>
            <div className="hand-front transform2">
              <img
                {...getAsset("hand_front")}
                alt="profile-avatar-background"
                className="hand-front-icon"
              />
            </div>
          </div>
          <div className="highfive-bottom-container">
            <div className="highfive-click-here">
              <img
                {...getAsset("high_five_click_here")}
                alt="high_five_click_here"
                className="high_five_click_here_icon"
              />
            </div>
            <div className="highfives-number">{props.highfives}</div>
            <div className="highfive-text">
              <h2 className="title3">{t("HIGHFIVES")}</h2>
            </div>
          </div>
        </button>
      </div>
    </div>
  );*/
};
